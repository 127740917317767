import React from "react";
import { useTranslation } from "react-i18next";
import NotCumulativeText from "@components/UI/NotCumulativeText/NotCumulativeText";
import PercentageText from "@components/UI/PercentageText/PercentageText";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import { NonCumulativeIndicatorDifference } from "../../NonCumulativeIndicatorDifference/NonCumulativeIndicatorDifference";
import { TextBoxProps, TEXTBOX_ENUM } from "./models/TextBox.models";
import "./TextBox.scss";
import { TargetPercentage } from "../../TargetPercentage/TargetPercentage";

const TextBox: React.FC<TextBoxProps> = ({
  type,
  numerator,
  denominator,
  percentage,
  referringRegion,
  target,
  meanDescription,
  onTargetDataLength,
  totalTargetDataLength,
  difference,
  band,
  value,
}) => {
  const { t } = useTranslation();
  switch (type) {
    case TEXTBOX_ENUM.TARGET_TEXT:
      return (
        <>
          <div className="TextBox__valueLabels">
            <span tabIndex={0} className="visually-hidden">
              {t("shared.textBox.targetTextHidden", {
                onTargetDataLength,
                totalTargetDataLength,
              })}
            </span>
            <Text
              type={TEXT_ENUM.TITLE_H6}
              customClass="TextBox__valueLabels--reachedNumber"
            >
              {onTargetDataLength}
            </Text>
            <Text type={TEXT_ENUM.TITLE_H6} customClass="inline">
              {`/ ${totalTargetDataLength}`}
            </Text>
            <Text
              type={TEXT_ENUM.PARAGRAPH}
              customClass="TextBox__valueLabels--reachedLabel"
            >
              {t("shared.charts.piechart.paragraph")}
            </Text>
            {value && (
              <>
                <Text
                  type={TEXT_ENUM.TITLE_H6}
                  customClass="TextBox__valueLabels--valueNumber"
                >
                  {typeof value === "number"
                    ? t("shared.percent", { val: value })
                    : value}
                </Text>
                <Text
                  type={TEXT_ENUM.PARAGRAPH}
                  customClass="TextBox__valueLabels--reachedLabel"
                >
                  {t("shared.charts.lineChart.nationalValue")}
                </Text>
              </>
            )}
          </div>
        </>
      );
    case TEXTBOX_ENUM.NOT_CUMULATIVE:
      return (
        <div className="TextBox">
          <NotCumulativeText
            value={percentage ?? 0}
            meanDescription={(meanDescription && t(meanDescription)) || ""}
            referringRegion={referringRegion}
          />
          <NonCumulativeIndicatorDifference
            difference={difference ?? 0}
            numerator={numerator ?? 0}
          />
        </div>
      );
    case TEXTBOX_ENUM.PERCENTAGE_TEXT:
    default:
      return (
        <>
          <PercentageText
            numerator={numerator}
            denominator={denominator}
            percentage={percentage ?? 0}
            referringRegion={referringRegion}
            isWideCard
          ></PercentageText>
          {target && (
            <TargetPercentage
              targetType={t("indicators.AslTarget") as string}
              percentage={Number(target)}
              band={band}
            />
          )}
        </>
      );
  }
};
export default React.memo(TextBox);
