import React from "react";
import { TEXT_ENUM } from "../Text/models/Text.models";
import Text from "../Text/Text";
import { NoDataRectangleProps } from "./models/NoDataRectangle.models";
import "./NoDataRectangle.scss";

const NoDataRectangle: React.FC<NoDataRectangleProps> = ({ label }) => {
  return (
    <div className="NoDataRectangle" data-testid="grey-rectangle">
      <Text customClass="NoDataRectangle__text" type={TEXT_ENUM.TITLE_H6}>
        {label}
      </Text>
    </div>
  );
};

export default React.memo(NoDataRectangle);
