import GenericLegend from "@components/Charts/Legend/GenericLegend/GenericLegend";
import { LEGEND_COLORS_ENUM } from "@components/Charts/Legend/GenericLegend/models/GenericLegend.models";
import GenericScatterplot from "@components/Charts/ScatterplotSingleAxis/GenericScatterplot/GenericScatterplot";
import { SCATTERPLOT_COLORS } from "@components/Charts/models/charts.enum";
import Spinner from "@components/UI/Spinner/Spinner";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import CollapseSection from "@containers/Indicators/components/CollapseSection/CollapseSection";
import SummaryCard from "@containers/Indicators/components/SummaryCard/SummaryCard/SummaryCard";
import { TEXTBOX_ENUM } from "@containers/Indicators/components/SummaryCard/TextBox/models/TextBox.models";
import GenericTable from "@containers/Indicators/components/table/GenericTable";
import {
  ENTITY_WITH_TARGET_COLUMN_WITH_NUMBERS,
  GENERIC_ENTITY_COLUMN_LABEL,
  GENERIC_ENTITY_COLUMN_WITH_NUMBERS,
  TableColumnRegionalProps,
} from "@containers/Indicators/models/tables.model";
import { REGION_TAB_ENUM } from "@containers/Indicators/models/tabs.models";
import {
  NATION_SCATTERPLOT_INTERVAL,
  NATION_SCATTERPLOT_SYMBOL_SIZE,
  NATIONAL_VALUE_LABEL,
  REGION_SCATTERPLOT_INTERVAL,
  REGION_SCATTERPLOT_SYMBOL_MAX_SIZE,
  REGION_SCATTERPLOT_SYMBOL_MIN_SIZE,
} from "@utils/constants";
import { targetLegendItems } from "@utils/constants/TargetLegendItems";
import {
  getEntityWithTargetTableColumns,
  getGenericEntityTableColumns,
  sortTableByValueFn,
} from "@utils/tableColumns/tablesColumns";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TableDataType } from "../TabDetailContainer.model";
import { NationHeaderProps, RegionsData } from "./models/NationHeader.model";

const NationHeader: React.FC<NationHeaderProps> = ({
  lastValueForSelectedKpi,
  isFetching,
  setLocalityTab,
  setSelectedRegion,
  isGoalDetail,
  CSVDownload,
  numberOfRegionsOnTarget,
}) => {
  const { t } = useTranslation();

  const nationalKpi = lastValueForSelectedKpi?.values?.at(0);
  const nationalKpiValue = nationalKpi?.value;

  const nationData = {
    value: nationalKpiValue,
    referenceDate: nationalKpi?.referenceDate,
    regionDescription: {
      description: NATIONAL_VALUE_LABEL,
    },
  };

  const powerNationData = {
    orderingValue: nationalKpiValue,
    entityDescription: {
      description: NATIONAL_VALUE_LABEL,
    },
    indicatorValue: nationalKpiValue,
    numerator: nationalKpi?.numerator,
    denominator: nationalKpi?.denominator,
    referenceDate: nationalKpi?.referenceDate,
  };

  const regionsData: TableDataType<RegionsData>[] =
    lastValueForSelectedKpi?.regions
      .map((region) => ({
        orderingValue: region.values[region.values.length - 1].value,
        regionDescription: {
          description: region.description,
          action: () => {
            setLocalityTab(REGION_TAB_ENUM.ID);
            setSelectedRegion({
              description: region.description,
              id: region.id,
              companies: {},
            });
          },
        },
        target: region.values[region.values.length - 1].target,
        // difference: region.values[0].difference,
        band: region.zone,
        ...region.values[region.values.length - 1],
      }))
      .sort(sortTableByValueFn) ?? [];

  const powerTableData =
    lastValueForSelectedKpi?.regions
      .map((region) => {
        const lastValue = region.values[region.values.length - 1];
        return {
          orderingValue: lastValue.value,
          entityDescription: {
            description: region.description ?? region.id,
            action: () => {
              setLocalityTab(REGION_TAB_ENUM.ID);
              setSelectedRegion({
                description: region.description,
                id: region.id,
                companies: {},
              });
            },
          },
          indicatorValue: lastValue.value,
          numerator: lastValue.numerator,
          denominator: lastValue.denominator,
          referenceDate: lastValue.referenceDate,
        };
      })
      .sort(sortTableByValueFn) ?? [];
  return (
    <Row data-testid="nation-header">
      <Col>
        <CollapseSection>
          {!isFetching && lastValueForSelectedKpi ? (
            <SummaryCard
              id={lastValueForSelectedKpi.id}
              headline={{
                firstIndicator: lastValueForSelectedKpi.numeratorDescription,
                periodDataReferringTo:
                  lastValueForSelectedKpi.lastDailyReferenceDate,
                secondIndicator: lastValueForSelectedKpi.denominatorDescription,
                indicatorDescription: lastValueForSelectedKpi.tooltip,
                lastUpdateDate: lastValueForSelectedKpi.lastUpdateDisplayName,
                isWide: true,
                headingTag: TEXT_ENUM.TITLE_H3,
                headingClass: "h5",
              }}
              value={lastValueForSelectedKpi.values?.at(0)?.value}
              numerator={lastValueForSelectedKpi.values?.at(0)?.numerator}
              denominator={lastValueForSelectedKpi.values?.at(0)?.denominator}
              difference={lastValueForSelectedKpi.values?.at(0)?.difference}
              onTargetDataLength={numberOfRegionsOnTarget}
              totalTargetDataLength={lastValueForSelectedKpi.regions.length}
              textBoxType={
                isGoalDetail
                  ? TEXTBOX_ENUM.TARGET_TEXT
                  : TEXTBOX_ENUM.PERCENTAGE_TEXT
              }
              percentage={!isGoalDetail ? nationData.value : undefined}
              table={
                isGoalDetail ? (
                  <GenericTable
                    columns={getEntityWithTargetTableColumns(
                      regionsData.map(
                        (region) =>
                          region.band !== undefined && region.band !== null
                      )[0]
                    )}
                    data={[nationData, ...regionsData]}
                    columnsWithNumbers={ENTITY_WITH_TARGET_COLUMN_WITH_NUMBERS}
                  />
                ) : (
                  <GenericTable
                    columns={getGenericEntityTableColumns(
                      GENERIC_ENTITY_COLUMN_LABEL.REGION_DESCRIPTION
                    )}
                    data={[
                      powerNationData as TableColumnRegionalProps,
                      ...powerTableData,
                    ]}
                    columnsWithNumbers={GENERIC_ENTITY_COLUMN_WITH_NUMBERS}
                  />
                )
              }
              chart={
                <GenericScatterplot
                  items={regionsData.map((data) => {
                    // An interface can be used
                    return isGoalDetail
                      ? {
                          difference: data.difference,
                          tooltipLabel: data.regionDescription.description,
                          value: data.value,
                          band: data.band,
                          target: data.target,
                        }
                      : {
                          tooltipLabel: data.regionDescription.description,
                          value: data.value,
                          target: nationData.value,
                        };
                  })}
                  aboveTargetColor={
                    isGoalDetail ? SCATTERPLOT_COLORS.GREEN : undefined
                  }
                  belowTargetColor={
                    isGoalDetail ? SCATTERPLOT_COLORS.RED : undefined
                  }
                  basicColor={
                    !isGoalDetail ? SCATTERPLOT_COLORS.BLUE : undefined
                  }
                  symbolSize={NATION_SCATTERPLOT_SYMBOL_SIZE}
                  interval={
                    isGoalDetail
                      ? NATION_SCATTERPLOT_INTERVAL
                      : REGION_SCATTERPLOT_INTERVAL
                  }
                  maxSymbolSize={
                    !isGoalDetail
                      ? REGION_SCATTERPLOT_SYMBOL_MAX_SIZE
                      : undefined
                  }
                  minSymbolSize={
                    !isGoalDetail
                      ? REGION_SCATTERPLOT_SYMBOL_MIN_SIZE
                      : undefined
                  }
                  markLine={
                    typeof nationData.value === "number"
                      ? nationData.value
                      : undefined
                  }
                />
              }
              chartLegend={
                isGoalDetail ? (
                  <GenericLegend
                    items={targetLegendItems}
                    hasTarget
                    targetLabel={t("shared.charts.lineChart.nationalValue")}
                  />
                ) : (
                  <GenericLegend
                    items={[
                      {
                        color: LEGEND_COLORS_ENUM.LIGHT_BLUE,
                        description: t("indicators.goals.tabs.region"),
                      },
                    ]}
                    hasTarget
                    targetLabel={t("shared.charts.lineChart.nationalValue")}
                  />
                )
              }
              onDownloadCsvButton={CSVDownload}
            />
          ) : (
            <Spinner />
          )}
        </CollapseSection>
      </Col>
    </Row>
  );
};
export default NationHeader;
