import { useTranslation } from "react-i18next";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import { TargetPercentageProps } from "./TargetPercentage.models";

export const TargetPercentage: React.FC<TargetPercentageProps> = ({
  targetType,
  band,
  percentage,
}) => {
  const { t } = useTranslation();

  const text = t("indicators.targetPercentage", {
    band: band ? `${t("indicators.band")} ${band} ` : "",
    percentage,
  });

  return (
    <div data-testid="target-percentage">
      <Text type={TEXT_ENUM.TITLE_H5} arialabel={text}>
        {text}
      </Text>
      <Text type={TEXT_ENUM.PARAGRAPH}>{targetType}</Text>
    </div>
  );
};
