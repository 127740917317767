import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Button from "@components/UI/Button/Button";
import Select from "@components/UI/Select/Select";
import { Company, Region } from "@models/Companies/companies.model";
import { ASL_OPTION, REGION_OPTION } from "@utils/Options/option.models";
import { IndicatorsSelectProps } from "./models/IndicatorsSelect.models";
import { sortHandler } from "@utils/function/sortHandler";

const IndicatorsSelect: React.FC<IndicatorsSelectProps> = ({
  onChange: change,
  regions,
  defaultRegion,
  defaultCompany,
  showCompanies = false,
  showStructures = false,
  regionOptions = sortHandler(regions) as Region[],
}) => {
  const { t } = useTranslation();

  const [selectedRegion, setSelectedRegion] = useState<Region>(
    defaultRegion as Region
  );
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    defaultCompany as Company
  );

  const [selectedStructure, setSelectedStructure] = useState<
    Company | undefined
  >(defaultCompany); //FIXME defaultStructure

  useEffect(() => {
    setSelectedCompany(defaultCompany);
  }, [defaultCompany]);

  const isDisabled = showCompanies && !selectedCompany;
  const companies = useMemo(
    () => Object.values<Company>(selectedRegion?.companies || {}),
    [selectedRegion]
  );

  const onChangeRegionHandler = (region?: Region) => {
    if (region) {
      setSelectedRegion(region);
      setSelectedCompany(defaultCompany);
      setSelectedStructure(undefined);
    }
  };

  const onChangeCompanyHandler = (company?: Company) => {
    if (company) {
      setSelectedCompany(company);
      setSelectedStructure(undefined);
    }
  };

  const confirmHandler = () => {
    change({
      region: selectedRegion,
      company: selectedCompany,
      structure: selectedStructure,
    });
  };

  const enoughRegions = regions.length > 1;
  const enoughCompanies = companies.length > 1;

  if (
    (!showCompanies && !enoughRegions) ||
    (showCompanies && !enoughRegions && !enoughCompanies)
  ) {
    return null;
  }

  return (
    <Row className="align-items-end g-4" data-testid="indicators-filter">
      {enoughRegions && (
        <Col md="auto" sm="auto">
          <Select
            label={t("filters.region")}
            placeholder={t("filters.regionPlaceholder")}
            options={regionOptions as Region[]}
            onChange={onChangeRegionHandler}
            value={selectedRegion}
            labelKey={REGION_OPTION.LABEL}
            valueKey={REGION_OPTION.VALUE}
          />
        </Col>
      )}

      {showCompanies && enoughCompanies && (
        <Col md="auto" sm="auto">
          <Select
            label={t("filters.asl")}
            placeholder={t("filters.aslPlaceholder")}
            options={sortHandler(companies) as Company[]}
            onChange={onChangeCompanyHandler}
            value={selectedCompany}
            labelKey={ASL_OPTION.LABEL}
            valueKey={ASL_OPTION.VALUE}
          />
        </Col>
      )}

      {showStructures && (
        <Col xs="12" sm="auto">
          <Select
            label={t("filters.structure")}
            placeholder={t("filters.structurePlaceholder")}
            options={sortHandler(companies) as Company[]} //FIXME selectedCompany.structures
            onChange={onChangeCompanyHandler} //FIXME onChangeStructureHandler
            value={selectedStructure}
            labelKey={ASL_OPTION.LABEL}
            valueKey={ASL_OPTION.VALUE}
          />
        </Col>
      )}

      {/* {regions && (
        <div
          className="IndicatorsSelect__verticalLine"
          data-testid="IndicatorsSelect-verticalLine"
        />
      )} */}

      <Col md="auto" sm="auto">
        <Button
          onClick={confirmHandler}
          isDisabled={isDisabled}
          customClass="w-100"
          id="confirm-filters-indicators"
        >
          {t("filters.button")}
        </Button>
      </Col>
    </Row>
  );
};

export default React.memo(IndicatorsSelect);
