import { LableDomain } from "@models/Glossary/Glossary.model";
import i18n from "src/i18n";

export interface HistogramSelectProps {
  onFilter: (filterOption: LableDomain) => void;
  options?: LableDomain[];
}

export enum HISTOGRAM_SELECT_OPTION {
  LABEL = "description",
  VALUE = "id",
}

export enum HISTOGRAM_SELECT_ID {
  INDICATOR_FILTER = "indicator_filter",
  ALPHABETICAL_FILTER = "alphabetical_filter",
  CODE_FILTER = "code_filter",
}

export const selectOptions: LableDomain[] = [
  {
    description: i18n.t("indicators.power.indicatorsLegend.valuePercentage"),
    id: HISTOGRAM_SELECT_ID.INDICATOR_FILTER,
  },
  {
    description: i18n.t("shared.charts.histogram.alphabeticalOrder"),
    id: HISTOGRAM_SELECT_ID.ALPHABETICAL_FILTER,
  },
  {
    description: i18n.t("shared.charts.histogram.aslCode"),
    id: HISTOGRAM_SELECT_ID.CODE_FILTER,
  },
];
