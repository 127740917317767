import React from "react";
import { useTranslation } from "react-i18next";
import Text from "../Text/Text";
import { TEXT_ENUM } from "../Text/models/Text.models";
import "./PercentageText.scss";
import { PercentageTextProps } from "./models/PercentageText.model";
import { OUT_OF_SCALE_VALUE } from "@utils/constants";

const PercentageText: React.FC<PercentageTextProps> = ({
  percentage,
  numerator = 0,
  denominator = 0,
  target = 0,
  isWideCard,
  referringRegion,
  customColorClass,
  areaLabel,
}) => {
  const { t } = useTranslation();
  let subText: string;
  let percentageValue: number | string;
  let percentageClass: string = "";

  const numeratorDenominator = t("shared.numerator_denominator", {
    numerator,
    denominator,
  });

  const region = t("shared.valueOfRegion", { region: referringRegion });
  const nation = t("shared.nationalValue");

  const defaultAreaLabel =
    target === 0
      ? "Numeratore " + numerator + " su denominatore " + denominator
      : undefined;

  if (target === 0) {
    subText = isWideCard
      ? referringRegion
        ? region
        : nation
      : numeratorDenominator;
    percentageValue = percentage;
    percentageClass =
      percentage === customColorClass
        ? `${customColorClass}`
        : "PercentageText__value";
  } else {
    subText = t("shared.charts.percentage.target");
    percentageValue =
      typeof percentage === "number" &&
      denominator &&
      typeof target === "number"
        ? percentage - target
        : percentage === OUT_OF_SCALE_VALUE
        ? t("shared.charts.percentage.outOfScale")
        : percentage;
    percentageClass =
      typeof percentageValue === "number"
        ? percentageValue < 0
          ? "PercentageText__value--negative"
          : "PercentageText__value--positive"
        : target
        ? "PercentageText__value--NA"
        : "";
  }

  const valueToShow =
    typeof percentageValue === "number"
      ? t("shared.percent", { val: percentageValue })
      : percentageValue === OUT_OF_SCALE_VALUE
      ? t("shared.charts.percentage.outOfScale")
      : percentageValue;

  return (
    <div>
      <div className={`PercentageText ${isWideCard && "PercentageText--wide"}`}>
        <span data-testid="percentage-value" className={`${percentageClass}`}>
          <Text type={TEXT_ENUM.TITLE_H3} arialabel={areaLabel}>
            {valueToShow}
          </Text>
        </span>
        {isWideCard && (
          <Text type={TEXT_ENUM.PARAGRAPH} data-testid="sidetext">
            ({numeratorDenominator})
          </Text>
        )}
      </div>
      <div>
        <Text
          type={TEXT_ENUM.PARAGRAPH}
          data-testid="subtext"
          arialabel={defaultAreaLabel}
        >
          {subText}
        </Text>
      </div>
    </div>
  );
};

export default PercentageText;
