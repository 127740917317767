import React from "react";
import { getTextTag } from "@utils/function/getTextTag";
import { TextProps } from "./models/Text.models";

const Text: React.FC<TextProps> = (props) => {
  const Tag = getTextTag(props.type);
  return (
    <Tag
      data-testid={props.id}
      className={props.customClass}
      onClick={props.onClick}
      aria-label={props.arialabel}
    >
      {props.children}
    </Tag>
  );
};
export default React.memo(Text);
