export enum PILL_MODE {
  OUTLINE_PRIMARY = "badge-outline-primary",
  OUTLINE_SECONDARY = "badge-outline-secondary",
}

export interface PillProps {
  text: string;
  hasIcon?: boolean;
  clicked?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  mode?: PILL_MODE;
  customClass?: string;
}
