import { KPI_CATEGORY_VALUE } from "./enum/kpiCategory";

// Pct stands for percentage
export const KPI = {
  Goal: {
    indexedDocumentsPct: "1",
    mmgFeedinFSEPct: "2",
    cda2Pct: "3",
    paDesSignedDocumentsPct: "4",
  },
  Power: {
    indexedhospitalDischargePct: "IA1.1",
    indexedERReportsPct: "IA1.2",
    indexedLabReportsPct: "IA1.3",
    indexedRadiologyReportsPct: "IA1.4",
    indexedSpecialistOutpatienReportsdPct: "IA1.5",
    indexedPathologicalAnatomyReportsPct: "IA1.6",
    indexedResidentsWithVaccineCertificationPct: "IA1.7",
    indexedDocumentsForPatientPct: "IA1.8",
    patientsWithIndicizzatoPatientSummaryPct: "IA2.1",
    indexedPatientSummaryForMMGPct: "IA2.2",
    mmgUsersThatSentPatientSummaryPct: "IU2.3",
  },
  Usage: {
    consultationConsentsPct: "IU1.1",
    documentsConsultedByResidentsPct: "IU1.2",
    usersThatUsedFSEServicesPct: "IU1.3",
    documentsConsultedByMMGPlsPct: "IU2.1",
    MMGPlsUsersThatUsedFSEServicesPct: "IU2.2",
    documentsConsultedByHealthcareProfessionalsPct: "IU3.1",
    healthcareProfessionalsThatConsuledDocumentsPct: "IU3.2",
    healthcareProfessionalsThatUpdatedADocumentPct: "IU3.3",
  },
} as const;

export const GoalKpis = [
  KPI.Goal.indexedDocumentsPct,
  KPI.Goal.mmgFeedinFSEPct,
  KPI.Goal.cda2Pct,
  KPI.Goal.paDesSignedDocumentsPct,
];
export const FeedKpis = [
  KPI.Power.indexedhospitalDischargePct,
  KPI.Power.indexedERReportsPct,
  KPI.Power.indexedLabReportsPct,
  KPI.Power.indexedRadiologyReportsPct,
  KPI.Power.indexedSpecialistOutpatienReportsdPct,
  KPI.Power.indexedPathologicalAnatomyReportsPct,
];
export const UsageKpis = [
  KPI.Usage.consultationConsentsPct,
  KPI.Usage.documentsConsultedByResidentsPct,
  KPI.Usage.usersThatUsedFSEServicesPct,
  KPI.Usage.documentsConsultedByMMGPlsPct,
  KPI.Usage.MMGPlsUsersThatUsedFSEServicesPct,
  KPI.Usage.documentsConsultedByHealthcareProfessionalsPct,
  KPI.Usage.healthcareProfessionalsThatConsuledDocumentsPct,
  KPI.Usage.healthcareProfessionalsThatUpdatedADocumentPct,
];

export const Kpis = {
  [KPI_CATEGORY_VALUE.GOAL]: GoalKpis,
  [KPI_CATEGORY_VALUE.POWER]: FeedKpis,
  [KPI_CATEGORY_VALUE.USAGE]: [], // usageKPI: UsageKpis, // TODO: Uncomment when Usage KPIs are ready
};

export const allKpis = Object.values(Kpis).flat();

export type Kpi = (typeof Kpis)[KPI_CATEGORY_VALUE][number];
