import React from "react";
import { useMatches } from "react-router-dom";
import "./Breadcrumb.scss";
import { HandleType, IMatches } from "./model/Breadcrumb.model";

const Breadcrumb: React.FC<{}> = () => {
  const matches: IMatches[] = useMatches();

  const _crumbs = matches
    .filter((match) =>
      Boolean(match.handle && (match.handle as HandleType).crumb)
    )
    .map((match) => {
      const crumb = (match.handle as HandleType).crumb(
        match.data as string | undefined
      );
      return crumb as React.ReactNode;
    });

  const crumbs = _crumbs.slice(0, -1);
  const currentCrumb = _crumbs.at(-1);

  return (
    <nav aria-label="Ti trovi in">
      <ol className="breadcrumb px-3 px-md-0" data-testid="breadcrumb-ol">
        {crumbs.map((crumb, index) => (
          <li className="breadcrumb-item" key={index}>
            {crumb}
          </li>
        ))}
        <li className="breadcrumb-item active">{currentCrumb}</li>
      </ol>
    </nav>
  );
};

export default React.memo(Breadcrumb);
