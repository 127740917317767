import { GlossariesMap, Glossary } from "@models/Glossary/Glossary.model";
import {
  IndicatorsForCard,
  NationalKPI,
} from "@models/Indicators/indicators.model";

export const getIndicatorsObj = (
  glossaries: GlossariesMap,
  indicators: NationalKPI[]
) => {
  return indicators.reduce<IndicatorsForCard[]>((result, indicatorsItem) => {
    const glossaryItem: Glossary | undefined = glossaries.get(
      indicatorsItem.id
    );
    return glossaryItem
      ? [
          ...result,
          {
            ...indicatorsItem,
            category: glossaryItem.category.description,
            denominatorDescription: glossaryItem.denominator,
            description: glossaryItem.description,
            numeratorDescription: glossaryItem.numerator,
            tags: [
              glossaryItem.category.description,
              ...glossaryItem.tags.map((item) => item.description),
            ],
            tooltip: glossaryItem.tooltip,
            zone: glossaryItem.isZoned
              ? indicatorsItem.regions[0].zone
              : undefined,
            deliveryRegime: glossaryItem.deliveryRegime,
          } satisfies IndicatorsForCard,
        ]
      : result;
  }, []);
};
