import {
  useGetGlossariesQuery,
  useGetProfileQuery,
} from "@api/orchestrator/orchestrator.services";
import { User } from "@models/User/User.models";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import {
  showEvolvingDataAlert,
  showTokenIsExpiringAlert,
} from "src/redux/selectors/alert.selectors";
import {
  evolvingDataAlertClosed,
  logoutSilent,
  reset,
  tokenIsExpiringClosed,
} from "src/redux/slices/alert.slice";
import { useAppDispatch } from "src/store";

export const useLayoutViewModel = () => {
  const {
    data: glossaries,
    isFetching: isFetchingGlossaries,
    error: errorGlossaries,
  } = useGetGlossariesQuery();
  const userData = useGetProfileQuery();

  const dispatch = useAppDispatch();

  // TODO: wrap this in a useMemo()
  const userInfo: User = {
    CF: userData.data?.taxCode ?? "",
    name: userData.data?.givenName ?? "",
    lastname: userData.data?.familyName ?? "",
    roles: userData.data?.roles ?? [],
  };

  const { user, signinRedirect } = useAuth();
  const tokenExp = (user?.expires_at ?? 0) * 1000;
  const IsTokenIsExpiringAlertVisible = useSelector(showTokenIsExpiringAlert);
  const isEvolvingDataAlertVisible = useSelector(showEvolvingDataAlert);
  const closeExpiringAlert = () => dispatch(tokenIsExpiringClosed());
  const closeEvolvingDataAlert = () => dispatch(evolvingDataAlertClosed());
  const closeAlert = () => dispatch(reset());
  const logout = () => dispatch(logoutSilent(signinRedirect));

  const showFullSidebarMenu = userData.data?.hasCentralInstitution ?? false;

  return {
    isFetchingGlossaries,
    errorGlossaries,
    glossaries,
    userInfo,
    IsTokenIsExpiringAlertVisible,
    tokenExp,
    closeAlert,
    logout,
    closeEvolvingDataAlert,
    closeExpiringAlert,
    isEvolvingDataAlertVisible,
    showFullSidebarMenu,
  };
};
