import {
  TabbarProps,
  TabItem,
  TAB_BAR_DIRECTION,
} from "./models/Tabbar.models";
import "./Tabbar.scss";
import { useTranslation } from "react-i18next";

const Tabbar = <T extends TabItem>({
  tabs,
  activeTab,
  onChange,
  direction = TAB_BAR_DIRECTION.HORIZONTAL,
  showBorders = false,
}: TabbarProps<T>) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="tabbar-container"
      className={`Tabbar ${
        direction === TAB_BAR_DIRECTION.VERTICAL ? "tabs-vertical" : ""
      }`}
    >
      <ul
        className={`nav ${showBorders ? "nav-tabs" : "nav-tabs-borderless"}`}
        aria-orientation={direction}
        role="tablist"
      >
        {tabs.map((item) => (
          <li
            key={item.id}
            className="nav-item"
            onClick={onChange.bind(null, item)}
          >
            <button
              className={`Tabbar__tabButton nav-link ${
                activeTab === item.id ? "active" : ""
              } ${item.isDisabled ? "Tabbar__tabButton--disabled" : ""}`}
              data-bs-toggle="tab"
              aria-controls={`tab-${item.id}`}
              aria-expanded={activeTab === item.id}
              role="tab"
              disabled={item.isDisabled}
            >
              {t(item.label)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabbar;
