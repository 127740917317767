import {
  CompaniesMap,
  CompanyProps,
  RegionProps,
  RegionsMap,
} from "@models/Companies/companies.model";
import { toTitleCase } from "./textFormatting";

export const buildCompaniesMapFromList = (companiesList: CompanyProps[]) => {
  const companiesMap: CompaniesMap = {};
  companiesList.forEach((company) => {
    companiesMap[company.code] = {
      ...company,
      description: toTitleCase(company.description ?? company.code),
    };
  });
  return companiesMap;
};

// This is a normalizr function that converts regions array to regions map
export const buildRegionsMapFromList = (regionsList: RegionProps[]) => {
  const regionsMap: RegionsMap = {};
  regionsList.forEach((region) => {
    regionsMap[region.id] = {
      ...region,
      description: toTitleCase(region.description ?? region.id),
      companies: buildCompaniesMapFromList(region.companies),
    };
  });
  return regionsMap;
};
