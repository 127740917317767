export const getInterval = (
  differences: { value: number | string; target: number | string }[],
  rangeSize: number
) => {
  const _differences = differences.map((difference) => {
    if (typeof difference.value === "string") return 0;
    return difference.value * 100;
  });
  const _targets = differences.map((difference) => {
    if (typeof difference.target === "string") return 0;
    return difference.target * 100;
  });
  const max = Math.max(..._differences, ..._targets);
  const min = Math.min(..._differences, ..._targets);
  const maxDecimal = Math.floor(max / rangeSize);
  const minDecimal = Math.floor(min / rangeSize);
  const maxModule = max % rangeSize;
  const minModule = min % rangeSize;
  const minInterval =
    minModule === 0
      ? minDecimal * rangeSize
      : minDecimal * rangeSize - rangeSize;
  const maxInterval =
    maxModule === 0
      ? maxDecimal * rangeSize
      : maxDecimal * rangeSize + rangeSize;

  return min === max
    ? [minInterval, minInterval + rangeSize]
    : [minInterval, maxInterval];
};
