import React, { useRef } from "react";
import { Trans } from "react-i18next";
import Icon from "@components/UI/Icon/Icon";
import Pill from "@components/UI/Pill/Pill";
import { PILL_MODE } from "@components/UI/Pill/models/Pill.model";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import { useHeadlineHeight } from "@hooks/ResizeHeadlineHeight/ResizeHeadlineHeight";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { HeadlineProps } from "./models/Headline.models";
import "./Headline.scss";

const Headline: React.FC<HeadlineProps> = ({
  indicatorsName,
  firstIndicator,
  secondIndicator,
  periodDataReferringTo,
  indicatorDescription,
  lastUpdateDate,
  isWide,
  isTitlePage,
  pillValues,
  customIconSizeClass,
  headingClass,
  headingTag,
}) => {
  const typeTitleText = isTitlePage
    ? TEXT_ENUM.TITLE_H1
    : headingTag
    ? headingTag
    : TEXT_ENUM.TITLE_H5;
  const headerBoxClassName =
    isTitlePage || isWide ? "Headline__dateBoxInline" : "Headline__dateBox";

  const headlineRef = useRef<HTMLDivElement>(null);
  const heightClass = `${useHeadlineHeight(
    headlineRef,
    isWide,
    isTitlePage
  )}vh`;

  const kpiBox: JSX.Element = (
    <div
      className={isWide ? "Headline__kpis--wideCard" : "Headline__kpis"}
      data-testid="headline-indicators"
    >
      <div
        className={`${isTitlePage ? "Headline__titleContainer" : ""}`}
        data-testid="headline-component-firstIndicator"
      >
        <Text
          type={typeTitleText}
          customClass={`Headline__kpis--text ${headingClass}`}
        >
          {`${firstIndicator} / ${secondIndicator}`}{" "}
          <Icon
            iconClass={ICON_CLASS.INFO_CIRCLE}
            customClass={`Headline__kpis--icon ${customIconSizeClass}`}
            tooltip={indicatorDescription}
          ></Icon>
        </Text>
      </div>
    </div>
  );

  const headerDateBox: JSX.Element = (
    <>
      {periodDataReferringTo && (
        <div
          className={headerBoxClassName}
          data-testid="headline-component-dateBox"
        >
          <Text
            type={TEXT_ENUM.PARAGRAPH}
            customClass={
              `${headerBoxClassName}--date mb-0` +
              (isTitlePage ? "" : " text-secondary")
            }
          >
            <Trans
              i18nKey="shared.periodDataReferringTo" // optional -> fallbacks to defaults if not provided
              values={{
                referenceDate: periodDataReferringTo,
              }}
              components={{ bold: <b /> }}
            />
          </Text>
          {lastUpdateDate && (
            <Text
              type={TEXT_ENUM.SPAN}
              customClass={`${headerBoxClassName}--lastUpdateDate ${
                isTitlePage ? "" : "text-secondary"
              }`}
            >
              <Trans
                i18nKey="shared.lastUpdate" // optional -> fallbacks to defaults if not provided
                values={{ lastUpdateDate }}
                components={{ bold: <b /> }}
              />
            </Text>
          )}
        </div>
      )}
    </>
  );

  return (
    <div
      className={`Headline h-${heightClass}`}
      data-testid="headline-component"
      ref={headlineRef}
    >
      {pillValues &&
        pillValues.map((tag) => (
          <div key={tag} className="Headline__pill">
            <Pill
              key={tag}
              text={tag}
              mode={PILL_MODE.OUTLINE_SECONDARY}
              disabled
              customClass="Headline__pill--disabled"
            />
          </div>
        ))}
      {indicatorsName && (
        <div
          className="Headline__indicatorName"
          data-testid="headline-component-indicatorName"
        >
          <Text
            type={TEXT_ENUM.TITLE_H5}
            customClass="Headline__indicatorName--text"
          >
            {indicatorsName}:
          </Text>
        </div>
      )}
      {kpiBox}
      {headerDateBox}
    </div>
  );
};
export default React.memo(Headline);
