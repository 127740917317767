import Tabbar from "@components/UI/Tabbar/Tabbar";
import { NESTED_TABS } from "@components/UI/Tabbar/models/Tabbar.models";
import Text from "@components/UI/Text/Text";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import { NUMBER_TYPE } from "@utils/enum/numberType.enum";
import { t } from "i18next";
import { Alert, Col, Row } from "react-bootstrap";
import { showTargetChangeAlert } from "src/redux/selectors/alert.selectors";
import { targetChangeClosed } from "src/redux/slices/alert.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import DetailTableInCard from "../components/DetailTableInCard/DetailTableInCard";
import IndicatorDetailCard from "../components/IndicatorDetailCard/IndicatorDetailCard";
import { DetailsContainerProps } from "../models/NestedTabDetailContainer.model";
import { useNestedTabDetailViewModel } from "./NestedTabDetailViewModel";

export default function NestedTabDetailContainer({
  category,
  kpi,
  regions,
  title,
  selectedRegion,
  selectedCompany,
  domain,
  noDataLabel,
  hasTarget,
  isTargetShown,
  zone,
  isAltertToShow = true,
}: DetailsContainerProps) {
  const {
    accessorKey,
    getSummaryText,
    graphData,
    headerColumn,
    isGoalDetail,
    isLinearScale,
    kpiValues,
    lineChartTarget,
    lineChartXAxis,
    setIsLinearScale,
    downloadCSV,
    availableGranularities,
    availablePeriods,
    getCheckboxData,
    hasScale,
    activeNestedTab,
    changeNestedTabHandler,
    nestedTabItems,
    selectedPeriod,
    selectedGranularity,
    onApplyFilterHandler,
    deliveryRegimeForCurrentTab,
    selectedDeliveryRegime,
    setSelectedDeliveryRegime,
    isFetching,
    selectedKPI,
  } = useNestedTabDetailViewModel(
    regions,
    category,
    kpi,
    domain,
    isTargetShown,
    zone
  );

  const showAlert = useAppSelector(showTargetChangeAlert) && isAltertToShow;
  const dispatch = useAppDispatch();

  const referenceDate = () => {
    switch (activeNestedTab) {
      case NESTED_TABS.VALIDATED_INDICATOR: {
        return selectedKPI?.referenceDateDisplayName;
      }
      case NESTED_TABS.NUMERATOR: {
        return selectedKPI?.numeratorLastUpdateDisplayName;
      }
      case NESTED_TABS.DENOMINATOR: {
        return selectedKPI?.denominatorLastUpdateDisplayName;
      }
    }
  };
  return (
    <div data-testid="nested-tab-detail-container">
      <Row>
        <Col>
          <Text
            children={title}
            type={TEXT_ENUM.TITLE_H3}
            customClass={`${TEXT_ENUM.TITLE_H5} my-4`}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-4">
          <Tabbar
            tabs={nestedTabItems}
            activeTab={activeNestedTab}
            onChange={changeNestedTabHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <IndicatorDetailCard
            selectedCompany={selectedCompany}
            regions={regions}
            summaryText={getSummaryText()}
            referenceDate={referenceDate() ?? ""}
            isFetching={isFetching}
            checkbox={getCheckboxData()}
            hasScale={hasScale}
            periods={availablePeriods}
            domain={domain}
            granularities={availableGranularities}
            selectedPeriod={selectedPeriod}
            selectedGranularity={selectedGranularity}
            selectedRegion={selectedRegion}
            onApplyFilters={onApplyFilterHandler}
            onChangeLineChartScale={() => {
              setIsLinearScale(!isLinearScale);
            }}
            lineChartValuesByEntity={graphData}
            lineChartTarget={
              activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR && hasTarget
                ? lineChartTarget
                : undefined
            }
            lineChartXAxis={lineChartXAxis}
            noDataLabel={noDataLabel}
            lineChartScale={
              activeNestedTab !== NESTED_TABS.VALIDATED_INDICATOR
                ? isLinearScale
                  ? NUMBER_TYPE.LINEAR
                  : NUMBER_TYPE.LOG
                : NUMBER_TYPE.LINEAR
            }
            tableComponent={
              <DetailTableInCard
                headerColumn={headerColumn}
                columns={lineChartXAxis}
                accessorKey={accessorKey()}
                dataTable={kpiValues}
                isGoalDetail={isGoalDetail}
              />
            }
            csvButton={downloadCSV}
            isValidatedContainer={
              activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR
            }
            deliveryRegimes={deliveryRegimeForCurrentTab}
            selectedDeliveryRegime={selectedDeliveryRegime}
            setSelectedDeliveryRegime={setSelectedDeliveryRegime}
            hasTarget={
              activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR && hasTarget
            }
            isTargetToShow={
              activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR &&
              isTargetShown
            }
            isGoalDetail={isGoalDetail}
            alert={
              isGoalDetail &&
              activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR && (
                <Alert
                  show={showAlert}
                  dismissible
                  onClose={() => dispatch(targetChangeClosed())}
                  className="ps-3"
                >
                  {t("indicators.detail.targetChangedAlert")}
                </Alert>
              )
            }
          />
        </Col>
      </Row>
    </div>
  );
}
