import { TableOptions } from "@tanstack/react-table";
import { TFuncKey } from "i18next";

export type TableColumnNationalProps = {
  regionDescription?: { description: string; action?: () => void };
  band?: string;
  target?: number;
  difference?: number;
  value?: number | string;
  goal?: undefined;
  referenceDate?: Date;
  denominator?: number;
  nominator?: number;
};

export interface TableColumnRegionalProps {
  entityDescription: {
    description: string;
    action?: () => void;
  };
  indicatorValue: number | string;
  numerator: number;
  denominator: number;
  referenceDate: Date;
}

export enum GENERIC_ENTITY_COLUMN_LABEL {
  REGION_DESCRIPTION = "indicators.nationTable.headers.region",
  ASL_DESCRIPTION = "indicators.regionTable.headers.aslDescription",
  INDICATOR_VALUE = "indicators.regionTable.headers.indicatorValue",
  NUMERATOR = "indicators.regionTable.headers.numerator",
  DENOMINATOR = "indicators.regionTable.headers.denominator",
  REFERENCE_DATE = "indicators.regionTable.headers.referenceDate",
  GO_TO_DETAIL = "indicators.regionTable.headers.action",
}

export const GENERIC_ENTITY_COLUMN_WITH_NUMBERS: TFuncKey[] = [
  GENERIC_ENTITY_COLUMN_LABEL.INDICATOR_VALUE,
  GENERIC_ENTITY_COLUMN_LABEL.NUMERATOR,
  GENERIC_ENTITY_COLUMN_LABEL.DENOMINATOR,
];

export type GenericEntityTableDataProps = Omit<
  TableOptions<TableColumnRegionalProps>,
  "getCoreRowModel" | "columns"
>;

export const ENTITY_WITH_TARGET_COLUMN_WITH_NUMBERS: TFuncKey[] = [
  "indicators.nationTable.headers.target",
  "indicators.nationTable.headers.distance",
  "indicators.nationTable.headers.value",
  "indicators.nationTable.headers.date",
];
