import React from "react";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import "./Pill.scss";
import { PillProps } from "./models/Pill.model";
import { BUTTON_MODE } from "../Button/models/Buttons.model";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

const Pill: React.FC<PillProps> = ({
  text,
  hasIcon,
  clicked,
  onClick,
  disabled,
  mode,
  customClass,
}) => {
  const className = `${onClick ? "Pill__withCursor" : ""} ${
    clicked ? "text-bg-primary" : ""
  } ${mode}`;

  const disabledPill = ` ${mode} Pill__disabled`;
  return (
    <Button
      customClass={`d-flex badge ${
        disabled ? disabledPill : className
      } ${customClass} rounded-pill`}
      id="pill-div"
      onClick={onClick ? onClick : () => {}}
      mode={!clicked ? BUTTON_MODE.OUTLINE_PRIMARY : undefined}
    >
      {text}
      {hasIcon && (
        <Icon
          iconClass={clicked ? ICON_CLASS.CROSS_SMALL : ICON_CLASS.PLUS}
          id="pill-icon"
        />
      )}
    </Button>
  );
};

export default Pill;
