//indicators navbar items enum
export enum INDICATORS_NAVBAR_ITEM_ID {
  INDICATORS = "indicators",
  POWER = "power",
  GOAL = "goal",
  APPLICATION = "application",
}
export enum INDICATORS_NAVBAR_ITEM_LABEL {
  INDICATORS = "navBar.indicators.indicators",
  POWER = "navBar.indicators.power",
  GOAL = "navBar.indicators.goals",
  APPLICATION = "navBar.indicators.application",
}

//glossary navbar items enum
export enum GLOSSARY_NAVBAR_ITEM_ID {
  GLOSSARY = "glossary",
}
export enum GLOSSARY_NAVBAR_ITEM_LABEL {
  GLOSSARY = "shared.header.menu.items.glossary",
}

//home_page navbar items enum
export enum HOME_PAGE_NAVBAR_ITEM_ID {
  HOME_PAGE = "home",
}
export enum HOME_PAGE_NAVBAR_ITEM_LABEL {
  HOME_PAGE = "shared.header.menu.items.home",
}

//data_quality navbar items enum
export enum DATA_QUALITY_NAVBAR_ITEM_ID {
  DATA_QUALITY = "dataQuality",
}
export enum DATA_QUALITY_NAVBAR_ITEM_LABEL {
  DATA_QUALITY = "shared.header.menu.items.dataQuality",
}

//report navbar items enum
export enum REPORT_NAVBAR_ITEM_ID {
  REPORT = "report",
}
export enum REPORT_NAVBAR_ITEM_LABEL {
  REPORT = "shared.header.menu.items.report",
}

//documentation navbar items enum
export enum DOCUMENTATION_NAVBAR_ITEM_ID {
  DOCUMENTATION = "documentation",
}
export enum DOCUMENTATION_NAVBAR_ITEM_LABEL {
  DOCUMENTATION = "shared.header.menu.items.doc",
}
