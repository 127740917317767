import React from "react";

const GithubIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.318"
      height="19.829"
      viewBox="0 0 29.318 35.829"
    >
      <path
        id="github-mark"
        d="M14.671,0a14.795,14.795,0,0,0-4.639,28.8c.729.147,1-.318,1-.709,0-.343-.024-1.517-.024-2.741-4.081.881-4.931-1.762-4.931-1.762a3.776,3.776,0,0,0-1.628-2.153c-1.336-.905.1-.905.1-.905A3.077,3.077,0,0,1,6.8,22.048a3.108,3.108,0,0,0,4.275,1.223A3.149,3.149,0,0,1,12,21.289C8.744,20.946,5.32,19.674,5.32,14a5.786,5.786,0,0,1,1.506-3.964,5.376,5.376,0,0,1,.146-3.915S8.21,5.726,11,7.635a13.986,13.986,0,0,1,7.335,0c2.793-1.909,4.032-1.517,4.032-1.517a5.375,5.375,0,0,1,.146,3.915A5.675,5.675,0,0,1,24.022,14c0,5.677-3.425,6.925-6.7,7.292a3.51,3.51,0,0,1,1,2.741c0,1.982-.024,3.573-.024,4.062,0,.392.267.857,1,.71A14.795,14.795,0,0,0,14.671,0Z"
        fill="#06c"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default React.memo(GithubIcon);
