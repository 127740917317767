import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "@components/UI/Collapse/Collapse";
import "./CollapseSection.scss";
import { CollapseSectionProps } from "./models/CollapseSection.models";

const CollapseSection: React.FC<CollapseSectionProps> = ({
  children,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const buttonText = isCollapsed
    ? t("shared.collapse.detail-expand")
    : t("shared.collapse.detail-close");
  return (
    <div className="CollapseSection">
      <Collapse
        id="collapse-detail-section"
        buttonText={buttonText}
        isCollapsed={isCollapsed}
        setAriaExpanded={() => setIsCollapsed(!isCollapsed)}
        disabled={disabled}
      >
        {children}
      </Collapse>
    </div>
  );
};
export default React.memo(CollapseSection);
