import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import {
  sortAlphaDown,
  sortAlphaUp,
} from "@utils/function/objectsArrayFunctions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckBoxItem, useCheckboxProps } from "./models";

export default function useCheckbox<T, K extends keyof T, V extends keyof T>({
  items,
  keyField,
  labelField,
  parentCheckedId,
}: useCheckboxProps<T, K, V>) {
  const [searchedValue, setSearchedValue] = useState<string | undefined>();
  const [checkboxItems, setCheckboxItems] = useState<CheckBoxItem<T, K, V>[]>(
    []
  );
  const [defaultValue, setDefaultValue] = useState<CheckBoxItem<T, K, V>[]>([]);

  const [isASCSorted, setIsASCSorted] = useState(true);

  const hasNationalValue = items.find(
    (item) => item[keyField] === FILTERS_KEY.NATIONAL_VALUE_ID
  );
  const maxCheckedItems = hasNationalValue ? 6 : 5;

  useEffect(() => {
    setDefaultValue(
      items
        .map((item, index) => ({
          ...item,
          isChecked:
            item[keyField] === FILTERS_KEY.NATIONAL_VALUE_ID ||
            item[keyField] === parentCheckedId
              ? true
              : index < maxCheckedItems,
        }))
        .filter((item) => item.isChecked)
    );
  }, [items, keyField, maxCheckedItems, parentCheckedId]);

  useEffect(() => {
    if (items && keyField)
      setCheckboxItems((checkboxItems) => {
        //temporaly workaround for "region select bug" issue #624
        if (
          checkboxItems.length < maxCheckedItems ||
          parentCheckedId !== undefined
        ) {
          return items.map((item, index) => {
            return {
              ...item,
              isChecked:
                item[keyField] === FILTERS_KEY.NATIONAL_VALUE_ID ||
                item[keyField] === parentCheckedId
                  ? true
                  : index < maxCheckedItems,
            };
          });
        } else {
          return items.map((item) => ({
            ...item,
            isChecked:
              item[keyField] === FILTERS_KEY.NATIONAL_VALUE_ID
                ? true
                : checkboxItems.find(
                    (_item) => _item[keyField] === item[keyField]
                  )?.isChecked ?? false,
          }));
        }
      });
  }, [items, keyField, maxCheckedItems, parentCheckedId]);

  useEffect(() => {
    setCheckboxItems((checkboxItems) =>
      isASCSorted
        ? // @ts-ignore
          sortAlphaUp(checkboxItems, labelField)
        : // @ts-ignore
          sortAlphaDown(checkboxItems, labelField)
    );
  }, [items, isASCSorted, keyField, labelField]);

  const filteredCheckboxItems = useMemo(() => {
    if (!searchedValue) {
      return checkboxItems;
    }

    return checkboxItems.filter((checboxItem) =>
      checboxItem[labelField]
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
    );
  }, [checkboxItems, labelField, searchedValue]);

  const checkedItems = useMemo(
    () => checkboxItems.filter((item) => item.isChecked),
    [checkboxItems]
  );

  const allChecked = useMemo(
    () => filteredCheckboxItems.every((item) => item.isChecked),
    [filteredCheckboxItems]
  );

  const onCheck = useCallback(
    (itemToSearch: T) => {
      const matchedItem = checkboxItems.find(
        (checkboxItems) => checkboxItems[keyField] === itemToSearch[keyField]
      );

      if (matchedItem) {
        matchedItem.isChecked = !matchedItem.isChecked;
        setCheckboxItems([...checkboxItems]);
      }
      setSearchedValue("");
    },
    [checkboxItems, keyField]
  );

  const onCheckAll = useCallback(() => {
    const newAllChecked = !allChecked;

    if (
      filteredCheckboxItems.length > 0 &&
      filteredCheckboxItems.length < checkboxItems.length
    ) {
      setCheckboxItems((checkboxItems) =>
        checkboxItems.map((checkboxItem) => ({
          ...checkboxItem,
          isChecked: filteredCheckboxItems.includes(checkboxItem)
            ? true
            : checkboxItem.isChecked,
        }))
      );
    } else {
      setCheckboxItems((checkboxItems) =>
        checkboxItems.map((checkboxItem) => ({
          ...checkboxItem,
          isChecked: newAllChecked,
        }))
      );
    }

    setSearchedValue("");
  }, [allChecked, checkboxItems.length, filteredCheckboxItems]);

  const onSearch = useCallback((text?: string) => {
    setSearchedValue(text);
  }, []);

  const onReset = useCallback(() => {
    setCheckboxItems((checkboxItems) =>
      checkboxItems.map((item) => {
        const isDefault = defaultValue?.find(
          // @ts-ignore
          (defItem) => defItem.id === item.id
        );
        if (isDefault) return isDefault;
        else
          return {
            ...item,
            isChecked: false,
          };
      })
    );
  }, [defaultValue]);

  const onSort = useCallback(() => {
    setIsASCSorted((isASCSorted) => !isASCSorted);
  }, []);

  return {
    checkedItems,
    items: filteredCheckboxItems,
    allChecked,
    isASCSorted,
    setSearchedValue,
    searchedValue,
    onReset,
    keyField,
    labelField,
    onCheck,
    onCheckAll,
    onSearch,
    onSort,
  };
}
