import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "@components/UI/Select/Select";
import "./HistogramSelect.scss";
import Button from "@components/UI/Button/Button";
import { Col, Row } from "react-bootstrap";
import { LabelPosition } from "@components/UI/Select/models/Select.models";
import { LableDomain } from "@models/Glossary/Glossary.model";
import {
  HISTOGRAM_SELECT_OPTION,
  HistogramSelectProps,
  selectOptions,
} from "./models/HistogramSelect.models";

const HistogramSelect: React.FC<HistogramSelectProps> = ({
  onFilter,
  options = selectOptions,
}) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<LableDomain>(options[0]);

  const applyFilterHandler = () => {
    onFilter(selectedFilter);
  };

  const onChangeFilterHandler = (filter?: LableDomain) => {
    filter && setSelectedFilter(filter);
  };

  return (
    <Row className="HistogramSelect mb-3 mb-lg-0">
      <Col xs={12} md={"auto"} className="HistogramSelect__Col">
        <Select
          label={t("filters.sortBy")}
          labelPosition={LabelPosition.Left}
          options={options}
          onChange={onChangeFilterHandler}
          value={selectedFilter}
          labelKey={HISTOGRAM_SELECT_OPTION.LABEL}
          valueKey={HISTOGRAM_SELECT_OPTION.VALUE}
          customClass="HistogramSelect__Col--select"
          focusable={false}
        />
      </Col>

      <Col className="HistogramSelect__Col">
        <Button
          customClass="HistogramSelect__Col--button"
          onClick={applyFilterHandler}
          tabIndex={-1}
        >
          {t("filters.button")}
        </Button>
      </Col>
    </Row>
  );
};

export default HistogramSelect;
