import { RefObject, useEffect, useState } from "react";
import { MOBILE_WIDTH, HEIGHT_FACTOR, DEFAULT_HEIGHT } from "@utils/constants";

export const useHeadlineHeight = (
  headlineRef: RefObject<HTMLDivElement>,
  isWide: boolean | undefined,
  isTitlePage: boolean | undefined
) => {
  const [headlineHeight, setHeadlineHeight] = useState<number>();

  useEffect(() => {
    const updateHeight = () => {
      const width = window.innerWidth;
      const height =
        width >= MOBILE_WIDTH && !isWide && !isTitlePage
          ? HEIGHT_FACTOR / width
          : DEFAULT_HEIGHT;

      if (headlineRef && headlineRef.current) setHeadlineHeight(height);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [headlineRef, isTitlePage, isWide]);
  return headlineHeight;
};
