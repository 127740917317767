import { useTranslation } from "react-i18next";
import React from "react";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import "./NonCumulativeIndicatorDifference.scss";
import { NonCumulativeIndicatorDifferenceProps } from "./NonCumulativeIndicatorDifference.model";
import { NA_VALUE } from "@utils/constants";

export const NonCumulativeIndicatorDifference: React.FC<
  NonCumulativeIndicatorDifferenceProps
> = (props) => {
  const { t } = useTranslation();

  const isDifferencePositive =
    typeof props.difference === "number" && props.difference >= 0;

  const differenceText = `${isDifferencePositive ? "+" : ""}${
    props.difference
  } `;

  const differencePercentage =
    typeof props.difference === "number" && typeof props.numerator === "number"
      ? props.difference / (props.numerator - props.difference)
      : NA_VALUE;

  const differencePercentageText = `(${t("shared.percent", {
    val: differencePercentage,
  })})`;

  return (
    <div>
      <div className=" NonCumulativeIndicatorDifference__container">
        <span>
          <Text
            type={TEXT_ENUM.TITLE_H4}
            id="difference"
            customClass={` NonCumulativeIndicatorDifference__difference NonCumulativeIndicatorDifference__difference${
              isDifferencePositive ? "--positive" : "--negative"
            }`}
          >
            {differenceText}
          </Text>
        </span>
        <Text
          type={TEXT_ENUM.TITLE_H5}
          id="differencePercentage"
          customClass="NonCumulativeIndicatorDifference__difference-percentage"
        >
          {differencePercentageText}
        </Text>
      </div>
      <Text
        type={TEXT_ENUM.PARAGRAPH}
        customClass={
          props.customTextClass ?? "NonCumulativeIndicatorDifference__timeText"
        }
      >{`${t("indicators.nonCumulativeDifference")}`}</Text>
    </div>
  );
};
