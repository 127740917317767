export enum KPI_CATEGORY {
  POWER = "Alimentazione",
  GOAL = "Obiettivo",
  USAGE = "Utilizzo",
}
export enum KPI_CATEGORY_VALUE {
  POWER = "alimentazione",
  GOAL = "obiettivo",
  USAGE = "utilizzo",
}
