import { useSelector } from "react-redux";
import { RegionsMap, Region } from "@models/Companies/companies.model";
import { selectRegion } from "src/redux/selectors/selections.selectors";

const useSelectedRegion = (regions: RegionsMap) => {
  let selectedRegionId = useSelector(selectRegion);
  let selectedRegion: Region;

  if (selectedRegionId && regions[selectedRegionId]) {
    selectedRegion = regions[selectedRegionId];
  } else {
    selectedRegionId =
      regions && Object.values(regions).length
        ? Object.values(regions)[0].id
        : undefined;
    selectedRegion =
      regions && Object.values(regions).length
        ? Object.values(regions)[0]
        : ({} as Region);
  }

  return {
    selectedRegionId,
    selectedRegion,
  };
};

export default useSelectedRegion;
