//add here react bootstrap icon classes
export enum ICON_CLASS {
  ARROW_LEFT = "bi bi-arrow-left",
  ARROW_UP = "bi bi-arrow-up",
  CHEVRON_DOWN = "bi bi-chevron-down",
  CHEVRON_UP = "bi bi-chevron-UP",
  ARROW_RIGHT = "bi bi-arrow-right",
  BOX_ARROW_UP_RIGHT = "bi bi-box-arrow-up-right",
  BOX_ARROW_RIGHT = "bi bi-box-arrow-right",
  PERSON_CIRCLE = "bi bi-person-circle",
  INFO_CIRCLE = "bi bi-info-circle",
  TROPHY = "bi bi-trophy",
  HAMBURGER_MENU = "bi bi-list",
  CROSS = "bi bi-x-lg",
  CROSS_SMALL = "bi bi-x",
  USER_CIRCLE = "bi  bi-person-circle",
  ARROW_DOWN_SQUARE = "bi bi-arrow-down-square",
  ARROW_DOWN_SQUARE_FILL = "bi bi-arrow-down-square-fill",
  SEARCH = "bi bi-search",
  DOWNLOAD = "bi bi-download",
  WARNING = "bi bi-exclamation-circle-fill",
  INFO = "bi bi-exclamation-circle",
  CHECK_CIRCLE_FILL = "bi bi-check-circle-fill",
  X_CIRCLE_FILL = "bi bi-x-circle-fill",
  DASH = "bi bi-dash",
  PLUS = "bi bi-plus",
  STAR = "bi bi-star",
  STAR_FILL = "bi bi-star-fill",
  CHEVRON_LEFT = "bi bi-chevron-left",
  CHEVRON_RIGHT = "bi bi-chevron-right",
  TRIANGLE_WARNING = "bi bi-exclamation-triangle-fill",

  //SORT
  SORT_ALPHA_DOWN = "bi bi-sort-alpha-down",
  SORT_ALPHA_UP = "bi bi-sort-alpha-up",
  SORT_ALPHA_DOWN_ALT = "bi bi-sort-alpha-down-alt",
  SORT_NUM_DOWN = "bi bi-sort-numeric-down",
  SORT_NUM_DOWN_ALT = "bi bi-sort-numeric-down-alt",
  SORT_NUM_UP = "bi bi-sort-numeric-up",
}
