import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export const showTargetChangeAlert = createSelector(
  (state: RootState) => state,
  (state) => state.alert.showTargetChangeAlert
);

export const showTokenIsExpiringAlert = createSelector(
  (state: RootState) => state,
  (state) => state.alert.showTokenIsExpiringAlert
);

export const showEvolvingDataAlert = createSelector(
  (state: RootState) => state,
  (state) => state.alert.showEvolvingDataAlert
);
