import { LeafTFuncKey } from "src/i18n";
export interface TabItem {
  id: string;
  label: LeafTFuncKey;
  isDisabled?: boolean;
}

export enum TAB_BAR_DIRECTION {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum NESTED_TABS {
  VALIDATED_INDICATOR = "indicator",
  NUMERATOR = "numerator",
  DENOMINATOR = "denominator",
}

export type TabbarProps<T extends TabItem> = {
  tabs: T[];
  activeTab: T["id"];
  onChange: (tab: T) => void;
  direction?: TAB_BAR_DIRECTION;
  showBorders?: boolean;
};
