import {
  useGetKPIsQuery,
  useGetProfileQuery,
} from "@api/orchestrator/orchestrator.services";
import { TabItem } from "@components/UI/Tabbar/models/Tabbar.models";
import { useGoalsOutletContext } from "@hooks/useGoalsOutletContext";
import useSelectedEnterprise from "@hooks/useSelectedEnterprise";
import useSelectedRegion from "@hooks/useSelectedRegion";
import { Region } from "@models/Companies/companies.model";
import { LableDomain } from "@models/Glossary/Glossary.model";
import { Kpi, Kpis } from "@utils/KPI";
import { DEFAULT_GRANULARITY, DEFAULT_PERIOD } from "@utils/constants";
import { KPI_CATEGORY_VALUE } from "@utils/enum/kpiCategory";
import { useCallback, useMemo, useState } from "react";
import { locationTab } from "src/redux/selectors/locationTab.selectors";
import { storeLocationTab } from "src/redux/slices/locationTab.slice";
import { selectionChanged } from "src/redux/slices/selections.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectOptions } from "../components/HistogramSelect/models/HistogramSelect.models";
import {
  ENTERPRISE_TAB_ENUM,
  NATION_TAB_ENUM,
  REGION_TAB_ENUM,
  STRUCTURE_TAB_ENUM,
} from "../models/tabs.models";

export const useIndicatorsDetailViewModel = (
  kpiCategory: KPI_CATEGORY_VALUE,
  currentKpi: Kpi
) => {
  const dispatch = useAppDispatch();
  const localityTab = useAppSelector(locationTab) as Tabs; //FIXME

  const { glossaries, regions } = useGoalsOutletContext();

  const glossaryForSelectedKpi = useMemo(() => {
    const _glossary = glossaries.get(currentKpi)!; //FIXME
    return {
      ..._glossary,
      tags: _glossary.tags.map((tag) => tag.description),
    };
  }, [currentKpi, glossaries]);

  const linkedIndicators = useMemo(
    () =>
      glossaryForSelectedKpi?.linked
        ?.map((id) => glossaries.get(id)!) //FIXME
        .map((kpi) => ({
          ...kpi,
          tags: kpi?.tags?.map((tag) => tag?.description),
        }))
        .filter(Boolean),
    [glossaries, glossaryForSelectedKpi.linked]
  );

  const allRegions = useMemo(() => Object.values(regions ?? {}), [regions]);
  const { selectedRegion } = useSelectedRegion(regions);
  const { selectedCompanyId, setSelectedCompany } =
    useSelectedEnterprise(selectedRegion);

  const selectedCompany = selectedRegion.companies[selectedCompanyId];

  const getLastKpiApiResponse = useGetKPIsQuery({
    KPIs: Kpis[kpiCategory],
    period: DEFAULT_PERIOD,
    sampling: DEFAULT_GRANULARITY,
    region: localityTab !== NATION_TAB_ENUM.ID ? selectedRegion.id : undefined,
    company:
      localityTab === ENTERPRISE_TAB_ENUM.ID ? selectedCompany.code : undefined,
  });

  const lastKpi = useMemo(
    () => getLastKpiApiResponse.data?.kpis.find((kpi) => kpi.id === currentKpi),
    [currentKpi, getLastKpiApiResponse.data?.kpis]
  );

  const isGoalDetail = kpiCategory === KPI_CATEGORY_VALUE.GOAL;

  const kpisToRequest = Kpis[kpiCategory];

  const activeSlide = useMemo(
    () => kpisToRequest.findIndex((kpi) => kpi === currentKpi),
    [currentKpi, kpisToRequest]
  );

  const { data } = useGetProfileQuery();

  const showNation = data?.hasCentralInstitution ?? false;

  const tabs = _tabs.filter(
    (tab) => showNation || (!showNation && tab.id !== "nation")
  );

  const [selectedHistogramFilter, setSelectedHistogramFilter] =
    useState<LableDomain>(selectOptions[0]);

  const setSelectedRegion = useCallback(
    (newRegion: Region | undefined) => {
      dispatch(
        selectionChanged({
          region: newRegion?.id,
        })
      );
    },
    [dispatch]
  );

  const setLocalityTab = useCallback(
    (path: string) => {
      dispatch(storeLocationTab(path));
    },
    [dispatch]
  );

  const onClickLocalityTabHandler = (tab: TabItem) => {
    setLocalityTab(tab.id);
  };

  return {
    glossaries,
    allRegions,
    tabs,
    localityTab,
    onClickLocalityTabHandler,
    linkedData: linkedIndicators,
    activeSlide,
    kpisToRequest,
    selectedHistogramFilter,
    changeHistogramFilterHandler: setSelectedHistogramFilter,
    selectedRegion,
    setSelectedRegion,
    setLocalityTab,
    selectedCompany,
    setSelectedCompany,
    isGoalDetail,
    glossaryForSelectedKpi,
    lastKpi,
  };
};

const _tabs = [
  {
    id: NATION_TAB_ENUM.ID,
    label: NATION_TAB_ENUM.LABEL,
  },
  {
    id: REGION_TAB_ENUM.ID,
    label: REGION_TAB_ENUM.LABEL,
  },
  {
    id: ENTERPRISE_TAB_ENUM.ID,
    label: ENTERPRISE_TAB_ENUM.LABEL,
  },
  {
    id: STRUCTURE_TAB_ENUM.ID,
    label: STRUCTURE_TAB_ENUM.LABEL,
    isDisabled: true,
  },
] satisfies TabItem[];

export type Tabs = (typeof _tabs)[number]["id"];
