import { EChartsOption, LineSeriesOption } from "echarts";
import ReactEcharts from "echarts-for-react";
import { t } from "i18next";
import React, { FC, useMemo } from "react";
import { LineChartProps, LineColor, LineScale } from "./LineChart.models";
import "./LineChart.scss";
import { NATIONAL_VALUE_LABEL, OUT_OF_SCALE_VALUE } from "@utils/constants";

const LineChart: FC<LineChartProps> = ({
  regions,
  values,
  xAxis,
  target,
  scale = LineScale.Linear,
  type = "number",
  hasPercentageY = false,
  hasTarget,
  isNational = false,
  noDataRectangle,
}) => {
  const region = Object.entries(values)?.find((value) =>
    regions?.some((region) => value[0] === region.description)
  )?.[0];

  const maxValue = useMemo(() => {
    const maxValues = Object.values(values).reduce((acc, arr) => {
      const numbers = arr.map((value) =>
        typeof value === "number" ? value : 0
      ) as number[];
      return acc.concat(numbers);
    }, [] as number[]) as unknown as number[]; // to check
    return Math.max(...maxValues);
  }, [values]);

  const lines: LineSeriesOption[] = useMemo(
    () =>
      Object.entries(values).map(([value, data]) => {
        return {
          type: "line",
          lineStyle: {
            type: isDashed(value, isNational, region) ? "dashed" : "solid",
            color: formatColor(value, data, target, isNational, region),
          },
          connectNulls: false,
          data:
            scale === LineScale.Log
              ? data.map((datum) => (datum === 0 ? "" : datum))
              : data,
          name: value,
          endLabel: {
            show:
              scale !== LineScale.Log ||
              (scale === LineScale.Log && data[0] !== 0),
            formatter: formatLabel(value, isNational, region),
            rich: {
              bold: {
                fontWeight: "bold",
              },
            },
          },
          emphasis: {
            focus: "series",
          },
        };
      }),
    [isNational, region, scale, target, values]
  );

  const targetLine: LineSeriesOption = useMemo(() => {
    const markLine = {
      symbol: "none",
      data: [{ yAxis: target }],
      lineStyle: {
        color: LineColor.Normal,
        type: [15, 5, 3, 5],
        width: 3,
      },
      label: {
        show: true,
        formatter: t("shared.charts.lineChart.target"),
      },
      animation: false,
    };

    return {
      name: target,
      type: "line",
      markLine:
        target !== undefined && hasTarget && lines.length !== 0 ? markLine : {},
    };
  }, [target, hasTarget, lines.length]);

  const options: EChartsOption = useMemo(
    () => ({
      dataZoom: [
        {
          type: "slider",
          zoomLock: true,
          zoomOnMouseWheel: false,
        },
        {
          type: "inside",
          zoomLock: true,
          zoomOnMouseWheel: false,
        },
      ],
      xAxis: {
        data: xAxis,
        offset: 0,
        boundaryGap: false,
      },
      yAxis: {
        max: Math.max(maxValue, target ?? 0),
        type: scale === LineScale.Log ? LineScale.Log : "value",
        axisLabel: {
          showMaxLabel: maxValue !== 0,
          showMinLabel: true,
          formatter: (value: number) =>
            hasPercentageY
              ? t("shared.percent", { val: value })
              : t("shared.number", { val: value }),
        },
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: /* istanbul ignore next */ (val) =>
          t(`shared.${type}`, {
            val,
          }),
        formatter: (val) => {
          return renderTooltipContent(
            val,
            target,
            region,
            hasPercentageY,
            isNational
          );
        },
      },
      animationEasing: "quadraticOut",
      animationDuration: 1000,
      color: LineColor.Normal,
      series: [...lines, targetLine],
    }),
    [
      xAxis,
      maxValue,
      target,
      scale,
      lines,
      targetLine,
      hasPercentageY,
      type,
      region,
      isNational,
    ]
  );

  return (
    <div data-testid="linechart" className="line-chart">
      {noDataRectangle}
      <ReactEcharts
        notMerge
        option={options}
        opts={{ renderer: "svg" }}
        style={{ height: 400 }}
      />
    </div>
  );
};

export default React.memo(LineChart);

function renderTooltipContent(
  val: any,
  target: number | undefined,
  region: string | undefined,
  hasPercentageY: boolean,
  isNational: boolean
) {
  const title = `<div class="mb-2">${val[0].name}</div>`;
  const tooltipItems = val.map((item: any) => {
    const isParentCondition = isDashed(item.seriesName, isNational, region);

    return `
    <div>
      <div class="LineChart__box">
        <div class="LineChart__dot LineChart__dot" style="color: ${getColorByTarget(
          isParentCondition
        )}">${"\u2022"}</div>
        <div class="LineChart__text LineChart__text--fraction">${
          item.seriesName
        }</div>
        <div class="LineChart__text LineChart__text--fraction" style="color: ${getColorByTarget(
          isParentCondition
        )}">${
      typeof item.value === "string"
        ? item.value === ""
          ? 0
          : item.value === OUT_OF_SCALE_VALUE
          ? t("shared.charts.percentage.outOfScale")
          : item.value
        : hasPercentageY
        ? t("shared.percent", { val: item.value })
        : t("shared.number", { val: item.value })
    }</div>
      </div>
    </div>
  `;
  });
  return title + tooltipItems.join("");
}

function formatColor(
  value: string,
  data: (string | number)[],
  target: number | undefined,
  isNational: boolean,
  region: string | undefined
) {
  return isDashed(value, isNational, region)
    ? LineColor.Region
    : getColorByTarget(undefined);
}

function getColorByTarget(isParent?: boolean | undefined) {
  return isParent ? LineColor.Region : LineColor.Normal;
}

function formatLabel(
  value: string,
  isNational: boolean,
  region: string | undefined
) {
  return isDashed(value, isNational, region)
    ? `${t("shared.charts.lineChart.regionLabel", { region: value })}`
    : value;
}

function isDashed(
  value: string,
  isNational: boolean,
  region: string | undefined
) {
  if (isNational) {
    return value === NATIONAL_VALUE_LABEL;
  }
  return value === region;
}
