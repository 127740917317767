import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SideBarProps } from "./Sidebar.model";
import { useAuth } from "react-oidc-context";
import { getCurrentDateTime } from "@utils/DateTime/DateTime";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import "./Sidebar.scss";
import { BUTTON_MODE } from "../Button/models/Buttons.model";
import Icon from "../Icon/Icon";
import { TEXT_ENUM } from "../Text/models/Text.models";
import Text from "../Text/Text";
import Button from "../Button/Button";
import packageJson from "../../../../package.json";

const SideBar = ({ activeRoute, sections, user }: SideBarProps) => {
  const { t } = useTranslation();
  const lastLogin = useMemo(getCurrentDateTime, []);
  const name = user.name.toLowerCase();
  const lastname = user.lastname.toLowerCase();
  const { revokeTokens, removeUser } = useAuth();

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <nav
        className="sidebar sidebar-expand-lg sidebar-light"
        aria-label={t("shared.header.menu.hidden.nav")}
      >
        <div className="Sidebar__header">
          <Text type={TEXT_ENUM.TITLE_H5} customClass="sidebar-title">
            {t("shared.header.menu.title").toUpperCase()}
            <span className="visually-hidden">
              {t("shared.header.menu.hidden.span")}
            </span>
          </Text>
          <div data-bs-dismiss="offcanvas">
            <Icon
              iconClass={ICON_CLASS.CROSS}
              customClass="Sidebar__closeIcon"
            />
          </div>
        </div>
        <div className="sidebar-collapse" id="sidebar-menu">
          {sections.map((section, sectionindex) => {
            return (
              <ul
                className="nav"
                key={sectionindex}
                data-testid={`section-${sectionindex}`}
              >
                {section.items.map((_item, index) => {
                  return (
                    <li
                      className={`nav-item ${
                        _item.isExpandible ? "dropdown" : ""
                      }`}
                      key={index}
                      data-testid={`item-${sectionindex}-${index}`}
                      data-bs-toggle={_item.isExpandible ? "" : "offcanvas"}
                      data-bs-target={_item.isExpandible ? "" : "#ex-offcanvas"}
                      aria-controls={_item.isExpandible ? "" : "ex-offcanvas"}
                    >
                      {_item.isExpandible ? (
                        <div className="nav-item">
                          <a
                            href={`#ex-sidebar-light-submenu-${index}`}
                            className="nav-link collapse-toggle collapsed"
                            data-bs-toggle="collapse"
                            aria-controls={`ex-sidebar-light-submenu-${index}`}
                            aria-expanded="false"
                          >
                            {t(_item.title)}
                          </a>
                          <div
                            id={`ex-sidebar-light-submenu-${index}`}
                            className="collapse"
                          >
                            <ul
                              className="nav"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#ex-offcanvas"
                              aria-controls="ex-offcanvas"
                            >
                              {_item.expandibleItems?.map(
                                (_expandibleItem, _index) => {
                                  return (
                                    <Link
                                      key={`sidebar-expandedItem-${_index}`}
                                      className={`nav-link ${
                                        activeRoute === _expandibleItem.href
                                          ? "active Sidebar__activeItem"
                                          : ""
                                      }
                                      ${
                                        _expandibleItem.isDisabled && "disabled"
                                      }
                                      }`}
                                      to={_expandibleItem.href}
                                    >
                                      {t(_expandibleItem.title)}
                                    </Link>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <Link
                          data-testid={`sidebar-item-${index}`}
                          key={`sidebar-item-${index}`}
                          className={`nav-link ${
                            activeRoute === _item.href
                              ? "active Sidebar__activeItem"
                              : ""
                          }
                        ${_item.isDisabled && "disabled"}
                        }`}
                          to={_item.href}
                        >
                          {t(_item.title)}
                        </Link>
                      )}
                    </li>
                  );
                })}
                {sectionindex !== sections.length - 1 && (
                  <div
                    className="nav-divider"
                    aria-hidden="true"
                    data-testid="list-divider"
                  />
                )}
              </ul>
            );
          })}
        </div>
      </nav>
      <div data-testid="user-section">
        <div>
          <Button
            id="user-logout-button"
            mode={BUTTON_MODE.WHITE}
            onClick={async () => {
              try {
                await revokeTokens();
              } finally {
                await removeUser();
                window.location.assign(
                  process.env.REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_URI ?? ""
                );
              }
            }}
            customClass="Sidebar__escSection--button"
          >
            <Icon
              iconClass={ICON_CLASS.BOX_ARROW_RIGHT}
              customClass="Sidebar__escSection--icon"
            />
            {t("shared.header.menu.items.esc")}
          </Button>
        </div>
        <div className="Sidebar__divider"></div>
        <div className="p-3 ">
          <div className="d-flex flex-row align-items-center">
            <Icon
              iconClass={ICON_CLASS.USER_CIRCLE}
              customClass="Sidebar__userIcon"
            />

            <div className="px-2">
              <Text
                type={TEXT_ENUM.TITLE_H5}
                customClass="Sidebar__name"
                id="user-fiscalCode"
              >
                <Trans
                  i18nKey="shared.header.userInfo.names"
                  values={{ name, lastname }}
                />
              </Text>
              {/* TODO replace with real value */}
              <Text
                type={TEXT_ENUM.PARAGRAPH}
                customClass="Sidebar__lastAccess"
                id="user-lastAccess"
              >
                {
                  //TODO: For mocking purposes - To be replaced with the concrete last log-in the user has made
                  t("sideBar.lastLogin", {
                    val: lastLogin,
                    formatParams: {
                      val: {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      },
                    },
                  })
                }
              </Text>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <span>v{packageJson.version}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SideBar);
