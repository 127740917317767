import { DeliveryRegime } from "../Glossary/Glossary.model";

export interface KPIDomain<T = KPIValue> {
  id: string;
  description: string;
  values: T[];
  zone?: string;
}
export interface KPIValue {
  numerator: number;
  denominator: number;
  value: number | string;
  referenceDate: Date;
  difference?: number; // value - target
  target?: number;
  weight?: number;
  numeratorSsn?: number;
  numeratorExtraSsn?: number;
  denominatorSsn?: number;
  denominatorExtraSsn?: number;
  valueSsn?: number | string;
  valueExtraSsn?: number | string;
  /* transmissionDate?: Date;
  elaborationDate?: Date; */
}
export interface KPIValueBeforeConversion {
  numerator: number;
  denominator: number;
  value: number;
  referenceDate: string;
  difference?: number; // value - target
  target?: number;
  weight?: number;
  numeratorSsn?: number;
  numeratorExtraSsn?: number;
  denominatorSsn?: number;
  denominatorExtraSsn?: number;
  valueSsn?: number;
  valueExtraSsn?: number;
}
export interface CompaniesKPI<T = KPIValue> extends KPIDomain<T> {
  type: string;
  structures?: T[];
  referenceDateDisplayName?: string;
  numeratorLastUpdateDisplayName?: string;
  denominatorLastUpdateDisplayName?: string;
}
export interface RegionalKPI<T = KPIValue> extends KPIDomain<T> {
  zone?: string;
  sampling: string;
  companies?: CompaniesKPI<T>[];
  referenceDateDisplayName?: string;
  numeratorLastUpdateDisplayName?: string;
  denominatorLastUpdateDisplayName?: string;
}
export interface NationalKPI<T = KPIValue> {
  id: string;
  regions: RegionalKPI<T>[];
  referenceDate: Date;
  lastUpdateDisplayName: string;
  lastDailyReferenceDate?: string;
  values?: T[];
}
export interface IndicatorsForCard extends NationalKPI {
  numeratorDescription: string;
  denominatorDescription: string;
  description: string;
  tooltip: string;
  category: string;
  tags: string[];
  zone?: string;
  deliveryRegime?: DeliveryRegime;
  denominatorLastUpdateDisplayName?: string;
  numeratorLastUpdateDisplayName?: string;
  referenceDateDisplayName?: string;
}
export enum Period {
  LastMonth = "1",
  LastThreeMonths = "4",
  LastSixMonths = "5",
  LastYear = "6",
  _2Q = "2Q",
  All = "7",
}
export enum Granularity {
  Monthly = "MONTHLY",
  Daily = "DAILY",
  Quarterly = "QUARTERLY",
}
