export function linearMap(
  x: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
): number {
  const proportion = (x - inMin) / (inMax - inMin);
  const mappedValue = outMin + proportion * (outMax - outMin);
  return mappedValue;
}
