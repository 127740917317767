import { BaseScatterplotProps } from "./BaseScatterplot.models";
import ReactEcharts from "echarts-for-react";
import { useMemo } from "react";
import { EChartsOption } from "echarts";
import { useTranslation } from "react-i18next";

const BaseScatterplot: React.FC<BaseScatterplotProps> = ({
  min,
  max,
  interval,
  options,
  style,
}) => {
  const { t } = useTranslation();
  const baseOptions = useMemo<EChartsOption>(
    () => ({
      dataZoom: [
        {
          type: "slider",
          labelFormatter: (value) => t("shared.percent", { val: value }),
          top: 100,
          height: 20,
          zoomLock: true,
          zoomOnMouseWheel: false,
        },
      ],
      yAxis: {
        show: false,
      },
      xAxis: {
        axisLabel: {
          formatter: (value: number) => t("shared.percent", { val: value }),
          hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
        max: max / 100,
        min: min / 100,
        interval: interval / 100,
      },
      ...options,
    }),
    [interval, max, min, options, t]
  );

  return (
    <div data-testid="echarts-scatterplot">
      <ReactEcharts
        option={baseOptions}
        style={style}
        opts={{ renderer: "svg" }}
      />
    </div>
  );
};

export default BaseScatterplot;
