import React from "react";
import { useTranslation } from "react-i18next";
import { EXTERNAL_LINKS } from "@utils/Links";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Icon from "../Icon/Icon";
import LogoGovBianco from "./icons/LogoGovBianco";
import "./Header.scss";
import { Link } from "react-router-dom";
import { TEXT_ENUM } from "../Text/models/Text.models";
import Text from "../Text/Text";
import { HeaderProps } from "./model";

const Header = ({ isDrawerVisible = true }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <header aria-label="Informazioni e strumenti utente">
      <div className="header-top-dark d-none d-md-block">
        <div className="container-mcw container-fluid py-2">
          <Link to={EXTERNAL_LINKS.HEALTH_MINISTRY} className="link-white">
            {t("shared.header.link")}
          </Link>
        </div>
      </div>
      <div className="header-dark">
        <div className="container-mcw container-fluid py-3 py-md-4">
          <div className="row align-items-center m-auto">
            {isDrawerVisible ? (
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ex-offcanvas"
                  aria-controls="ex-offcanvas"
                  data-testid="hamburger-menu"
                >
                  <Icon
                    iconClass={ICON_CLASS.HAMBURGER_MENU}
                    customClass="Header__hamburgerIcon"
                    data-testid="link-icon"
                  />
                </button>
              </div>
            ) : undefined}
            <div className="col-auto">
              <LogoGovBianco />
            </div>
            <div className="col">
              <Text type={TEXT_ENUM.TITLE_H1} id="header">
                {t("shared.header.title")}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
