import { Size } from "@hooks/useWindowSize";
import i18n from "src/i18n";

export const WINDOW_WIDTH_THRESHOLD = 768;
export const calculateIsMobile = (size: Size) => {
  return size.width! < WINDOW_WIDTH_THRESHOLD;
};
export const regionsNumber = 22;
export const REGION_SCATTERPLOT_SYMBOL_MAX_SIZE = 40;
export const REGION_SCATTERPLOT_SYMBOL_MIN_SIZE = 20;
export const REGION_SCATTERPLOT_INTERVAL = 20;
export const NATION_SCATTERPLOT_SYMBOL_SIZE = 30;
export const NATION_SCATTERPLOT_INTERVAL = 5;
export const CHECKED_FIELD = "checked";
export const allRouteTabs = [
  i18n.t("shared.nation"),
  i18n.t("shared.region", i18n.t("shared.asl")),
  i18n.t("shared.structure"),
];

export const ROWS_DEFAULT_LENGTH = 3;

export const FIRST_ACCESS_KEY = "firstAccess";

export const DEFAULT_PERIOD = "1";
export const DEFAULT_GRANULARITY = "MONTHLY";
export const MOBILE_WIDTH = 768;
export const HEIGHT_FACTOR = 350 * 100;
export const DEFAULT_HEIGHT = 10;
export const BODY_CARD_WIDTH_WRAP = 325;
export const WINDOW_CARD_BODY_WRAP = 1440;

export const NA_VALUE = "N/A";
export const OUT_OF_SCALE_VALUE = "Fuori scala";
export const NATIONAL_VALUE_LABEL = "Valore nazionale";
export const REGIONAL_VALUE_LABEL = "Valore regione/PA";

export const ALL_REGIONS_ID = "111";
