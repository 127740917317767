import CollapseSection from "@containers/Indicators/components/CollapseSection/CollapseSection";
import { Col, Row } from "react-bootstrap";

const EnterpriseHeader: React.FC = () => {
  return (
    <Row data-testid="enterprise-header">
      <Col>
        <CollapseSection disabled>{[]}</CollapseSection>
      </Col>
    </Row>
  );
};
export default EnterpriseHeader;
