import Button from "@components/UI/Button/Button";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import Icon from "@components/UI/Icon/Icon";
import { SortableTable } from "@components/sortableTable/SortableTable";
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ROWS_DEFAULT_LENGTH } from "@utils/constants";
import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { hasNoValue, isClickable } from "@utils/tableColumns/tablesColumns";
import { TFuncKey } from "i18next";
import { FC, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DetailTableInCardProps } from "./model/detailTableInCard";
import { isValuePercent } from "@utils/function/isValuePercent";

const DetailTableInCard: FC<DetailTableInCardProps> = ({
  dataTable,
  headerColumn,
  columns,
  accessorKey,
  isGoalDetail,
}) => {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);

  const [canShowMore, setCanShowMore] = useState(true);

  const detailColumnTable: ColumnDef<(typeof dataTable)[number]>[] = useMemo(
    () => [
      {
        header: headerColumn,
        accessorKey: FILTERS_KEY.DESCRIPTION,
        cell: (props) => {
          const action = props.row.original.navigate;
          const description = props.row.original.description;

          if (!action) {
            return description;
          }

          return isClickable(description) ? (
            <Button
              mode={BUTTON_MODE.LINK}
              onClick={action}
              customClass="text-start"
            >
              {description}
            </Button>
          ) : (
            <>{description}</>
          );
        },
      },
      ...columns.map<ColumnDef<(typeof dataTable)[number]>>(
        (columnLabel, index) => ({
          header:
            isValuePercent(accessorKey) && isGoalDetail
              ? t("indicators.tables.columnLabel", {
                  columnLabel,
                })
              : columnLabel,
          accessorKey: `values.${index}.${accessorKey}`,
          cell: (props) =>
            !hasNoValue(props.getValue() as string) ? (
              <Trans
                i18nKey={
                  // TODO: refactor these booleans
                  isValuePercent(accessorKey)
                    ? "shared.percent"
                    : "shared.number"
                }
                values={{ val: props.getValue() }}
              />
            ) : (
              props.getValue()
            ),
        })
      ),
    ],
    [accessorKey, columns, headerColumn, isGoalDetail, t]
  );

  const columnsWithNumbers = useMemo(
    () => detailColumnTable.slice(1).map(({ header }) => header),
    [detailColumnTable]
  );

  const table = useReactTable({
    columns: detailColumnTable,
    data: dataTable,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });
  return (
    <div data-testid="detail-table-in-card">
      <SortableTable
        rowLength={canShowMore ? ROWS_DEFAULT_LENGTH : dataTable.length}
        table={table}
        columnsWithNumbers={columnsWithNumbers as TFuncKey[]}
      />
      {dataTable.length > 1 && (
        <Button
          mode={BUTTON_MODE.LINK}
          onClick={() => setCanShowMore(!canShowMore)}
        >
          <Icon
            iconClass={canShowMore ? ICON_CLASS.PLUS : ICON_CLASS.DASH}
            customClass="RegionalTable__icon"
          />
          {canShowMore
            ? t("indicators.nationTable.showMore")
            : t("indicators.nationTable.showLess")}
        </Button>
      )}
    </div>
  );
};

export default DetailTableInCard;
