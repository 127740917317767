export const sortAlphaDown = <T extends unknown>(
  items: T[],
  field: keyof T & string
) => {
  const sortedData = items.sort((p1: T, p2: T) => {
    return p1[field] < p2[field] ? 1 : p1[field] > p2[field] ? -1 : 0;
  });
  return sortedData;
};

export const sortAlphaUp = <T extends unknown>(
  items: T[],
  field: keyof T & string
) => {
  const sortedData = items.sort((p1: T, p2: T) => {
    return p2[field] < p1[field] ? 1 : p2[field] > p1[field] ? -1 : 0;
  });
  return sortedData;
};

export const getMean = (items: number[]): number => {
  if (items.length === 0) {
    return 0;
  }
  const sum = items.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  });
  const mean = sum / items.length;
  return mean;
};
