import { SortDirection, flexRender } from "@tanstack/react-table";
import { t, TFuncKey } from "i18next";
import Icon from "@components/UI/Icon/Icon";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { LeafTFuncKey } from "src/i18n";
import { SortableTableProps } from "./sortableTable.model";

function getSortIcon(
  state: false | SortDirection,
  isNumber: boolean
): ICON_CLASS {
  if (!state || state === "desc") {
    return isNumber
      ? ICON_CLASS.SORT_NUM_DOWN_ALT
      : ICON_CLASS.SORT_ALPHA_DOWN_ALT;
  }
  return isNumber ? ICON_CLASS.SORT_NUM_DOWN : ICON_CLASS.SORT_ALPHA_DOWN;
}

export const SortableTable = ({
  table,
  columnsWithNumbers = [],
  rowLength,
}: SortableTableProps) => {
  return (
    <div
      data-testid="table-responsive"
      className="table-responsive table-adaptive-sm"
    >
      <table className="table row-divided m-0">
        <thead className="visually-sm-down-hidden">
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} scope="col">
                      <div
                        {...{
                          role: header.column.getCanSort() ? "button" : "none",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <Text type={TEXT_ENUM.TITLE_H6}>
                          {flexRender(
                            t(header.column.columnDef.header as LeafTFuncKey),
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <Icon
                              customClass="text-primary mx-2"
                              iconClass={getSortIcon(
                                header.column.getIsSorted(),
                                columnsWithNumbers.includes(
                                  header.column.columnDef.header as TFuncKey
                                )
                              )}
                            />
                          )}
                        </Text>
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, rowLength)
            .map((row) => (
              <tr data-testid="table-responsive-row" key={row.id}>
                {row.getVisibleCells().map((cell, index) => {
                  if (index === 0) {
                    return (
                      <th
                        data-testid="table-responsive-cell"
                        key={cell.id}
                        scope="row"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </th>
                    );
                  }
                  let headerTKey = table.getHeaderGroups()[0].headers[index]
                    .column.columnDef.header as TFuncKey;

                  return (
                    <td key={cell.id} data-th={t(headerTKey)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
