import { ReactNode } from "react";

export enum CONTROLLERS_MODE {
  CONTROLS_ASIDE = "aside", // label between controllers
  CONTROLS_BOTTOM = "bottom", // label above controllers
}
export enum SLIDE_POSITION {
  SLIDE_TOP = "top", // slide on top and controllers on bottom
  SLIDE_BOTTOM = "bottom", // slide on bottom and controllers on top
}
export enum CONTROLLERS_POSITION {
  CONTROLLERS_RIGHT = "right", // controllers + label on right
  CONTROLLERS_LEFT = "left", // controllers + label on left
  CONTROLLERS_CENTER = "center", // controllers + label at center
}
export interface SlideItem {
  label: ReactNode; // ex Indicatore 1, Indicatore2
  id: string;
  children: ReactNode; // Content to show
}
export interface SliderProps {
  activeSlide: number; // slide index
  onChange: (index: number) => void; // set the index slide
  slides: SlideItem[] | SlideItem[][];
  slidePosition?: SLIDE_POSITION;
  controllersPosition?: CONTROLLERS_POSITION;
  controllersMode?: CONTROLLERS_MODE;
  showLabel?: boolean;
  showDots?: boolean;
  showControllers?: boolean;
  spinner?: ReactNode;
  customClass?: string;
}
