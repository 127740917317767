import { t } from "i18next";
import { IndicatorsForCard } from "@models/Indicators/indicators.model";
import {
  LEGEND_COLORS_ENUM,
  LegendItem,
} from "@components/Charts/Legend/GenericLegend/models/GenericLegend.models";
import { PowerIndicatorsDictionary } from "@utils/Indicators/power";
import {
  getRegimeInfo,
  REGIME_TYPE,
  RegimeInfo,
} from "@utils/function/getRegimeInfo";

export function getHistogramData(
  item: IndicatorsForCard,
  indicatorDictionary: PowerIndicatorsDictionary
) {
  const deliveryRegime = getRegimeInfo(item.deliveryRegime);

  const ssnSuffix: string = t("indicators.power.indicatorsLegend.suffixes.SSN");
  const extraSsnSuffix: string = t(
    "indicators.power.indicatorsLegend.suffixes.extraSSN"
  );

  const _ssnNumeratorDescription = t(
    "indicators.power.indicatorsLegend.legendWithSuffix",
    {
      legend: item.numeratorDescription,
      suffix: ssnSuffix,
    }
  );
  const _ssnDenominatorDescription = t(
    "indicators.power.indicatorsLegend.legendWithSuffix",
    {
      legend: item.denominatorDescription,
      suffix: ssnSuffix,
    }
  );

  const extraSSNnumeratorDescription =
    deliveryRegime.numerator === REGIME_TYPE.SSN_EXTRASSN
      ? t("indicators.power.indicatorsLegend.legendWithSuffix", {
          legend: t(indicatorDictionary.numeratorLegendDescription),
          suffix: extraSsnSuffix,
        })
      : undefined;
  const extraSSNdenominatorDescription =
    deliveryRegime.denominator === REGIME_TYPE.SSN_EXTRASSN
      ? t("indicators.power.indicatorsLegend.legendWithSuffix", {
          legend: t(indicatorDictionary.denominatorLegendDescription),
          suffix: extraSsnSuffix,
        })
      : undefined;

  const histogramLegends = getHistogramLegends(
    deliveryRegime,
    extraSSNnumeratorDescription,
    extraSSNdenominatorDescription,
    item
  );

  return {
    _ssnNumeratorDescription,
    _ssnDenominatorDescription,
    extraSSNnumeratorDescription,
    extraSSNdenominatorDescription,
    histogramLegends,
  };
}

export function getHistogramLegends(
  deliveryRegime: RegimeInfo,
  extraSSNnumeratorDescription: string | undefined,
  extraSSNdenominatorDescription: string | undefined,
  indicator: IndicatorsForCard
) {
  let legendItems: LegendItem[] = [];

  if (
    deliveryRegime.numerator === REGIME_TYPE.SSN_EXTRASSN &&
    indicator.values &&
    indicator.values[0].numeratorExtraSsn
  )
    legendItems.push({
      color: LEGEND_COLORS_ENUM.BLUE_EXTRA_SSN,
      description: extraSSNnumeratorDescription ?? "",
    });

  if (
    deliveryRegime.denominator === REGIME_TYPE.SSN_EXTRASSN &&
    indicator.values &&
    indicator.values[0].denominatorExtraSsn
  )
    legendItems.push({
      color: LEGEND_COLORS_ENUM.GREY_EXTRA_SSN,
      description: extraSSNdenominatorDescription ?? "",
    });

  return legendItems;
}
