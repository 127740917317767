import React from "react";
import { GenericModalProps } from "./models/GenericModal.models";
import ReactDOM from "react-dom";
import { Button, Modal } from "react-bootstrap";

// from montalcini
const GenericModal: React.FC<GenericModalProps> = ({
  show,
  title,
  children,
  onClose,
  leftButton,
  rightButton,
  size,
  closeLabel,
  id,
  closeButton = true,
  closeVariant = "outline-primary",
}) => {
  const portalElement = document.getElementById("overlays")!;
  return ReactDOM.createPortal(
    <Modal
      id={id}
      data-testid={id}
      show={show}
      onHide={onClose}
      size={size}
      centered
      backdrop="static"
    >
      {title && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title as="h1" className="h2">
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="justify-content-start">
        {leftButton}
        <Button variant={closeVariant} onClick={onClose}>
          {closeLabel ?? "Annulla"}
        </Button>
        {rightButton}
      </Modal.Footer>
    </Modal>,
    portalElement
  );
};

export default GenericModal;
