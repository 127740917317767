import { useSelector } from "react-redux";
import { Region, Company } from "@models/Companies/companies.model";
import { selectCompany } from "src/redux/selectors/selections.selectors";
import { useCallback } from "react";
import { selectionChanged } from "src/redux/slices/selections.slice";
import { useAppDispatch } from "src/store";

const useSelectedEnterprise = (selectedRegion: Region) => {
  const dispatch = useAppDispatch();
  const setSelectedCompany = useCallback(
    (newRegion: Region, newCompany: Company) => {
      dispatch(
        selectionChanged({
          region: newRegion?.id,
          company: newCompany?.code,
        })
      );
    },
    [dispatch]
  );

  let selectedCompanyId = useSelector(selectCompany);

  if (
    selectedRegion &&
    selectedCompanyId &&
    selectedRegion.companies[selectedCompanyId]
  ) {
    setSelectedCompany(
      selectedRegion,
      selectedRegion.companies[selectedCompanyId]
    );
  } else {
    const companiesSorted = Object.values(selectedRegion.companies).sort(
      function (a, b) {
        var textA = a.description.toUpperCase();
        var textB = b.description.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
    );
    setSelectedCompany(selectedRegion, companiesSorted[0]);
    selectedCompanyId = companiesSorted[0]?.code;
  }

  return { selectedCompanyId, setSelectedCompany };
};

export default useSelectedEnterprise;
