export enum EXPORT_TYPE {
  KPI = "KPI",
  KPI_SSN = "KPI_SSN",
  KPI_EXTRA_SSN = "KPI_EXTRA_SSN",
  NUMERATOR = "NUMERATOR",
  NUMERATOR_SSN = "NUMERATOR_SSN",
  NUMERATOR_EXTRA_SSN = "NUMERATOR_EXTRA_SSN",
  DENOMINATOR = "DENOMINATOR",
  DENOMINATOR_SSN = "DENOMINATOR_SSN",
  DENOMINATOR_EXTRA_SSN = "DENOMINATOR_EXTRA_SSN",
  TARGET = "TARGET",
}
