import Footer from "@components/UI/Footer/Footer";
import Header from "@components/UI/Header/Header";
import ScrollToTop from "@components/UI/ScrollToTop/ScrollToTop";
import Sidebar from "@components/UI/Sidebar/Sidebar";
import { useWindowSize } from "@hooks/useWindowSize";
import { calculateIsMobile } from "@utils/constants";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { setIsMobile } from "src/redux/slices/isMobile.slice";
import { useAppDispatch } from "src/store";
import { ExpiringTokenModal } from "@components/ExpiringTokenModal/ExpiringTokenModal";
import Breadcrumb from "@components/UI/Breadcrumb/Breadcrumb";
import Callout from "@components/UI/Callout/Callout";
import NavBar from "@components/UI/NavBar/NavBar";
import { SideBarProps } from "@components/UI/Sidebar/Sidebar.model";
import Spinner from "@components/UI/Spinner/Spinner";
import {
  commonSideMenuSectionItems,
  fullSideMenuSectionItems,
} from "@utils/constants/sideMenuItem";
import { CALLOUT_TYPE } from "@utils/enum/callout.enum";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import {
  removeTabsFromRoute,
  setAllDetailRoutes,
} from "@utils/function/detailRoute";
import { getLocation } from "@utils/function/getLocation";
import { navBarItemsHandler } from "@utils/function/navBarItemsHandler";
import { t } from "i18next";
import { Alert, Col, Row } from "react-bootstrap";
import "./Layout.scss";
import { EvolvingDataAlert } from "@components/EvolvingDataAlert/EvolvingDataAlert";
import { INDICATORS_NESTED_ROUTES } from "@utils/Routes/routes.enum";
import { useLayoutViewModel } from "./LayoutViewModel";

const LayoutSideBar = (props: SideBarProps) => {
  return (
    <div
      className="offcanvas offcanvas-start"
      id="ex-offcanvas"
      tabIndex={-1}
      aria-labelledby="ex-offcanvas-label"
      aria-hidden="true"
      data-testid="layoutSideBar"
    >
      <Sidebar {...props} />
    </div>
  );
};

const Layout = () => {
  const {
    glossaries,
    userInfo,
    isFetchingGlossaries,
    isEvolvingDataAlertVisible,
    IsTokenIsExpiringAlertVisible,
    tokenExp,
    showFullSidebarMenu,
    closeExpiringAlert,
    closeEvolvingDataAlert,
    logout,
  } = useLayoutViewModel();
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const { pathname } = useLocation();
  const location = getLocation(pathname);

  const [tabs, setTabs] = useState(navBarItemsHandler(pathname));

  const [showLogoutAlert, setShowLogoutAlert] = useState(true);

  const isHomepage = pathname.includes(INDICATORS_NESTED_ROUTES.GOAL);

  useEffect(() => {
    const mobile = calculateIsMobile(size);
    dispatch(setIsMobile(mobile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    if (glossaries && userInfo) {
      glossaries.forEach((value) => setAllDetailRoutes(value));
    }
  }, [glossaries, userInfo]);

  useEffect(() => {
    const newTabs = navBarItemsHandler(pathname).map((tab) => ({
      ...tab,
      isActive: tab.path === removeTabsFromRoute(pathname),
    }));
    setTabs(newTabs);
  }, [pathname]);

  const onClickHandler = (index: number) => {
    const newTabs = tabs.map((tab, tabIndex) => ({
      ...tab,
      isActive: index === tabIndex,
    }));
    setTabs(newTabs);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const sideMenuSectionItems = showFullSidebarMenu
    ? fullSideMenuSectionItems
    : commonSideMenuSectionItems;

  return (
    <>
      {userInfo.CF && !isFetchingGlossaries && (
        <LayoutSideBar
          sections={sideMenuSectionItems}
          activeRoute={location}
          user={userInfo}
        />
      )}

      <a href="#main" className="visually-hidden-focusable m-2">
        Vai al contenuto principale
      </a>
      <Header isDrawerVisible={!!userInfo.CF && !isFetchingGlossaries} />
      {userInfo.CF && !isFetchingGlossaries && (
        <div className="container-mcw container-fluid Layout__NavbarContainer">
          <NavBar tabs={tabs} onClickTab={onClickHandler} />
        </div>
      )}
      <main id="main" className="container-mcw container-fluid">
        {showLogoutAlert && isHomepage && (
          <Row className="mt-4">
            <Col xs="12" md="10" lg="8" xl="6" xxl="5">
              <Alert
                className="mb-0 ps-3"
                dismissible
                onClose={() => setShowLogoutAlert(false)}
              >
                {t("shared.tokenIsExpiring", { exp: tokenExp })}
              </Alert>
            </Col>
          </Row>
        )}

        {userInfo.CF && !isFetchingGlossaries && (
          <div className="my-4">
            <Breadcrumb />
          </div>
        )}
        {(isFetchingGlossaries || !userInfo) && <Spinner />}
        {!userInfo.CF && !isFetchingGlossaries ? (
          <Callout
            title={t("error.authorization.title")}
            description={t("error.authorization.description")}
            icon={ICON_CLASS.INFO}
            type={CALLOUT_TYPE.PRIMARY}
          />
        ) : (
          !isFetchingGlossaries && <Outlet />
        )}
      </main>
      <ScrollToTop onClick={scrollToTop} data-testid="scroll-to-top" />
      {userInfo.CF && !isFetchingGlossaries && <Footer />}
      {IsTokenIsExpiringAlertVisible && (
        <ExpiringTokenModal
          show={true}
          onHide={closeExpiringAlert}
          onConfirm={logout}
        />
      )}
      {isEvolvingDataAlertVisible && (
        <EvolvingDataAlert show={true} onHide={closeEvolvingDataAlert} />
      )}
    </>
  );
};

export default Layout;
