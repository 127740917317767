import React, { useId } from "react";
import { Tooltip } from "react-tooltip";
import { TooltipProps } from "./models/Tooltip.models";
import "./ReactTooltip.scss";

const ReactTooltip: React.FC<TooltipProps> = (props) => {
  const id = useId();
  return (
    <>
      <span
        data-testid="tooltip-id"
        data-tooltip-id={id}
        data-tooltip-html={props.text}
        data-tooltip-place="top"
      >
        {props.children}
      </span>
      <Tooltip
        id={props.text ? id : ""}
        className="ReactTooltip__container"
      ></Tooltip>
    </>
  );
};
export default React.memo(ReactTooltip);
