import { ReactNode } from "react";
import { Region } from "@models/Companies/companies.model";

export type LineChartProps = {
  regions?: Region[] | undefined;
  values: { [value: string]: (number | string)[] };
  xAxis: string[];
  target?: number;
  scale?: "linear" | "log";
  type?: "percent" | "number";
  hasPercentageY?: boolean;
  hasTarget?: boolean;
  isNational?: boolean;
  noDataRectangle?: ReactNode;
};

export enum LineColor {
  Normal = "#000000",
  Region = "#0066CC",
  BelowTarget = "#D9364F",
  AboveTarget = "#009562B3",
}

export enum LineScale {
  Linear = "linear",
  Log = "log",
}
