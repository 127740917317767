import { IndicatorsForCard } from "@models/Indicators/indicators.model";
import { PowerIndicatorsDictionary } from "@utils/Indicators/power";
import { useCallback } from "react";

export const usePowerResumeCard = (
  allPowerIndicators: IndicatorsForCard[],
  dictionaryItems: PowerIndicatorsDictionary[]
) => {
  /**
   *
   * @param cardType  the type of chart in card
   * @returns get indicators with matching by card type (barchart, piechart, no chart) in dictionary
   */
  const getIndicatorsFilteredByCardType: (
    cardType: string
  ) => IndicatorsForCard[] = useCallback(
    (cardType) => {
      return allPowerIndicators.filter((powerIndicator: IndicatorsForCard) =>
        dictionaryItems.some(
          (dictionaryItem) =>
            dictionaryItem.indicatorId === powerIndicator.id &&
            dictionaryItem.cardType === cardType
        )
      );
    },
    [allPowerIndicators, dictionaryItems]
  );
  /**
   *
   * @param indicator single power indicator
   * @returns array with info to know if we want pie chart or bar chart also usefull for get legents info
   */
  const getDictionaryInfoForPowerIndicators = useCallback(
    (indicator: IndicatorsForCard) => {
      return (
        dictionaryItems.find(
          (dictionaryItem) => dictionaryItem.indicatorId === indicator.id
        ) ?? dictionaryItems[0]
      );
    },
    [dictionaryItems]
  );

  //TODO create getDictionaryInfoForPowerIndicators for PowerIndicatorSsnDictionary

  return {
    getIndicatorsFilteredByCardType,
    getDictionaryInfoForPowerIndicators,
  };
};
