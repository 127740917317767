import { DeliveryRegime } from "@models/Glossary/Glossary.model";
export enum REGIME_TYPE {
  SIMPLE = "SIMPLE",
  SSN = "SSN",
  SSN_EXTRASSN = "SSN_AND_EXTRA_SSN",
}

export interface RegimeInfo {
  numerator: REGIME_TYPE;
  denominator: REGIME_TYPE;
}

const ssnAndExtraLabel = "SSN ed Extra SSN";
const ssnLabel = "Solo SSN";

export const getRegimeInfo = (
  deliveryRegime: DeliveryRegime | undefined
): RegimeInfo => {
  const regimeInfo: RegimeInfo = {
    numerator: REGIME_TYPE.SIMPLE,
    denominator: REGIME_TYPE.SIMPLE,
  };

  if (deliveryRegime) {
    if (deliveryRegime.denominator[0].description === ssnAndExtraLabel)
      regimeInfo.denominator = REGIME_TYPE.SSN_EXTRASSN;
    if (deliveryRegime.denominator[0].description === ssnLabel)
      regimeInfo.denominator = REGIME_TYPE.SSN;
    if (deliveryRegime.numerator[0].description === ssnAndExtraLabel)
      regimeInfo.numerator = REGIME_TYPE.SSN_EXTRASSN;
    if (deliveryRegime.numerator[0].description === ssnLabel)
      regimeInfo.numerator = REGIME_TYPE.SSN;
  }
  return regimeInfo;
};
