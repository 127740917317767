import Button from "@components/UI/Button/Button";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import Icon from "@components/UI/Icon/Icon";
import Text from "@components/UI/Text/Text";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import {
  GENERIC_ENTITY_COLUMN_LABEL,
  TableColumnNationalProps,
  TableColumnRegionalProps,
} from "@containers/Indicators/models/tables.model";
import { SortCell } from "@models/SortCell/SortCell.models";
import { createColumnHelper } from "@tanstack/react-table";
import {
  NA_VALUE,
  NATIONAL_VALUE_LABEL,
  OUT_OF_SCALE_VALUE,
} from "@utils/constants";
import { TFuncKey, t } from "i18next";
import { Trans } from "react-i18next";
import i18n from "src/i18n";
import { ICON_CLASS } from "../enum/icon.enum";
import "./tablesColumns.scss";
import { TableDataType } from "@containers/Indicators/Detail/TabDetailContainer/TabDetailContainer.model";

export const hasNoValue = (value: string) => {
  return value === NA_VALUE || value === OUT_OF_SCALE_VALUE;
};
export const isClickable = (value: string) => {
  return !value.includes("Non Riconosciuto");
};

const entityWithTargetColumnHelper =
  createColumnHelper<TableColumnNationalProps>();

export function sortTableByValueFn<T>(
  table_row_one: TableDataType<T>,
  table_row_two: TableDataType<T>
) {
  const value_one = table_row_one.orderingValue;
  const value_two = table_row_two.orderingValue;

  return typeof value_one === "string"
    ? +1
    : typeof value_two === "string"
    ? -1
    : value_one > value_two
    ? -1
    : value_one < value_two
    ? +1
    : 0;
}

export const getEntityWithTargetTableColumns = (isBandVisible: boolean) => [
  entityWithTargetColumnHelper.accessor("regionDescription", {
    header: "indicators.nationTable.headers.region",
    cell: (props) => {
      const { action, description } = props.getValue() ?? {};

      if (!action) {
        return description;
      }

      return (
        <Button
          mode={BUTTON_MODE.LINK}
          onClick={action}
          customClass="text-start"
        >
          {description}
          <span className="visually-hidden">
            {i18n.t("indicators.nationTable.hiddenHeader", {
              selectedValue: description,
            })}
          </span>
        </Button>
      );
    },
    sortingFn: (a, b) => {
      return (a.getValue("regionDescription") as SortCell).description >
        (b.getValue("regionDescription") as SortCell).description
        ? 1
        : (a.getValue("regionDescription") as SortCell).description <
          (b.getValue("regionDescription") as SortCell).description
        ? -1
        : 0;
    },
  }),
  ...(isBandVisible
    ? [
        entityWithTargetColumnHelper.accessor("band", {
          header: "indicators.nationTable.headers.band",
        }),
      ]
    : []),
  entityWithTargetColumnHelper.accessor("target", {
    header: "indicators.nationTable.headers.target",
    cell: (props) => {
      if (
        props.row.original.regionDescription?.description ===
        NATIONAL_VALUE_LABEL
      ) {
        return null;
      }
      return (
        <Text type={TEXT_ENUM.SPAN} customClass="fw-bold">
          <Trans
            i18nKey="shared.percent"
            values={{
              val: props.getValue(),
            }}
          />
        </Text>
      );
    },
  }),
  entityWithTargetColumnHelper.accessor("value", {
    header: "indicators.nationTable.headers.value",
    cell: (props) => {
      const target: number = props.row.getValue("target");
      const value: string = props.row.getValue("value");
      const isNationalValue: boolean =
        props.row.original.regionDescription?.description ===
        NATIONAL_VALUE_LABEL;
      const color =
        target && Number(value) >= target ? "text-success" : "text-danger";

      return (
        <>
          {!hasNoValue(value) ? (
            <Text
              type={TEXT_ENUM.SPAN}
              customClass={`fw-bold ${
                isNationalValue ? "text-default" : color
              }`}
            >
              <Trans
                i18nKey="shared.percent"
                values={{
                  val:
                    value === OUT_OF_SCALE_VALUE
                      ? t("shared.charts.percentage.outOfScale")
                      : value,
                }}
              />
            </Text>
          ) : (
            <Text type={TEXT_ENUM.SPAN} customClass={`fw-bold`}>
              {value}
            </Text>
          )}
        </>
      );
    },
  }),
  entityWithTargetColumnHelper.accessor("goal", {
    header: "indicators.nationTable.headers.goal",
    cell: (props) => {
      if (
        props.row.original.regionDescription?.description ===
        NATIONAL_VALUE_LABEL
      ) {
        return null;
      }

      const target = props.row.getValue("target") as number;
      const value: string = props.row.getValue("value");
      const targetReached = Number(value) >= target;
      let iconClass: string;
      let customClass: string;
      let label: TFuncKey;

      if (hasNoValue(value)) {
        iconClass =
          value === OUT_OF_SCALE_VALUE
            ? ICON_CLASS.TRIANGLE_WARNING
            : ICON_CLASS.X_CIRCLE_FILL;
        customClass =
          value === OUT_OF_SCALE_VALUE
            ? "out-of-scale-target mx-2"
            : "no-target mx-2";
        label =
          value === OUT_OF_SCALE_VALUE
            ? "indicators.nationTable.targetInRevision"
            : "indicators.nationTable.targetNotApplicable";
      } else {
        iconClass = targetReached
          ? ICON_CLASS.CHECK_CIRCLE_FILL
          : ICON_CLASS.WARNING;
        customClass = targetReached
          ? "target-reached mx-2"
          : "text-danger mx-2";
        label = targetReached
          ? "indicators.nationTable.targetArchived"
          : "indicators.nationTable.targetNotArchived";
      }
      return (
        <Text type={TEXT_ENUM.SPAN}>
          <Icon iconClass={iconClass} customClass={customClass} />
          {t(label)}
        </Text>
      );
    },
    sortingFn: (a, b) =>
      (a.getValue("difference") as number) -
      (b.getValue("difference") as number),
  }),
  entityWithTargetColumnHelper.accessor("referenceDate", {
    header: "indicators.nationTable.headers.date",
    cell: (props) => (
      <Text type={TEXT_ENUM.SPAN}>
        <Trans
          i18nKey="indicators.tables.dateMonthly"
          values={{
            date: props.getValue(),
          }}
        />
      </Text>
    ),
  }),
];

const genericEntityColumnHelper =
  createColumnHelper<TableColumnRegionalProps>();

export const getGenericEntityTableColumns = (entityLabel: string) => {
  return [
    genericEntityColumnHelper.accessor("entityDescription", {
      header: entityLabel,
      cell: (props) => {
        const { action, description } = props.getValue();

        return isClickable(description) && action !== undefined ? (
          <Button
            mode={BUTTON_MODE.LINK}
            onClick={action}
            customClass="text-start"
          >
            {description}
            <span className="visually-hidden">
              {i18n.t("indicators.regionTable.hiddenHeader", {
                selectedValue: description,
              })}
            </span>
          </Button>
        ) : (
          <>
            {description}
            <span className="visually-hidden">
              {i18n.t("indicators.regionTable.hiddenHeader", {
                selectedValue: description,
              })}
            </span>
          </>
        );
      },
      sortingFn: (a, b) => {
        return (a.getValue("entityDescription") as SortCell).description >
          (b.getValue("entityDescription") as SortCell).description
          ? 1
          : (a.getValue("entityDescription") as SortCell).description <
            (b.getValue("entityDescription") as SortCell).description
          ? -1
          : 0;
      },
    }),
    genericEntityColumnHelper.accessor("indicatorValue", {
      header: GENERIC_ENTITY_COLUMN_LABEL.INDICATOR_VALUE,
      cell: (props) => (
        <Text type={TEXT_ENUM.SPAN} customClass="fw-bold">
          {!hasNoValue(props.getValue() as string) ? (
            <Trans
              i18nKey="shared.percent"
              values={{
                val: props.getValue(),
              }}
            />
          ) : (
            props.getValue()
          )}
        </Text>
      ),
    }),
    genericEntityColumnHelper.accessor("numerator", {
      header: GENERIC_ENTITY_COLUMN_LABEL.NUMERATOR,
      cell: (props) => (
        <Text type={TEXT_ENUM.SPAN}>
          <Trans i18nKey="shared.number" values={{ val: props.getValue() }} />
        </Text>
      ),
    }),
    genericEntityColumnHelper.accessor("denominator", {
      header: GENERIC_ENTITY_COLUMN_LABEL.DENOMINATOR,
      cell: (props) => (
        <Text type={TEXT_ENUM.SPAN}>
          <Trans i18nKey="shared.number" values={{ val: props.getValue() }} />
        </Text>
      ),
    }),
    genericEntityColumnHelper.accessor("referenceDate", {
      header: GENERIC_ENTITY_COLUMN_LABEL.REFERENCE_DATE,
      cell: (props) => (
        <Text type={TEXT_ENUM.SPAN}>
          <Trans
            i18nKey="indicators.tables.dateMonthly"
            values={{
              date: props.getValue(),
            }}
          />
        </Text>
      ),
    }),
  ];
};
