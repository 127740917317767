import { useTranslation } from "react-i18next";
import Search from "@components/UI/Search/Search";
import Icon from "@components/UI/Icon/Icon";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Button from "@components/UI/Button/Button";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import { CheckboxProps, CheckboxBlockProps } from "./Checkbox.models";
import "./Checkbox.scss";
import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import { useId } from "react";

function CheckboxBlock<T extends unknown>({
  items,
  condition,
  onCheck,
  keyField,
  labelField,
  region,
}: CheckboxBlockProps<T>) {
  const id = useId();
  return (
    <div className="Checkbox">
      {items
        .filter((item) => item.isChecked === condition && item !== region)
        .map((item) => {
          return (
            <div
              className="form-check"
              data-testid={`${item[keyField]}-checkbox`}
              key={`ex-checks-${item[labelField]}`}
            >
              <input
                type="checkbox"
                id={`ex-checks-${item[keyField]}-${id}`}
                name={`ex-checks-${item[labelField]}-${id}`}
                value={item[keyField] as string}
                className="form-check-input"
                onChange={() => {
                  onCheck(item);
                }}
                checked={item.isChecked}
                data-testid={`ex-checks-${item[keyField]}-${id}`}
              />
              <label
                htmlFor={`ex-checks-${item[keyField]}-${id}`}
                className="form-check-label"
              >
                {item[labelField] as string}
              </label>
            </div>
          );
        })}
    </div>
  );
}

export default function Checkbox<T extends unknown>({
  regions,
  title,
  items,
  isASCSorted,
  searchedValue,
  searchBoxTitle,
  searchPlaceholder,
  allChecked,
  onCheck,
  onCheckAll,
  onSearch,
  onReset,
  onSort,
  keyField,
  labelField,
  fieldsetAriaLabel,
  selectAllAriaLabel,
  sortingAriaLabel,
  resetAriaLabel,
}: CheckboxProps<T>) {
  const { t } = useTranslation();

  const nation = items?.find(
    (item) => item[keyField] === FILTERS_KEY.NATIONAL_VALUE_ID
  );

  const region = items?.find((item) =>
    regions?.some(
      (region) => (item[labelField] as string) === region.description
    )
  );

  const nationCheckBox = nation && (
    <div className="form-check" data-testid={`nation-checkbox`}>
      <input
        type="checkbox"
        id={`ex-checks-nation`}
        name={`ex-checks-nation`}
        value=""
        className="form-check-input"
        onChange={() => onCheck(nation)}
        checked={nation.isChecked}
      />
      <label htmlFor={`ex-checks-nation`} className="form-check-label">
        {t("shared.checkbox.nation")}
      </label>
    </div>
  );

  const regionCheckBox = region && (
    <div className="form-check" data-testid={`region-checkbox`}>
      <input
        type="checkbox"
        id={`ex-checks-region`}
        name={`ex-checks-region`}
        value=""
        className="form-check-input"
        onChange={() => onCheck(region)}
        checked={region.isChecked}
      />
      <label htmlFor={`ex-checks-region`} className="form-check-label">
        {t("shared.checkbox.region")}
      </label>
    </div>
  );

  return (
    <fieldset
      className="fieldset-bordered d-flex flex-column gap-2 Checkbox__search"
      tabIndex={1}
      aria-label={fieldsetAriaLabel}
    >
      <legend data-testid={`${title}-checkbox`}>{title}</legend>
      <div>
        <Search
          title={searchBoxTitle}
          value={searchedValue}
          onType={onSearch}
          placeholder={searchPlaceholder}
          tabIndex={2}
          showLensButton={true}
        />
        <div className="Checkbox__sortBox">
          <Button
            id="sort-checkbox-button"
            mode={BUTTON_MODE.WHITE}
            onClick={onSort}
            customClass="CSVButton__button p-0"
            ariaLabel={sortingAriaLabel}
            tabIndex={3}
          >
            <Icon
              iconClass={
                isASCSorted
                  ? ICON_CLASS.SORT_ALPHA_DOWN
                  : ICON_CLASS.SORT_ALPHA_UP
              }
              customClass="Checkbox__icon"
            />
            {t("shared.checkbox.sort")}
          </Button>
        </div>
      </div>
      <div className="Checkbox Checkbox__wrapper" tabIndex={4}>
        <div className="Checkbox">
          <div className={`form-check`} data-testid={`all-checkbox`}>
            <input
              type="checkbox"
              id={`ex-checks-all`}
              data-testid={`ex-checks-all`}
              name={`ex-checks-all`}
              value=""
              className="form-check-input"
              onChange={() => onCheckAll(allChecked)}
              checked={allChecked}
              aria-label={selectAllAriaLabel}
            />
            <label htmlFor={`ex-checks-all`} className="form-check-label">
              {t("shared.checkbox.all")}
            </label>
          </div>
          {nationCheckBox}
          {regionCheckBox}
        </div>
        <CheckboxBlock
          items={items.filter(
            (item) => item[keyField] !== FILTERS_KEY.NATIONAL_VALUE_ID
          )}
          condition={true}
          keyField={keyField}
          region={region}
          labelField={labelField}
          onCheck={onCheck}
        />
        <CheckboxBlock
          items={items.filter(
            (item) => item[keyField] !== FILTERS_KEY.NATIONAL_VALUE_ID
          )}
          condition={false}
          keyField={keyField}
          region={region}
          labelField={labelField}
          onCheck={onCheck}
        />
      </div>
      <Button
        id="reset-checkbox-button"
        mode={BUTTON_MODE.WHITE}
        onClick={onReset}
        customClass="text-start CSVButton__button p-0"
        tabIndex={5}
        ariaLabel={resetAriaLabel}
      >
        {t("shared.checkbox.reset")}
      </Button>
    </fieldset>
  );
}
