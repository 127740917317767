import React from "react";
import { ROUTES } from "@utils/Routes/routes.enum";
import { LeafTFuncKey } from "src/i18n";
import GithubIcon from "../icons/GithubIcon";
import SlackIcon from "../icons/SlackIcon";

//TODO: add valid links

export interface LinkItem {
  key: LeafTFuncKey;
  hasIcon?: boolean;
  href: string;
  openInNewTab?: boolean;
}

export const links: LinkItem[] = [
  {
    key: "shared.footer.links.legaleNotes",
    href: ROUTES.LEGALNOTES,
    openInNewTab: true,
    hasIcon: true,
  },

  {
    key: "shared.footer.links.privacyInfo",
    href: ROUTES.PRIVACY,
    openInNewTab: true,
    hasIcon: true,
  },
  {
    key: "shared.footer.links.accessibilityDeclaration",
    href: "",
    hasIcon: true,
  },
];

export const menuLinks: LinkItem[] = [
  { key: "shared.header.menu.items.glossary", href: ROUTES.RESOURCES },
];

export interface SponsorLogoItem {
  src: string;
  width: number;
  height: number;
  alt: LeafTFuncKey;
}

export interface socialLogosItem {
  src: React.FC;
  label: LeafTFuncKey;
  href: string;
  id: string;
}

export const socialLogos: socialLogosItem[] = [
  {
    src: SlackIcon,
    label: "shared.footer.social.slack",
    href: "https://developersitalia.slack.com/archives/C03RDT88FSM",
    id: "slack-icon",
  },
  {
    src: GithubIcon,
    label: "shared.footer.social.github",
    href: "https://github.com/ministero-salute/it-fse-support/",
    id: "github-icon",
  },
];

//TODO replace width and height with the real one
export const sponsorLogos: SponsorLogoItem[] = [
  {
    src: require("@assets/img/footer/nextGenerationEU.png"),
    width: 217,
    height: 48,
    alt: "shared.footer.altLogo.nextGenerationEU",
  },
  {
    src: require("@assets/img/footer/italiaDomani.png"),
    width: 217,
    height: 42,
    alt: "shared.footer.altLogo.italiaDomani",
  },
  {
    src: require("@assets/img/footer/healt.png"),
    width: 217,
    height: 42,
    alt: "shared.footer.altLogo.healt",
  },
  {
    src: require("@assets/img/footer/digitalDepartment.png"),
    width: 217,
    height: 42,
    alt: "shared.footer.altLogo.digitalDepartment",
  },
];
