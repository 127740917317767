import { FIRST_ACCESS_KEY } from "@utils/constants";
import { UserManagerSettings } from "oidc-client-ts";
import { ENV_VARIABLES_ENUM } from "./envVariables.enum";

const urlParams = new URLSearchParams(window.location.search);
const firstAccess = urlParams.get(FIRST_ACCESS_KEY) !== "false";
const redirect_url = new URL(
  process.env.REACT_APP_OAUTH_REDIRECT_URI ?? window.location.origin
);

if (firstAccess) {
  redirect_url.searchParams.set(FIRST_ACCESS_KEY, "false");
}

export const clientConfigs: UserManagerSettings = {
  metadataUrl: process.env.REACT_APP_OAUTH_METADATA_URL,
  authority: process.env.REACT_APP_OAUTH_AUTHORITY as string,
  client_id: process.env.REACT_APP_OAUTH_CLIENTID as string,
  scope: process.env.REACT_APP_OAUTH_SCOPE as string,
  response_type: process.env.REACT_APP_OAUTH_RESPONSE_TYPE as string,
  redirect_uri: redirect_url.href,
  automaticSilentRenew: false,
  loadUserInfo: process.env.NODE_ENV === ENV_VARIABLES_ENUM.DEVELOPMENT,
  accessTokenExpiringNotificationTimeInSeconds: 5 * 60,
  extraQueryParams: firstAccess
    ? {
        service: process.env.REACT_APP_OAUTH_SERVICE as string,
      }
    : undefined,
};
