import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Slider.scss";
import {
  CONTROLLERS_MODE,
  CONTROLLERS_POSITION,
  SLIDE_POSITION,
  SlideItem,
  SliderProps,
} from "./models/Slider.models";

const Slider: React.FC<SliderProps> = ({
  showLabel,
  showDots,
  activeSlide,
  slides,
  controllersMode = CONTROLLERS_MODE.CONTROLS_ASIDE,
  onChange,
  slidePosition = SLIDE_POSITION.SLIDE_BOTTOM,
  controllersPosition = CONTROLLERS_POSITION.CONTROLLERS_CENTER,
  showControllers = true,
  spinner,
  customClass = "",
}) => {
  const { t } = useTranslation();

  const id =
    slidePosition === SLIDE_POSITION.SLIDE_BOTTOM ? "slider" : "slider-reverse";

  const shiftSlide = (direction: 1 | -1) => {
    const n = slides.length;
    const i = activeSlide + direction;
    onChange(((i % n) + n) % n);
  };

  const onNextSlide = () => shiftSlide(1);
  const onPrevSlide = () => shiftSlide(-1);

  const onClickDotHandler = (index: number) => {
    onChange(index);
  };

  const renderSlides = (slidesToRender: SlideItem[], isGrouped?: boolean) => {
    const sliderId = isGrouped ? "ex-slider" : "grouped-ex-slider";
    return (
      <>
        <div
          id={sliderId}
          data-testid={sliderId}
          className={`carousel carousel-dark carousel-indicators-bottom carousel-controls-${controllersMode} slide Slider__controllers--${controllersPosition}`}
          data-bs-interval="false"
        >
          <div className="carousel-inner" data-testid="slider-labels">
            {showLabel &&
              slidesToRender.map((slide, index) => (
                <div
                  id={`${sliderId}-${index}`}
                  key={`${sliderId}-${index}`}
                  className={
                    activeSlide === index
                      ? "carousel-item active Slider__labels"
                      : "carousel-item Slider__labels"
                  }
                >
                  {slide.label}
                </div>
              ))}
          </div>
          {showControllers && (
            <>
              <button
                className="carousel-control-prev"
                type="button"
                data-testid="prev-slide"
                data-bs-target={`#${sliderId}`}
                data-bs-slide="prev"
                onClick={onPrevSlide}
              >
                <span className="carousel-control-prev-icon"></span>

                <span className="visually-hidden">
                  {t("shared.slider.prev")}
                </span>
              </button>
              {showDots && (
                <div
                  className="carousel-indicators carousel-inner Slider__dots"
                  data-testid="slider-dots"
                >
                  {Array.isArray(slides[activeSlide])
                    ? slides.map((group, groupIndex) => {
                        return (
                          <button
                            key={`slider-button-${groupIndex}`}
                            id={`slider-button-${groupIndex}`}
                            data-testid={`slider-button-${groupIndex}`}
                            type="button"
                            data-bs-target={`#${sliderId}`}
                            data-bs-slide-to={groupIndex}
                            aria-label={""}
                            aria-controls={`${sliderId}-${groupIndex}`}
                            className={
                              activeSlide === groupIndex
                                ? "bg-primary active"
                                : "bg-primary"
                            }
                            onClick={() => onClickDotHandler(groupIndex)}
                          ></button>
                        );
                      })
                    : slidesToRender.map((slide, index) => {
                        return (
                          <button
                            key={`slider-button-${index}`}
                            id={`slider-button-${index}`}
                            data-testid={`slider-button-${index}`}
                            type="button"
                            data-bs-target={`#${sliderId}`}
                            data-bs-slide-to={index}
                            aria-label={slide.label as string}
                            aria-controls={`${sliderId}-${index}`}
                            className={
                              activeSlide === index
                                ? "bg-primary active"
                                : "bg-primary"
                            }
                            onClick={() => onClickDotHandler(index)}
                          ></button>
                        );
                      })}
                </div>
              )}
              <button
                className="carousel-control-next"
                type="button"
                data-testid="next-slide"
                data-bs-target={`#${sliderId}`}
                data-bs-slide="next"
                onClick={onNextSlide}
              >
                <span className="carousel-control-next-icon"></span>
                <span className="visually-hidden Slider__arrowButtons">
                  {t("shared.slider.next")}
                </span>
              </button>
            </>
          )}
        </div>
        {spinner ? (
          spinner
        ) : Array.isArray(slides[activeSlide]) ? (
          <Row className="mb-4">
            {slidesToRender.map((slide: SlideItem) => slide.children)}
          </Row>
        ) : (
          slidesToRender[activeSlide] && (
            <div data-testid={`slide-${activeSlide}`} className="Slider__slide">
              {slidesToRender[activeSlide].children}
            </div>
          )
        )}
      </>
    );
  };
  return (
    <div id={id} data-testid={id} className={`Slider ${customClass}`}>
      {Array.isArray(slides[activeSlide])
        ? renderSlides(slides[activeSlide] as SlideItem[], true)
        : renderSlides(slides as SlideItem[])}
    </div>
  );
};
export default React.memo(Slider);
