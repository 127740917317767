export enum DOMAIN {
  NATION = "nation",
  REGION = "region",
  ENTERPRISE = "enterprise",
  STRUCTURE = "structure",
}

export enum NUMERATOR_DETAIL_SUMMARY {
  NATION = "indicators.detail.nation.numeratorSummary",
  REGION = "indicators.detail.region.numeratorSummary",
  ENTERPRISE = "indicators.detail.enterprise.numeratorSummary",
  NATION_ZONE_A = "indicators.detail.nation.numeratorSummaryZoneA",
  NATION_ZONE_B = "indicators.detail.nation.numeratorSummaryZoneB",
}
export enum VALIDATED_INDICATOR_DETAIL_SUMMARY {
  IS_GOAL = "indicators.detail.nation.validatedIndicatorSummary",
  NATION = "indicators.detail.nation.validatedPowerIndicatorSummary",
  REGION_GOAL = "indicators.detail.region.validatedIndicatorSummaryGoals",
  REGION_POWER = "indicators.detail.region.validatedIndicatorSummaryPowers",
  ENTERPRISE = "indicators.detail.enterprise.validatedIndicatorSummary",
  NATION_ZONE_A = "indicators.detail.nation.validateIndicatorSummaryZoneA",
  NATION_ZONE_B = "indicators.detail.nation.validateIndicatorSummaryZoneB",
}
export enum DENOMINATOR_DETAIL_SUMMARY {
  NATION = "indicators.detail.nation.denominatorSummary",
  REGION = "indicators.detail.region.denominatorSummary",
  ENTERPRISE = "indicators.detail.enterprise.denominatorSummary",
  NATION_ZONE_A = "indicators.detail.nation.denominatorSummaryZoneA",
  NATION_ZONE_B = "indicators.detail.nation.denominatorSummaryZoneB",
}
export enum NESTED_TAB_DETAIL_HEADER_COLUMN {
  REGION = "indicators.goals.tabs.region",
  ENTERPRISE = "indicators.goals.tabs.enterprise",
}
