import { FC } from "react";
import { CalloutProps } from "./Callout.model";
import Icon from "../Icon/Icon";
import "./Callout.scss";

const Callout: FC<CalloutProps> = ({ title, description, icon, type }) => {
  return (
    <div data-testid="callout" className={`Callout callout ${type}`}>
      <h3 className="Callout__title callout-title">
        {icon && (
          <Icon
            iconClass={icon}
            customClass="Callout__title--icon bi-lg me-3"
          />
        )}
        {title}
      </h3>
      <p>{description}</p>
    </div>
  );
};

export default Callout;
