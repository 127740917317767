import { useOutletContext } from "react-router-dom";
import { RegionsMap } from "@models/Companies/companies.model";
import { GlossariesMap } from "@models/Glossary/Glossary.model";
import { Preference } from "@models/Preferences/Preferences.models";

export function useGoalsOutletContext() {
  return useOutletContext<{
    glossaries: GlossariesMap;
    regions: RegionsMap;
    preferences: Preference[];
  }>();
}
