import "./wdyr"; // <--- first import
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import "./i18n";
import "./index.scss";
import userManager from "./services/Auth";

const providerConfigs: AuthProviderProps = {
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  userManager: userManager,
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider {...providerConfigs}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
