import {
  useDownloadCSVMutation,
  useGetKPIsQuery,
} from "@api/orchestrator/orchestrator.services";
import { selectedFilters } from "@containers/Indicators/components/IndicatorsSelect/models/IndicatorsSelect.models";
import {
  ENTERPRISE_TAB_ENUM,
  NATION_TAB_ENUM,
} from "@containers/Indicators/models/tabs.models";
import { Company, Region } from "@models/Companies/companies.model";
import { Domain } from "@models/Detail/detail.model";
import { Kpi, Kpis } from "@utils/KPI";
import { DEFAULT_GRANULARITY, DEFAULT_PERIOD } from "@utils/constants";
import { DOMAIN } from "@utils/enum/domain.enum";
import { useTranslation } from "react-i18next";
import { locationTab } from "src/redux/selectors/locationTab.selectors";
import { useAppSelector } from "src/store";
import { Tabs } from "../DetailViewModel";
import { KPI_CATEGORY_VALUE } from "@utils/enum/kpiCategory";
import { useCallback, useMemo } from "react";
import { EXPORT_TYPE } from "@utils/enum/exportType.enum";
import { hasReachedTarget } from "@utils/function/hasReachedTarget";
import { getIndicatorsObj } from "@utils/function/indicators";
import { useGoalsOutletContext } from "@hooks/useGoalsOutletContext";

export function useTabDetailViewModel(
  kpiCategory: KPI_CATEGORY_VALUE,
  currentKpi: Kpi,
  domain: Domain,
  isGoalDetail: boolean,
  selectedRegion: Region | undefined,
  selectedCompany: Company | undefined,
  setSelectedCompany: (Region: Region, company: Company) => void,
  setSelectedRegion: (region: Region | undefined) => void
) {
  const { glossaries } = useGoalsOutletContext();
  const localityTab = useAppSelector(locationTab) as Tabs; //FIXME
  const { t } = useTranslation();

  const getLastKpiApiResponse = useGetKPIsQuery({
    KPIs: Kpis[kpiCategory], //TODO
    period: DEFAULT_PERIOD,
    sampling: DEFAULT_GRANULARITY,
    region: localityTab !== NATION_TAB_ENUM.ID ? selectedRegion?.id : undefined,
    company:
      localityTab === ENTERPRISE_TAB_ENUM.ID
        ? selectedCompany?.code
        : undefined,
  });

  const lastKpi = useMemo(() => {
    if (getLastKpiApiResponse.data === undefined) {
      return undefined;
    }

    const _lastKpi = getLastKpiApiResponse.data?.kpis.find(
      (kpi) => kpi.id === currentKpi
    );

    if (_lastKpi === undefined) {
      return undefined;
    }

    return getIndicatorsObj(glossaries, [_lastKpi]).at(0);
  }, [currentKpi, getLastKpiApiResponse.data, glossaries]);

  const [getCSVData] = useDownloadCSVMutation({});

  const downloadCSV = useCallback(() => {
    getCSVData({
      glossaryId: lastKpi?.id ?? "",
      periodId: DEFAULT_PERIOD,
      sampling: DEFAULT_GRANULARITY,
      exportType: EXPORT_TYPE.KPI,
      regions:
        lastKpi?.regions.map((region) => ({
          regionId: region.id,
          companies: region.companies?.map((company) => ({
            companyId: company.id,
          })),
        })) ?? [],
    });
  }, [getCSVData, lastKpi?.id, lastKpi?.regions]);

  const numberOfRegionsOnTarget = useMemo(() => {
    return domain === DOMAIN.NATION
      ? lastKpi?.regions?.filter((region) =>
          hasReachedTarget(
            region.values[region.values.length - 1].value,
            region.values[region.values.length - 1].target
          )
        ).length
      : 0;
  }, [domain, lastKpi?.regions]);

  const isTargetShown = isGoalDetail;

  const pageTitle =
    domain === DOMAIN.NATION
      ? t("indicators.power.title.national")
      : domain === DOMAIN.REGION
      ? `${t("indicators.detail.synt")} ${selectedRegion?.description}`
      : t("indicators.detail.enterprise.summary");

  const setFilters = (region: Region, company?: Company) => {
    if (company) {
      setSelectedCompany(region, company);
    } else {
      setSelectedRegion(selectedRegion);
    }
  };

  const onChangeRegion = (data: selectedFilters) => {
    setSelectedRegion(data.region);
  };

  const onChangeEnterprise = (data: selectedFilters) =>
    setFilters(data.region, data.company);

  return {
    pageTitle,
    isTargetShown,
    onChangeRegion,
    onChangeEnterprise,
    downloadCSV,
    lastKpi,
    getLastKpiApiResponse,
    numberOfRegionsOnTarget,
  };
}
