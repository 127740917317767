import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Text from "../Text/Text";
import { TEXT_ENUM } from "../Text/models/Text.models";
import "./NavBar.scss";
import {
  NAVBAR_ITEM_MODE,
  NavBarItem,
  NavBarProps,
} from "./models/NavBar.model";

const NavBar: FC<NavBarProps> = ({
  tabs,
  variant = "dark",
  customClass,
  customItemClass,
  customDisabledClass,
  onClickTab,
}) => {
  const { t } = useTranslation();

  const navBarItems: JSX.Element = (
    <ul className="navbar-nav ">
      {tabs.map(
        (
          { id, label, path, isActive, isDisabled }: NavBarItem,
          index: number
        ) => {
          return (
            <li className="nav-item" key={id}>
              <Link
                to={path}
                className={`nav-item ${NAVBAR_ITEM_MODE.LINK} ${
                  isDisabled
                    ? `NavBar__itemDisabled ${customDisabledClass}`
                    : ""
                } ${
                  isActive ? NAVBAR_ITEM_MODE.LINK_IS_ACTIVE : customItemClass
                }`}
                onClick={onClickTab ? () => onClickTab(index) : () => {}}
              >
                {t(label)}
              </Link>
            </li>
          );
        }
      )}
    </ul>
  );

  return (
    <nav
      className={`navbar navbar-expand-md navbar-${variant} ${customClass}`}
      aria-label="Menu principale"
    >
      <Text type={TEXT_ENUM.TITLE_H1} customClass="visually-hidden">
        Menu principale
      </Text>
      <div className="NavBar__container">
        <div className="navbar-collapse collapse" id="navbar-menu">
          {navBarItems}
        </div>
      </div>
    </nav>
  );
};

export default React.memo(NavBar);
