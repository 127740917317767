import i18n from "src/i18n";
import { ROUTES, INDICATORS_NESTED_ROUTES } from "../Routes/routes.enum";

export const ROUTES_MAP = new Map<
  string,
  ROUTES | INDICATORS_NESTED_ROUTES | string
>([
  ["", ROUTES.ROOT],
  [i18n.t("shared.header.menu.items.home"), ROUTES.HOMEPAGE],
  [i18n.t("shared.header.menu.items.overwiew"), ROUTES.OVERVIEW],
  [i18n.t("shared.header.menu.items.report"), ROUTES.REPORT],
  [i18n.t("navBar.indicators.indicators"), ROUTES.INDICATORS],
  [i18n.t("shared.header.menu.items.glossary"), ROUTES.RESOURCES],
  [i18n.t("shared.header.menu.items.doc"), ROUTES.DOCUMENTATION],
  [i18n.t("shared.header.menu.items.dataQuality"), ROUTES.DATA_QUALITY],
  [i18n.t("navBar.indicators.power"), INDICATORS_NESTED_ROUTES.POWER],
  [i18n.t("navBar.indicators.goals"), INDICATORS_NESTED_ROUTES.GOAL],
  [
    i18n.t("navBar.indicators.application"),
    INDICATORS_NESTED_ROUTES.APPLICATION,
  ],
]);
