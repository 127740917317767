import { LeafTFuncKey } from "src/i18n";

export interface NavBarItem {
  id: string;
  label: LeafTFuncKey;
  path: string;
  isActive?: boolean;
  isDisabled?: boolean;
}

export enum NAVBAR_ITEM_MODE {
  LINK_IS_ACTIVE = "active",
  LINK_IS_DISABLED = "disabled",
  LINK = "nav-link ",
}

export interface NavBarProps {
  tabs: NavBarItem[];
  variant?: string;
  customClass?: string;
  customItemClass?: string;
  customDisabledClass?: string;
  onClickTab?: (index: number) => void;
}
