import { TableDataType } from "@containers/Indicators/Detail/TabDetailContainer/TabDetailContainer.model";
import { DataTable } from "@containers/Indicators/Detail/TabDetailContainer/components/models/RegionHeader.model";
import { IndicatorsForCard } from "@models/Indicators/indicators.model";
import { NATIONAL_VALUE_LABEL, REGIONAL_VALUE_LABEL } from "@utils/constants";
import { goToAslDetailHandler, goToRegionDetailHandler } from "./goToDetail";
import { sortTableByValueFn } from "@utils/tableColumns/tablesColumns";

export const getRegionalTableData = (
  item: IndicatorsForCard,
  goToAslDetail: (aslId: string, item?: IndicatorsForCard) => void
): TableDataType<DataTable>[] => {
  const regionalValues = item.regions?.at(0)?.values.at(0);
  const regionalData = {
    orderingValue: regionalValues?.value as number,
    referenceDate: regionalValues?.referenceDate as Date,
    entityDescription: {
      description: REGIONAL_VALUE_LABEL,
    },
    indicatorValue: regionalValues?.value as number,
    numerator: regionalValues?.numerator as number,
    denominator: regionalValues?.denominator as number,
  };
  const companiesData =
    item.regions[0].companies
      ?.map((company) => ({
        orderingValue: company.values[0].value,
        entityDescription: {
          description: company.description ?? company.id,
          action: () => goToAslDetailHandler(item, company.id, goToAslDetail),
        },
        indicatorValue: company.values[0].value,
        numerator: company.values[0].numerator,
        denominator: company.values[0].denominator,
        referenceDate: company.values[0].referenceDate,
      }))
      .sort(sortTableByValueFn) ?? [];
  return [regionalData, ...companiesData];
};

export const getNationalTableData = (
  item: IndicatorsForCard,
  goToRegionDetail: (item: IndicatorsForCard, regionId: string) => void
): TableDataType<DataTable>[] => {
  const nationValues = item.values?.at(0);
  const nationData = {
    orderingValue: nationValues?.value as number,
    referenceDate: nationValues?.referenceDate as Date,
    entityDescription: {
      description: NATIONAL_VALUE_LABEL,
    },
    indicatorValue: nationValues?.value as number,
    numerator: nationValues?.numerator as number,
    denominator: nationValues?.denominator as number,
  };
  const regionsData = item.regions
    .map((region) => ({
      orderingValue: region.values[0].value,
      entityDescription: {
        description: region.description ?? region.id,
        action: () =>
          goToRegionDetailHandler(item, region.id, goToRegionDetail),
      },
      indicatorValue: region.values[0].value,
      ...region.values[0],
    }))
    .sort(sortTableByValueFn);
  return [nationData, ...regionsData];
};
