import Histogram from "@components/Charts/Histogram/Histogram";
import GenericLegend from "@components/Charts/Legend/GenericLegend/GenericLegend";
import { LEGEND_COLORS_ENUM } from "@components/Charts/Legend/GenericLegend/models/GenericLegend.models";
import GenericScatterplot from "@components/Charts/ScatterplotSingleAxis/GenericScatterplot/GenericScatterplot";
import { SCATTERPLOT_COLORS } from "@components/Charts/models/charts.enum";
import Spinner from "@components/UI/Spinner/Spinner";
import CollapseSection from "@containers/Indicators/components/CollapseSection/CollapseSection";
import HistogramSelect from "@containers/Indicators/components/HistogramSelect/HistogramSelect";
import SummaryCard from "@containers/Indicators/components/SummaryCard/SummaryCard/SummaryCard";
import { TEXTBOX_ENUM } from "@containers/Indicators/components/SummaryCard/TextBox/models/TextBox.models";
import GenericTable from "@containers/Indicators/components/table/GenericTable";
import {
  GENERIC_ENTITY_COLUMN_LABEL,
  GENERIC_ENTITY_COLUMN_WITH_NUMBERS,
} from "@containers/Indicators/models/tables.model";
import {
  REGION_SCATTERPLOT_INTERVAL,
  REGION_SCATTERPLOT_SYMBOL_MAX_SIZE,
  REGION_SCATTERPLOT_SYMBOL_MIN_SIZE,
} from "@utils/constants";
import { getGenericEntityTableColumns } from "@utils/tableColumns/tablesColumns";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ENTERPRISE_TAB_ENUM } from "@containers/Indicators/models/tabs.models";
import { ScatterplotItem } from "@components/Charts/ScatterplotSingleAxis/GenericScatterplot/GenericScatterplot.models";
import { IndicatorsForCard } from "@models/Indicators/indicators.model";
import {
  PowerIndicatorsDictionary,
  powerIndicatorsDictionary,
} from "@utils/Indicators/power";
import { usePowerResumeCard } from "@hooks/ResumeCard/usePowerResumeCard";
import { RegionHeaderProps } from "./models/RegionHeader.model";
import { getHistogramData } from "@utils/Charts/Histogram/HistogramUtils";
import { getRegionalTableData } from "@utils/function/getTableData";

const RegionHeader: React.FC<RegionHeaderProps> = ({
  lastValueForSelectedKpi,
  selectedHistogramFilter,
  onChangeHistogramFilter = () => {},
  selectedRegion,
  setLocalityTab,
  setSelectedCompany,
  isFetching,
  isGoalDetail,
  CSVDownload,
}) => {
  const { t } = useTranslation();

  const region = lastValueForSelectedKpi?.regions.at(0);

  const goToAslDetail = (aslId: string) => {
    if (selectedRegion) {
      setLocalityTab(ENTERPRISE_TAB_ENUM.ID);
      setSelectedCompany(selectedRegion, selectedRegion.companies[aslId]);
    }
  };
  const seriesForRegionScatterPlot: ScatterplotItem[] =
    region?.companies?.map((current) => {
      const lastValue = current.values[current.values.length - 1];
      return {
        tooltipLabel: current.description ?? current.id, // to remove id
        value: lastValue.value,
        weight: lastValue.weight,
        target: region.values[0].value,
      };
    }) ?? [];

  const _selectedKpi: IndicatorsForCard = {
    numeratorDescription: lastValueForSelectedKpi?.numeratorDescription ?? "",
    denominatorDescription:
      lastValueForSelectedKpi?.denominatorDescription ?? "",
    description: lastValueForSelectedKpi?.description ?? "",
    tooltip: lastValueForSelectedKpi?.tooltip ?? "",
    category: lastValueForSelectedKpi?.category ?? "",
    tags: lastValueForSelectedKpi?.tags ?? [],
    id: lastValueForSelectedKpi?.id ?? "",
    regions: lastValueForSelectedKpi?.regions ?? [],
    referenceDate: lastValueForSelectedKpi?.referenceDate ?? new Date(),
    lastUpdateDisplayName: lastValueForSelectedKpi?.lastUpdateDisplayName ?? "",
    deliveryRegime: lastValueForSelectedKpi?.deliveryRegime,
  };

  const { getDictionaryInfoForPowerIndicators } = usePowerResumeCard(
    [_selectedKpi],
    powerIndicatorsDictionary
  );

  const infoForPowerIndicatorsArray: PowerIndicatorsDictionary =
    getDictionaryInfoForPowerIndicators(_selectedKpi);

  const {
    _ssnNumeratorDescription,
    _ssnDenominatorDescription,
    extraSSNnumeratorDescription,
    extraSSNdenominatorDescription,
    histogramLegends,
  } = getHistogramData(_selectedKpi, infoForPowerIndicatorsArray);

  const lastValueIndex =
    lastValueForSelectedKpi && region?.values ? region.values.length - 1 : 0;

  return (
    <Row data-testid="region-header">
      <Col>
        <CollapseSection>
          {isFetching && <Spinner />}
          {lastValueForSelectedKpi && !isFetching && (
            <SummaryCard
              id={lastValueForSelectedKpi.id}
              key={lastValueForSelectedKpi.id}
              table={
                <GenericTable
                  data={getRegionalTableData(
                    lastValueForSelectedKpi,
                    goToAslDetail
                  )}
                  columns={getGenericEntityTableColumns(
                    GENERIC_ENTITY_COLUMN_LABEL.ASL_DESCRIPTION
                  )}
                  columnsWithNumbers={GENERIC_ENTITY_COLUMN_WITH_NUMBERS}
                />
              }
              headline={{
                firstIndicator: lastValueForSelectedKpi.numeratorDescription,
                periodDataReferringTo:
                  lastValueForSelectedKpi.lastDailyReferenceDate,
                secondIndicator: lastValueForSelectedKpi.denominatorDescription,
                indicatorDescription: lastValueForSelectedKpi.tooltip,
                lastUpdateDate: lastValueForSelectedKpi.lastUpdateDisplayName,
                isWide: true,
              }}
              onDownloadCsvButton={CSVDownload}
              percentage={
                lastValueForSelectedKpi.regions[0].values[lastValueIndex]
                  .value ?? 0
              }
              numerator={
                lastValueForSelectedKpi.regions[0].values[lastValueIndex]
                  .numerator
              }
              denominator={
                lastValueForSelectedKpi.regions[0].values[lastValueIndex]
                  .denominator
              }
              difference={
                lastValueForSelectedKpi.regions[0].values[lastValueIndex]
                  .difference
              }
              referringRegion={lastValueForSelectedKpi.regions[0].description}
              target={
                isGoalDetail
                  ? lastValueForSelectedKpi.regions[0].values[lastValueIndex]
                      .target
                  : undefined
              }
              textBoxType={TEXTBOX_ENUM.PERCENTAGE_TEXT} // TO DO: add the check on mean description (see RegionalPowerContainer)
              chart={
                //isGoalDetail && // is this boolean useless now?
                lastValueForSelectedKpi.regions[0].companies && isGoalDetail ? (
                  <GenericScatterplot
                    items={seriesForRegionScatterPlot}
                    symbolSize={REGION_SCATTERPLOT_SYMBOL_MAX_SIZE}
                    interval={REGION_SCATTERPLOT_INTERVAL}
                    maxSymbolSize={REGION_SCATTERPLOT_SYMBOL_MAX_SIZE}
                    minSymbolSize={REGION_SCATTERPLOT_SYMBOL_MIN_SIZE}
                    basicColor={SCATTERPLOT_COLORS.BLUE}
                    markLine={
                      region && typeof region.values[0].value === "number"
                        ? region.values[0].value
                        : undefined
                    }
                  />
                ) : (
                  <Histogram
                    filter={selectedHistogramFilter}
                    companies={region?.companies ?? []}
                    numeratorSsnDescription={_ssnNumeratorDescription}
                    denominatorSsnDescription={_ssnDenominatorDescription}
                    extraSSNnumeratorDescription={extraSSNnumeratorDescription}
                    extraSSNdenominatorDescription={
                      extraSSNdenominatorDescription
                    }
                  />
                )
              }
              histogramSelect={
                !isGoalDetail && (
                  <HistogramSelect onFilter={onChangeHistogramFilter} />
                )
              }
              chartLegend={
                // isGoalDetail // is this boolean useless now?
                isGoalDetail ? (
                  <GenericLegend
                    items={[
                      {
                        color: LEGEND_COLORS_ENUM.LIGHT_BLUE,
                        description: t("indicators.goals.tabs.enterprise"),
                      },
                    ]}
                  />
                ) : (
                  <GenericLegend
                    items={[
                      {
                        color: LEGEND_COLORS_ENUM.BLUE_SSN,
                        description: _ssnNumeratorDescription,
                      },
                      {
                        color: LEGEND_COLORS_ENUM.GREY_SSN,
                        description: _ssnDenominatorDescription,
                      },
                      {
                        color: LEGEND_COLORS_ENUM.ACCENT,
                        description: t(
                          "indicators.power.indicatorsLegend.valuePercentage"
                        ),
                      },
                      ...histogramLegends,
                    ]}
                  />
                )
              }
            />
          )}
        </CollapseSection>
      </Col>
    </Row>
  );
};
export default RegionHeader;
