import { ROUTES } from "@utils/Routes/routes.enum";
import i18n from "src/i18n";

export function getLocation(location: string) {
  let locationRoute = location;
  const lastTab = location.substring(locationRoute.lastIndexOf("/"));
  switch (lastTab) {
    case i18n.t("shared.nation"):
    case i18n.t("shared.region"):
    case i18n.t("shared.asl"):
      locationRoute = locationRoute.substring(
        0,
        locationRoute.lastIndexOf("/")
      );
      break;
    default:
      break;
  }

  return locationRoute === ROUTES.OVERVIEW || locationRoute === ROUTES.ROOT
    ? ROUTES.ROOT
    : locationRoute;
}
