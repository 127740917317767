import { Region } from "@models/Companies/companies.model";
import { KPIDomain } from "@models/Indicators/indicators.model";
import { NA_VALUE } from "@utils/constants";
import { DOMAIN } from "@utils/enum/domain.enum";
import { FIELD_ENUM } from "@utils/enum/fields.enum";
import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import { REGION_OPTION } from "@utils/Options/option.models";
import { useMemo } from "react";
import useCheckbox from "./useCheckbox";

export function useCheckboxHandler(
  domain: string,
  items: (KPIDomain & {
    navigate?: (() => void) | undefined;
  } & {
    description: string;
    id: string;
  })[],
  isGoalDetail?: boolean,
  selectedRegion?: Region,
  zone?: string
) {
  const filteredItems = useMemo(() => {
    return !zone ? items : items.filter((region) => region.zone === zone);
  }, [items, zone]);

  const sortedItems = () => {
    const key = FIELD_ENUM.VALUE;
    return filteredItems.sort((item1, item2) => {
      const item2_lenght = item2.values.length - 1;
      const item1_lenght = item1.values.length - 1;
      const value_1 = item1.values[item1_lenght][key]!;
      const value_2 = item2.values[item2_lenght][key]!;
      return item1.id === FILTERS_KEY.NATIONAL_VALUE_ID
        ? -1
        : item2.id === FILTERS_KEY.NATIONAL_VALUE_ID
        ? 1
        : typeof value_1 === "string"
        ? 1
        : typeof value_2 === "string"
        ? -1
        : value_2 < value_1
        ? 1
        : value_2 > value_1
        ? -1
        : 0;
    });
  };
  const checkboxHandler = useCheckbox({
    items: sortedItems(),
    labelField: REGION_OPTION.LABEL,
    keyField: REGION_OPTION.VALUE,
    parentCheckedId: domain === DOMAIN.REGION ? selectedRegion?.id : undefined,
  });
  return checkboxHandler;
}
