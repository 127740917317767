import React from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import "./Histogram.scss";
import { Colors } from "@styles/theme";
import {
  HISTOGRAM_SELECT_ID,
  selectOptions,
} from "@containers/Indicators/components/HistogramSelect/models/HistogramSelect.models";
import { HistogramDataset, HistogramProps } from "./models/Histogram.models";

const Histogram: React.FC<HistogramProps> = ({
  companies,
  filter = selectOptions[0],
  numeratorSsnDescription,
  denominatorSsnDescription,
  extraSSNnumeratorDescription,
  extraSSNdenominatorDescription,
}) => {
  const { t } = useTranslation();
  const indicatorValue = t("indicators.regionTable.headers.indicatorValue");
  const aslDescription = t("indicators.regionTable.headers.aslDescription");
  const ssnNumColor = Colors.secondaryBlu;
  const extraSsnNumColor = Colors.lightBlue;
  const ssnDenColor = Colors.neutral1;
  const extraSsnDenColor = Colors.neutral2;
  const valueColor = Colors.accent;

  const fillDataset = () => {
    let dataset: HistogramDataset[] = companies.map((company) => {
      const lastValue = company.values[company.values.length - 1];
      const data: HistogramDataset = {
        [aslDescription]: company.description,
        [numeratorSsnDescription]: lastValue.numeratorSsn,
        [denominatorSsnDescription]: lastValue.denominatorSsn,
        [indicatorValue]:
          typeof lastValue.value === "number"
            ? `${parseInt(
                t("shared.percent", {
                  val: lastValue.value,
                })
              )}`
            : undefined,
      };
      if (extraSSNnumeratorDescription) {
        data[extraSSNnumeratorDescription] = lastValue.numeratorExtraSsn;
      }

      if (extraSSNdenominatorDescription) {
        data[extraSSNdenominatorDescription] = lastValue.denominatorExtraSsn;
      }

      return data;
    });
    return sortDataset(dataset);
  };

  const sortDataset = (dataset: HistogramDataset[]) => {
    return [...dataset].sort(sortFunction);
  };

  const sortFunction = (a: HistogramDataset, b: HistogramDataset) => {
    let key;
    switch (filter.id) {
      case HISTOGRAM_SELECT_ID.INDICATOR_FILTER: {
        key = indicatorValue;
        break;
      }
      case HISTOGRAM_SELECT_ID.ALPHABETICAL_FILTER: {
        key = aslDescription;
        break;
      }
      default: {
        key = indicatorValue; // TODO: key = aslCode (when available)
        break;
      }
    }

    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  const getMaxValueIndicator = () => {
    const itemsByValue = fillDataset()
      .filter((item) => item[indicatorValue] !== undefined)
      .map((item) => item[indicatorValue]);
    return Math.max(...itemsByValue);
  };
  const getMaxBar = () => {
    const itemsByNumerator = fillDataset().map((item) => {
      if (extraSSNnumeratorDescription)
        return (
          item[numeratorSsnDescription] + item[extraSSNnumeratorDescription]
        );
      return item[numeratorSsnDescription];
    });

    const itemsByDenominator = fillDataset().map((item) => {
      if (extraSSNdenominatorDescription)
        return (
          item[denominatorSsnDescription] + item[extraSSNdenominatorDescription]
        );
      return item[denominatorSsnDescription];
    });

    const numMax = Math.max(...itemsByNumerator);
    const denMax = Math.max(...itemsByDenominator);
    const valMax = getMaxValueIndicator();
    return Math.max(numMax, denMax, valMax);
  };

  const fillDimensions = () => {
    let dimensions = [
      aslDescription,
      numeratorSsnDescription,
      denominatorSsnDescription,
      indicatorValue,
    ];
    if (extraSSNnumeratorDescription)
      dimensions.push(extraSSNnumeratorDescription);
    if (extraSSNdenominatorDescription)
      dimensions.push(extraSSNdenominatorDescription);
    return dimensions;
  };

  const fillSeries = () => {
    let series = [
      {
        type: "bar",
        barWidth: "25%",
        barGap: "5%",
        color: ssnNumColor,
        stack: "Numerator",
      },

      {
        type: "bar",
        barWidth: "25%",
        barGap: "5%",
        color: ssnDenColor,
        stack: "Denominator",
      },

      {
        type: "bar",
        barWidth: "25%",
        barGap: "5%",
        color: valueColor,
        yAxisIndex: 1,
        label: {
          show: "true",
          position: "inside",
          formatter: (item: any) => `${item.data[indicatorValue]}%`,
          fontSize: 14,
          color: "black",
          fontWeight: "bold",
        },
      },
    ];
    if (extraSSNnumeratorDescription)
      series.push({
        type: "bar",
        barWidth: "25%",
        barGap: "5%",
        color: extraSsnNumColor,
        stack: "Numerator",
      });
    if (extraSSNdenominatorDescription)
      series.push({
        type: "bar",
        barWidth: "25%",
        barGap: "5%",
        color: extraSsnDenColor,
        stack: "Denominator",
      });
    return series;
  };

  /* istanbul ignore next */
  const renderTooltipContent = (item: any) => {
    return `

        <div class="d-flex align-items-center">
            <p class="LineChart__text LineChart__text--fraction">${
              item.name
            }</p>
        </div>    

        <div class="Histogram__box">

            <div class="me-1 mt-2 rounded-circle" style="width:5px; height:5px; background-color: ${ssnNumColor}"></div>
            <div class="Histogram__text">${numeratorSsnDescription}</div>
            <div class="Histogram__text">${t("shared.number", {
              val: item.data[numeratorSsnDescription],
            })}</div>

            <div class="me-1 mt-2 rounded-circle" style="width:5px; height:5px; background-color: ${ssnDenColor}"></div>
            <div class="Histogram__text">${denominatorSsnDescription}</div>
            <div class="Histogram__text">${t("shared.number", {
              val: item.data[denominatorSsnDescription],
            })}</div>
            ${
              extraSSNnumeratorDescription &&
              item.data[extraSSNnumeratorDescription] > 0
                ? `<div class="me-1 mt-2 rounded-circle" style="width:5px; height:5px; background-color: ${extraSsnNumColor}"></div>
                <div class="Histogram__text">${extraSSNnumeratorDescription}</div>
                <div class="Histogram__text">${t("shared.number", {
                  val: item.data[extraSSNnumeratorDescription],
                })}</div>`
                : ""
            }
            ${
              extraSSNdenominatorDescription &&
              item.data[extraSSNdenominatorDescription] > 0
                ? `<div class="me-1 mt-2 rounded-circle" style="width:5px; height:5px; background-color: ${extraSsnDenColor}"></div>
                  <div class="Histogram__text">${extraSSNdenominatorDescription}</div>
                  <div class="Histogram__text">${t("shared.number", {
                    val: item.data[extraSSNdenominatorDescription],
                  })}</div>`
                : ""
            }
            <div class="me-1 mt-2 rounded-circle" style="width:5px; height:5px; background-color: ${valueColor}"></div>
            <div class="Histogram__text">${indicatorValue}</div>
            <div class="Histogram__text">${
              item.data[indicatorValue]
                ? t("shared.number", {
                    val: item.data[indicatorValue],
                  }) + "%"
                : "N/A"
            }</div>

        </div>`;
  };

  const option = {
    legend: {
      show: false,
    },
    tooltip: {
      position: "top",
      confine: true,
      formatter: (item: any) => renderTooltipContent(item),
    },
    grid: {
      top: 20,
      bottom: 320,
    },
    dimensions: fillDimensions(),
    dataset: {
      source: fillDataset(),
    },
    xAxis: {
      type: "category",
      nameTextStyle: {
        rich: {
          style: {
            lineHeight: 226,
            padding: 100,
          },
        },
      },
      axisLabel: {
        show: true,
        formatter: (item: string) => {
          return item.replaceAll(" ", "\n");
        },
      },
    },
    yAxis: [
      {
        type: "value",
        show: true,
        min: 0,
        max: getMaxBar(),
        interval: getMaxBar() / 4,
      },
      {
        type: "value",
        show: true,
        min: 0,
        max: getMaxValueIndicator(),
        interval: getMaxValueIndicator() / 2,
        splitline: {
          show: false,
        },
        axisLabel: {
          formatter: "{value} %",
        },
      },
    ],
    dataZoom: [
      {
        type: "slider",
        top: 270,
        height: 20,
        start: 0,
        end: companies.length > 7 ? (6 * 100) / companies.length : 100,
        zoomLock: true,
        zoomOnMouseWheel: false,
      },
      {
        type: "inside",
        zoomLock: true,
        zoomOnMouseWheel: false,
      },
    ],
    series: fillSeries(),
  };
  return (
    <div data-testid="histogram" className="Histogram pb-4">
      <ReactEcharts
        echarts={echarts}
        option={option}
        opts={{ renderer: "svg", height: 520 }}
      />
    </div>
  );
};

export default React.memo(Histogram);
