import { LeafTFuncKey } from "src/i18n";

export interface PowerIndicatorsDictionary {
  indicatorId: string;
  cardType: string;
  numeratorLegendDescription: LeafTFuncKey;
  denominatorLegendDescription: LeafTFuncKey;
  meanDescription?: LeafTFuncKey;
}

export enum CARD_TYPE {
  BAR_CHART = "BAR_CHART",
  PIE_CHART = "PIE_CHART",
  NO_CHART = "NO_CHART",
}

export const powerIndicatorsDictionary: PowerIndicatorsDictionary[] = [
  {
    indicatorId: "IA1.1",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedLetters",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.hospitalizations",
  },
  {
    indicatorId: "IA1.2",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedMinutes",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.accessToPS",
  },
  {
    indicatorId: "IA1.3",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedReports",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.servicesProvided",
  },
  {
    indicatorId: "IA1.4",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedReports",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.servicesProvided",
  },
  {
    indicatorId: "IA1.5",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedReports",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.servicesProvided",
  },
  {
    indicatorId: "IA1.6",
    cardType: CARD_TYPE.BAR_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.indexedReports",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.servicesProvided",
  },
  {
    indicatorId: "IA1.7",
    cardType: CARD_TYPE.PIE_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.residentsWithIndexedCertificate",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.residentsWithoutIndexedCertificate",
  },
  {
    indicatorId: "IA1.8",
    cardType: CARD_TYPE.NO_CHART,
    numeratorLegendDescription: "filters.asl",
    denominatorLegendDescription: "filters.asl",
    meanDescription: "indicators.power.not-cumulativeLabel.doc/assisted",
  },
  {
    indicatorId: "IA2.1",
    cardType: CARD_TYPE.PIE_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.assistedWithIndexedPs",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.assistedWithoutIndexedPs",
  },
  {
    indicatorId: "IA2.2",
    cardType: CARD_TYPE.NO_CHART,
    numeratorLegendDescription: "filters.asl",
    denominatorLegendDescription: "filters.asl",
    meanDescription: "indicators.power.not-cumulativeLabel.patientSummary/mmg",
  },
  {
    indicatorId: "IU2.3",
    cardType: CARD_TYPE.PIE_CHART,
    numeratorLegendDescription:
      "indicators.power.indicatorsLegend.MMG-PLSThatSend",
    denominatorLegendDescription:
      "indicators.power.indicatorsLegend.MMG-PLSNotSend",
  },
];
