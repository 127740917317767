import React from "react";

const SlackIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.424"
      height="16.411"
      viewBox="0 0 17.424 17.411"
    >
      <g
        id="Group_28278"
        data-name="Group 28278"
        transform="translate(-1394.189 -313.076)"
      >
        <path
          id="Path_12624"
          data-name="Path 12624"
          d="M63.16,127.43a1.83,1.83,0,1,1-1.83-1.83h1.83Z"
          transform="translate(1334.703 196.652)"
          fill="#06c"
        />
        <path
          id="Path_12625"
          data-name="Path 12625"
          d="M92.5,127.43a1.83,1.83,0,1,1,3.66,0V132a1.83,1.83,0,1,1-3.66,0Z"
          transform="translate(1306.277 196.652)"
          fill="#06c"
        />
        <path
          id="Path_12626"
          data-name="Path 12626"
          d="M94.33,63.06a1.83,1.83,0,1,1,1.83-1.83v1.83Z"
          transform="translate(1306.277 253.676)"
          fill="#06c"
        />
        <path
          id="Path_12627"
          data-name="Path 12627"
          d="M65.818,92.5a1.83,1.83,0,1,1,0,3.66H61.23a1.83,1.83,0,0,1,0-3.66Z"
          transform="translate(1334.789 225.164)"
          fill="#06c"
        />
        <path
          id="Path_12628"
          data-name="Path 12628"
          d="M158.6,94.33a1.83,1.83,0,1,1,1.83,1.83H158.6Z"
          transform="translate(1249.34 225.164)"
          fill="#06c"
        />
        <path
          id="Path_12629"
          data-name="Path 12629"
          d="M129.26,65.818a1.83,1.83,0,0,1-3.66,0V61.23a1.83,1.83,0,0,1,3.66,0Z"
          transform="translate(1277.766 253.676)"
          fill="#06c"
        />
        <g
          id="Group_28247"
          data-name="Group 28247"
          transform="translate(1403.365 322.253)"
        >
          <path
            id="Path_12630"
            data-name="Path 12630"
            d="M127.43,158.6a1.83,1.83,0,1,1-1.83,1.83V158.6Z"
            transform="translate(-125.6 -154.026)"
            fill="#06c"
          />
          <path
            id="Path_12631"
            data-name="Path 12631"
            d="M127.43,129.26a1.83,1.83,0,0,1,0-3.66h4.588a1.83,1.83,0,1,1,0,3.66Z"
            transform="translate(-125.6 -125.6)"
            fill="#06c"
          />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(SlackIcon);
