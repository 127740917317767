export enum BUTTON_MODE {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  BLACK = "black",
  WHITE = "white",
  LIGHT = "light",
  ANALOGUE_1 = "analogue-1",
  ANALOGUE_2 = "analogue-2",
  NEUTRAL_1 = "neutral-1",
  NEUTRAL_2 = "neutral-1",
  OUTLINE_PRIMARY = "outline-primary",
  LINK = "link",
}

export interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  mode?: BUTTON_MODE;
  customClass?: string;
  id?: string;
  ariaLabel?: string;
  tabIndex?: number;
  dataBsToggle?: string;
  dataBsTarget?: string;
}
