import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orchestratorApi } from "@api/orchestrator/orchestrator.services";

type SelectionState = {
  region?: string | null;
  company?: string | null;
};

const initialState = {} as SelectionState;

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    selectionChanged: (state, action: PayloadAction<SelectionState>) => {
      state.region = action.payload.region;
      state.company = action.payload.company;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      orchestratorApi.endpoints.getProfile.matchFulfilled,
      (state, action) => {
        state.region = action.payload.roles
          .filter((role) => role.region !== "111")
          .at(0)?.region;
        state.company = action.payload.roles
          .filter((role) => role.region !== "111")
          .at(0)?.company;
      }
    );
  },
});

export const selectionReducer = selectionSlice.reducer;

export const { selectionChanged } = selectionSlice.actions;
