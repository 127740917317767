export enum SSN_LABEL {
  SSN_AND_EXTRASSN = "1",
  SSN = "2",
  EXTRA_SSN = "3",
}

export enum SSN_TYPE_NUMERATOR {
  SSN_AND_EXTRASSN = "numerator",
  SSN = "numeratorSsn",
  EXTRA_SSN = "numeratorExtraSsn",
}

export enum SSN_TYPE_DENOMINATOR {
  SSN_AND_EXTRASSN = "denominator",
  SSN = "denominatorSsn",
  EXTRA_SSN = "denominatorExtraSsn",
}

export enum SSN_TYPE_VALUE {
  SSN_AND_EXTRASSN = "value",
  SSN = "valueSsn",
  EXTRA_SSN = "valueExtraSsn",
  DIFFERENCE = "difference",
}

export enum SSN_KEYS {
  SSN = "Ssn",
  EXTRA_SSN = "ExtraSsn",
}

export enum SSN_TYPE_KEY {
  NUMERATOR = "numerator",
  DENOMINATOR = "denominator",
  VALIDATED_INDICATOR = "value",
}

export enum SSN_TYPE {
  VALUE = "VALUE",
  NUMERATOR = "NUMERATOR",
  DENOMINATOR = "DENOMINATOR",
}
