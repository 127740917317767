import { FC } from "react";
import { Button } from "react-bootstrap";
import GenericModal from "@components/UI/GenericModal/GenericModal";

import { Trans, useTranslation } from "react-i18next";

export const ExpiringTokenModal: FC<{
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}> = ({ show, onHide, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      show={show}
      size="lg"
      title={t("logoutModal.title")}
      onClose={onHide}
      leftButton={<Button onClick={onConfirm}>{t("logoutModal.cta")}</Button>}
      closeLabel={t("logoutModal.cancel")}
      closeButton={false}
      id={"expiring-modal"}
    >
      <Trans>logoutModal.description</Trans>
    </GenericModal>
  );
};
