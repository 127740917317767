import { ChangeEvent, useId } from "react";
import { TFuncKey, t } from "i18next";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { LabelPosition, SelectProps } from "./models/Select.models";
import "./Select.scss";
import Icon from "../Icon/Icon";

const Select = <T extends unknown>({
  label,
  customClass = "",
  labelPosition = LabelPosition.Top,
  placeholder,
  disabled,
  value,
  multiple,
  options,
  labelKey,
  valueKey,
  categoryKey,
  onChange,
  focusable = true,
  tooltip,
}: SelectProps<T>) => {
  const id = useId();

  const selectedValue = Array.isArray(value)
    ? value.map((item) => item[valueKey])
    : value?.[valueKey];

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(options.find((item) => item[valueKey] === event.target.value));
  };

  const withoutTitle =
    (categoryKey && options?.filter((option) => !option[categoryKey])) ??
    options;

  const withTitle = options?.filter(
    (option) => categoryKey && option[categoryKey]
  );

  const groupedOptions = withTitle.reduce((group, option) => {
    if (!categoryKey) {
      return group;
    }

    const category = option[categoryKey] as string;
    group[category] = group[category] ?? [];
    group[category].push(option);
    return group;
  }, {} as Record<string, T[]>);

  return (
    <div
      className={`${customClass} ${
        labelPosition === LabelPosition.Left &&
        "d-flex d-md-inline-flex align-items-center mb-3 mb-md-0"
      } `}
      data-testid="select-id"
    >
      {typeof label === "string" ? (
        <label
          htmlFor={id}
          className={`form-label ${
            labelPosition === LabelPosition.Left
              ? "Select__nowrap me-2"
              : "mb-2"
          }`}
        >
          {label}
        </label>
      ) : (
        <>{label}</>
      )}
      {tooltip && (
        <Icon
          customClass="mx-2 text-primary d-inline-block"
          iconClass={ICON_CLASS.INFO_CIRCLE}
          tooltip={tooltip}
        />
      )}
      <select
        id={id}
        value={(selectedValue as string) ?? ""} //FIXME
        disabled={disabled}
        multiple={multiple}
        onChange={onChangeHandler}
        className="form-select Select"
        data-testid="select"
        tabIndex={focusable ? 0 : -1}
      >
        {placeholder && value === undefined && (
          <option hidden disabled value="" data-testid="placeholder">
            {placeholder}
          </option>
        )}
        {withoutTitle.map((option) => (
          <option
            key={option[valueKey] as string} //FIXME
            value={option[valueKey] as string} //FIXME
            data-testid={label}
          >
            {
              option[labelKey] as string //FIXME
            }
          </option>
        ))}
        {Object.entries(groupedOptions).map(([title, _options]) => (
          <optgroup
            label={title && t(title as TFuncKey).toString()}
            key={title}
            data-testid="optgroup"
          >
            {_options.map((option) => (
              <option
                key={option[valueKey] as string} //FIXME
                value={option[valueKey] as string} //FIXME
                data-testid={label}
              >
                {
                  option[labelKey] as string //FIXME
                }
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
};

export default Select;
