import { t } from "i18next";
import {
  LegendItem,
  LEGEND_COLORS_ENUM,
} from "@components/Charts/Legend/GenericLegend/models/GenericLegend.models";

export const targetLegendItems: LegendItem[] = [
  {
    color: LEGEND_COLORS_ENUM.GREEN,
    description: t("shared.charts.legend.aboveTarget"),
  },
  {
    color: LEGEND_COLORS_ENUM.RED,
    description: t("shared.charts.legend.belowTarget"),
  },
];
