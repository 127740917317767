import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export const selectRegion = createSelector(
  (state: RootState) => state,
  (state) => state.selections.region
);

export const selectCompany = createSelector(
  (state: RootState) => state,
  (state) => state.selections.company
);
