import { NotCumulativeTextProps } from "@components/UI/NotCumulativeText/models/NotCumulativeText.models";
import { PercentageTextProps } from "@components/UI/PercentageText/models/PercentageText.model";
import { LeafTFuncKey } from "src/i18n";

export enum TEXTBOX_ENUM {
  PERCENTAGE_TEXT = "Percentage",
  TARGET_TEXT = "Target",
  NOT_CUMULATIVE = "Not cumulative",
}

export interface TextBoxProps
  extends Partial<PercentageTextProps>,
    Partial<Omit<NotCumulativeTextProps, "meanDescription">> {
  type: TEXTBOX_ENUM;
  onTargetDataLength?: number;
  totalTargetDataLength?: number;
  difference?: number;
  meanDescription?: LeafTFuncKey;
  band?: string;
}
