export enum Chart {
  LINE_CHART = "LINE_CHART",
}

export interface LableDomain<T extends string = string> {
  id: T;
  description: string;
}
export interface PeriodGranularity extends LableDomain<Chart> {
  values: LableDomain[];
}
export interface CalculationRule {
  denominator: string;
  numerator: string;
}
export interface Filters {
  description: string;
  granularity: string;
  period: string;
}
export interface DeliveryRegime {
  denominator: LableDomain<DeliveryRegimeValue>[];
  numerator: LableDomain<DeliveryRegimeValue>[];
  indicator: LableDomain<DeliveryRegimeValue>[];
}
export interface Glossary {
  id: string;
  numerator: string;
  denominator: string;
  description: string;
  tooltip: string;
  category: LableDomain;
  tags: LableDomain[];
  isZoned: boolean;
  periods: PeriodGranularity[];
  granularity: PeriodGranularity[];
  linked: string[];
  deliveryRegime?: DeliveryRegime;
  calculationRule: CalculationRule;
  updateFrequency: string;
  filters: Filters;
}

export const DeliveryRegimeType = {
  Ssn_Extra_Ssn: "1",
  Ssn: "2",
  Extra_Ssn: "3",
} as const;

export type DeliveryRegimeValue =
  (typeof DeliveryRegimeType)[keyof typeof DeliveryRegimeType];

export type GlossariesMap = Map<string, Glossary>;
