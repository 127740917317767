import { useTranslation } from "react-i18next";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Icon from "../Icon/Icon";
import "./Footer.scss";
import {
  links,
  menuLinks,
  socialLogos,
  sponsorLogos,
} from "./models/Footer.models";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="mt-auto Footer__container">
      <div className="">
        <div className="d-flex flex-wrap flex-lg-nowrap Footer__partners gap-5 my-4 px-3 px-md-5">
          {sponsorLogos.map((logo, index) => (
            <div
              className={`col-6 col-md-4 col-lg-2 d-flex justify-content-start align-center my-xl-2`}
              key={index}
            >
              <img {...logo} alt={t(logo.alt)} tabIndex={0} />
            </div>
          ))}
        </div>
        <hr className="Footer__line mb-0" />
        <div className="d-md-flex justify-content-md-between justify-content-lg-between Footer__social my-4 px-3 px-md-5">
          <div className="mt-2 mb-4 mb-md-2">
            {menuLinks.map((link, index) => {
              return (
                <a
                  key={`menuLink-${index}`}
                  href={link.href}
                  style={{ display: "inline-block", marginRight: "40px" }}
                >
                  {t(link.key)}
                </a>
              );
            })}
          </div>
          <div
            className={`pt-xs-2 pt-0 d-sm-flex align-items-start Footer__socialLinks`}
          >
            {t("shared.footer.social.community")}
            {socialLogos.map((icon, index) => {
              const Icon = icon.src;
              return (
                <div
                  key={index}
                  className={`mt-3 mt-sm-0`}
                  data-testid={icon.id}
                >
                  {<Icon />}
                  <a
                    href={icon.href}
                    target="_blank"
                    className="Footer__socialLinks--textLink"
                    rel="noreferrer"
                  >
                    {t(icon.label)}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="Footer__background py-3 px-3 px-md-5">
        <ul className="list-inline d-inline-block me-3 mb-0">
          {links.map((link, index) => {
            return (
              <li
                key={index}
                className="list-inline-item Footer__infoLinks mb-4 mb-md-0"
              >
                <Link
                  to={link.href}
                  target="_blank"
                  className="text-white underline"
                >
                  {t(link.key)}
                </Link>
                {link.hasIcon && (
                  <Icon
                    customClass="mr2 text-white m-2"
                    iconClass={ICON_CLASS.BOX_ARROW_UP_RIGHT}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
