export enum ROUTES {
  ROOT = "/",
  HOMEPAGE = "/homepage",
  OVERVIEW = "/overview",
  INDICATORS = "/indicatori",
  REPORT = "/report",
  RESOURCES = "/risorse-utili",
  DOCUMENTATION = "/documentazione",
  DATA_QUALITY = "/dataQuality",
  PRIVACY = "/privacy",
  LEGALNOTES = "/noteLegali",
}

export enum INDICATORS_NESTED_ROUTES {
  ALL_INDICATORS = "/indicatori",
  POWER = "/indicatori/alimentazione",
  GOAL = "/indicatori/obiettivo",
  APPLICATION = "/indicatori/utilizzo",
}
