import i18n from "src/i18n";
import { Glossary } from "@models/Glossary/Glossary.model";
import { ROUTES_MAP } from "@utils/Maps/Routes.map";
import { INDICATORS_NESTED_ROUTES } from "@utils/Routes/routes.enum";

const setDetailRouteLink = (
  glossary: Glossary,
  startingRoute: INDICATORS_NESTED_ROUTES
) => {
  const categoryDescription = glossary.category.description;
  const glossaryId = glossary.id;
  switch (startingRoute) {
    case INDICATORS_NESTED_ROUTES.GOAL:
      return {
        GOAL: `${startingRoute}/${categoryDescription}-${glossaryId}`,
      };
    case INDICATORS_NESTED_ROUTES.APPLICATION:
      return {
        APPLICATION: `${startingRoute}/${categoryDescription}-${glossaryId}`,
      };
    case INDICATORS_NESTED_ROUTES.POWER:
      return {
        POWER: `${startingRoute}/${categoryDescription}-${glossaryId}`,
      };
    default:
      return {
        GOAL: `${startingRoute}/${categoryDescription}-${glossaryId}`,
      };
  }
};

const setDetailRouteLabel = (glossary: Glossary) => {
  return `${glossary.numerator}/${glossary.denominator}`;
};

export const setAllDetailRoutes = (glossariyItem: Glossary) => {
  if (
    glossariyItem.category.description === i18n.t("navBar.indicators.goals")
  ) {
    ROUTES_MAP.set(
      setDetailRouteLabel(glossariyItem),
      setDetailRouteLink(glossariyItem, INDICATORS_NESTED_ROUTES.GOAL).GOAL!
    );
  }
  if (
    glossariyItem.category.description ===
    i18n.t("navBar.indicators.application")
  ) {
    ROUTES_MAP.set(
      setDetailRouteLabel(glossariyItem),
      setDetailRouteLink(glossariyItem, INDICATORS_NESTED_ROUTES.APPLICATION)
        .APPLICATION!
    );
  }
  if (
    glossariyItem.category.description === i18n.t("navBar.indicators.power")
  ) {
    ROUTES_MAP.set(
      setDetailRouteLabel(glossariyItem),
      setDetailRouteLink(glossariyItem, INDICATORS_NESTED_ROUTES.POWER).POWER!
    );
  }
};

export const removeKpiAndCategoryFromDetailRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const origin = tabs.slice(0, 2).join("/");
  return origin;
};

export const getKpiCategoryFromDetailRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const kpiTab = tabs[3];
  const category = kpiTab.substring(0, kpiTab.indexOf("-"));
  return category;
};

export const getKpiIDFromDetailRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const kpiTab = tabs[3];
  const kpiID = kpiTab.substring(kpiTab.indexOf("-") + 1);
  return kpiID;
};

export const getKpiTabFromDetailRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const kpiTab = tabs[3];
  return kpiTab;
};

export const isDetailRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const kpiTab = tabs[3];
  return kpiTab && kpiTab.includes("-");
};

export const removeTabsFromRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const origin = tabs.slice(0, 3).join("/");
  return origin;
};

export const getFirstFromRoute = (pathname: string) => {
  const tabs = pathname.split("/");
  const origin = tabs.slice(0, 2).join("/");
  return origin;
};
