import { NavBarItem } from "@components/UI/NavBar/models/NavBar.model";
import { ROUTES } from "@utils/Routes/routes.enum";
import {
  indicatorsNavBarMenu,
  glossaryNavBarMenu,
  homeNavBarMenu,
  reportNavBarMenu,
  documentationNavBarMenu,
} from "@utils/constants/navBarMenuItem";

export const navBarItemsHandler = (pathname: string): NavBarItem[] => {
  switch (pathname) {
    case ROUTES.INDICATORS:
      return indicatorsNavBarMenu;
    case ROUTES.RESOURCES:
      return glossaryNavBarMenu;
    case ROUTES.ROOT:
      return homeNavBarMenu;
    case ROUTES.OVERVIEW:
      return homeNavBarMenu;
    case ROUTES.REPORT:
      return reportNavBarMenu;
    case ROUTES.DOCUMENTATION:
      return documentationNavBarMenu;
    default:
      return indicatorsNavBarMenu;
  }
};
