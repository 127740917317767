import React from "react";
import Text from "../Text/Text";
import { TEXT_ENUM } from "../Text/models/Text.models";
import "./Collapse.scss";
import { CollapseProps } from "./models/Collapse.models";

const Collapse: React.FC<CollapseProps> = ({
  id,
  children,
  buttonText,
  title,
  subTitle,
  setAriaExpanded,
  disabled,
  isCollapsed,
}) => {
  const titleComponent = () => {
    if (title && subTitle)
      return (
        <div>
          <Text
            type={TEXT_ENUM.TITLE_H5}
            id="collapse-title"
            customClass="collapse-title font-weight-bold"
          >
            {title}
          </Text>
          <Text type={TEXT_ENUM.PARAGRAPH} id="collapse-sub-title">
            {subTitle}
          </Text>
        </div>
      );
    else if (title && !subTitle)
      return (
        <Text
          type={TEXT_ENUM.TITLE_H5}
          id="collapse-title"
          customClass="collapse-title font-weight-bold"
        >
          {title}
        </Text>
      );
    return;
  };

  return (
    <div className="row" data-testid="collapse-container">
      <div className="Collapse">
        {titleComponent()}
        <button
          type="button"
          className={`btn btn-link collapse-toggle ${
            isCollapsed ? "collapsed" : ""
          } Collapse__button`}
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${id}`}
          aria-expanded={!isCollapsed}
          aria-controls={`collapse-${id}`}
          onClick={setAriaExpanded}
          disabled={disabled}
        >
          {buttonText}
        </button>
      </div>
      <div
        className={`collapse ${isCollapsed ? "" : "show"}`}
        id={`collapse-${id}`}
      >
        <div className="py-4">{children}</div>
      </div>
    </div>
  );
};
export default React.memo(Collapse);
