import {
  IndicatorsForCard,
  RegionalKPI,
} from "@models/Indicators/indicators.model";
import { INDICATORS_TAB_ITEM_KEY } from "@utils/enum/tabBarItem";

export const goToDetailHandler = (
  item: IndicatorsForCard,
  tab: INDICATORS_TAB_ITEM_KEY,
  goToDetail: Function
) => {
  goToDetail(item, tab);
};
export const goToAslDetailHandler = (
  item: IndicatorsForCard,
  aslId: string,
  goToAslDetail: (aslId: string, item?: IndicatorsForCard) => void
) => {
  goToAslDetail(aslId, item);
};
export const goToRegionDetailHandler = (
  item: IndicatorsForCard,
  regionId: string,
  goToRegionDetail: Function
) => {
  goToRegionDetail(item, regionId);
};
