import { FC } from "react";
import GenericModal from "@components/UI/GenericModal/GenericModal";

import { Trans, useTranslation } from "react-i18next";

export const EvolvingDataAlert: FC<{
  show: boolean;
  onHide: () => void;
}> = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      show={show}
      size="lg"
      title={t("evolvingDataAlert.title")}
      onClose={onHide}
      closeLabel={t("evolvingDataAlert.cta")}
      closeButton={false}
      id={"evolving-data-modal"}
      closeVariant="primary"
    >
      <Trans>evolvingDataAlert.description</Trans>
    </GenericModal>
  );
};
