import { UserManager } from "oidc-client-ts";
import { clientConfigs } from "@services/Auth/oidcClientConfig";

class SogeiUserManager extends UserManager {
  async logout() {
    try {
      await this.revokeTokens();
    } finally {
      await this.removeUser();
      window.location.assign(
        process.env.REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_URI ?? ""
      );
    }
  }

  async logoutSilent(): Promise<void> {
    const iframePromise = new Promise<void>((resolve) => {
      const iframe = window.document.createElement("iframe");

      iframe.addEventListener(
        "load",
        (ev) => {
          const frame = ev.target as HTMLIFrameElement;
          frame.parentNode?.removeChild(frame);
          resolve();
        },
        true
      );

      iframe.src = process.env.REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_URI ?? "";

      iframe.style.visibility = "hidden";
      iframe.style.position = "fixed";
      iframe.style.left = "-1000px";
      iframe.style.top = "0";
      iframe.width = "0";
      iframe.height = "0";

      window.document.body.insertAdjacentElement("beforeend", iframe);
    });

    try {
      await iframePromise;
      await this.revokeTokens();
    } finally {
      await this.removeUser();
    }
  }
}

const instance = new SogeiUserManager(clientConfigs);

export const didLogout = { current: false };

instance.events.addUserUnloaded(() => {
  didLogout.current = true;
});

export default instance;
