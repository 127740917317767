import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userManager from "@services/Auth";

const initialState = {
  showTargetChangeAlert: true,
  showEvolvingDataAlert: true,
  showTokenIsExpiringAlert: false,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    targetChangeClosed: (state) => {
      state.showTargetChangeAlert = false;
    },
    evolvingDataAlertClosed: (state) => {
      state.showEvolvingDataAlert = false;
    },
    tokenIsExpiring: (state) => {
      state.showTokenIsExpiringAlert = true;
    },
    tokenIsExpiringClosed: (state) => {
      state.showTokenIsExpiringAlert = false;
    },

    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(logout.pending, (state) => {
      state.showTargetChangeAlert = true;
    });
  },
});

export const logout = createAsyncThunk("selection/logout", async () => {
  await userManager.logout();
});

export const logoutSilent = createAsyncThunk(
  "selection/logout",
  async (callback?: () => void) => {
    try {
      await userManager.logoutSilent();
    } finally {
      callback?.();
    }
  }
);

export const alertReducer = alertSlice.reducer;

export const {
  targetChangeClosed,
  tokenIsExpiring,
  evolvingDataAlertClosed,
  tokenIsExpiringClosed,
  reset,
} = alertSlice.actions;
