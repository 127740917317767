import React from "react";
import { useTranslation } from "react-i18next";
import { TEXT_ENUM } from "../Text/models/Text.models";
import Text from "../Text/Text";
import "./Spinner.scss";

const Spinner: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="Spinner" data-testid="spinner-id">
      <div className="spinner" role="status">
        <div className="spinner-border text-primary"></div>
        <Text type={TEXT_ENUM.SPAN} customClass="visually-hidden">
          {t("shared.spinner.label")}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(Spinner);
