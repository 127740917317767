import { EChartsOption } from "echarts";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getInterval } from "@utils/Charts/ScatterplotSingleAxis";
import { convertArrayToMap } from "@utils/function/getMapFromArray";
import { linearMap } from "@utils/function/linearMap";
import BaseScatterplot from "../BaseScatterplot/BaseScatterplot";
import { GenericScatterplotProps } from "./GenericScatterplot.models";
import "./GenericScatterplot.scss";
import { OUT_OF_SCALE_VALUE } from "@utils/constants";

const GenericScatterplot: React.FC<GenericScatterplotProps> = ({
  items: _items,
  symbolSize,
  maxSymbolSize,
  minSymbolSize,
  interval,
  basicColor,
  aboveTargetColor,
  belowTargetColor,
  markLine,
}) => {
  const { t } = useTranslation();
  const symbolOffset =
    maxSymbolSize && minSymbolSize
      ? [0, -((minSymbolSize! + maxSymbolSize!) / 2)]
      : [0, -symbolSize];
  const grid =
    maxSymbolSize && minSymbolSize
      ? (maxSymbolSize + minSymbolSize) / 2 + 5
      : symbolSize / 2 + 20;
  const additionalHeight = maxSymbolSize ?? symbolSize;
  const items = useMemo(
    () =>
      _items.map(({ difference, value, weight, target, ...rest }) => ({
        target: target ?? 0,
        difference: difference ?? 0,
        value: value,
        weigth: weight ?? 0,
        ...rest,
      })),
    [_items]
  );
  const valuesByTarget = items
    .filter((item) => typeof item.value === "number")
    .map((item) => {
      /* issue #819
      if (item.difference !== 0) {
        return item.difference;
      } */
      return { value: item.value, target: markLine ?? 0 };
    });
  const weights = useMemo(() => items.map((item) => item.weigth), [items]);
  const minWeight = useMemo(() => Math.min(...weights), [weights]);
  const maxWeight = useMemo(() => Math.max(...weights), [weights]);
  const isGoalCase: boolean = Boolean(aboveTargetColor && belowTargetColor);

  const [minInterval, maxInterval] = useMemo(() => {
    return getInterval(valuesByTarget, interval);
  }, [interval, valuesByTarget]);

  const [lowerTargetItems, upperTargetItems] = useMemo(() => {
    const lowerTargetItems: [number, number][] = [];
    const upperTargetItems: [number, number][] = [];
    // Second item is 0 because every item is at 0 y-axis
    items
      .filter((item) => typeof item.value === "number")
      .forEach(({ value, target }) => {
        if (
          target !== undefined &&
          value !== undefined &&
          typeof value === "number" &&
          typeof target === "number"
        ) {
          value >= target
            ? upperTargetItems.push([value, 0])
            : lowerTargetItems.push([value, 0]);
        }
      });
    return [lowerTargetItems, upperTargetItems]; // altrimenti rimangono vuoti
  }, [items]);

  // N.B: Tooltip formatter function is called for each item in the serie
  // Having this object allow us to have items with same difference in the same tooltip
  const itemsByValue = useMemo(() => {
    // issue #819
    /*  if (isGoalCase)
      return convertArrayToMap(
        items.filter((item) => typeof item.value === "number"),
        "difference"
      ); */
    return convertArrayToMap(
      items.filter((item) => typeof item.value === "number"),
      "value"
    );
  }, [items, isGoalCase]);

  const renderValue = (value: string | number) =>
    typeof value === "number"
      ? t("shared.percent", { val: value })
      : value === OUT_OF_SCALE_VALUE
      ? t("shared.charts.percentage.outOfScale")
      : value;

  /* istanbul ignore next */
  const renderTooltipDifferenceContent = useCallback(
    (difference: number, _: number, isUpperTarget = false) => {
      const items = itemsByValue.get(difference) ?? [];
      return items
        .map(
          (item) => `  
            <div class="ScatterPlotTooltip__box ScatterPlotTooltip__box--multiple" style="grid-template-columns: 0fr 3fr 1fr 2fr">
                <div class="ScatterPlotTooltip__dot ${
                  isUpperTarget
                    ? "ScatterPlotTooltip__dot--green"
                    : "ScatterPlotTooltip__dot--red"
                }">${"\u2022"}</div>
                <div class="ScatterPlotTooltip__text--title ScatterPlotTooltip__text--label">
                ${item.tooltipLabel}
                </div>
                <div class="ScatterPlotTooltip__text--title ${
                  isUpperTarget
                    ? "ScatterPlotTooltip__dot--green"
                    : "ScatterPlotTooltip__dot--red"
                }">${renderValue(item.value)}</div>
                <div class="ScatterPlotTooltip__text--label ScatterPlotTooltip__text--caption">${
                  item.band
                    ? `T: Fascia ${item.band} ${t("shared.percent", {
                        val: item.target,
                      })}`
                    : `T:${t("shared.percent", {
                        val: item.target,
                      })}`
                }
                </div>
            </div>
          `
        )
        .join("\n");
    },
    [itemsByValue, t]
  );

  /* istanbul ignore next */
  const renderTooltipValueWeigthContent = useCallback(
    ([value]: [number, number, number, string]) => {
      const items = itemsByValue.get(value) ?? [];
      return items
        .map(
          (item) => `
          <div class="ScatterPlotTooltip__box ScatterPlotTooltip__box--single ScatterPlotTooltip__box--single--ValueWeigthContent">
            <div class="ScatterPlotTooltip__dot ScatterPlotTooltip__dot--blue">${"\u2022"}</div>
            <div class="ScatterPlotTooltip__text ScatterPlotTooltip__text--title ScatterPlotTooltip__text--label">
            ${item.tooltipLabel}
            </div>
            <div class="ScatterPlotTooltip__text--title ScatterPlotTooltip__dot--blue">
            ${renderValue(item.value)}
            </div>
        </div>
        `
        )
        .join("\n");
    },
    [itemsByValue, t]
  );

  /* istanbul ignore next */
  const renderTooltipValueContent = useCallback(
    ([value]: [number, number]) => {
      const items = itemsByValue.get(value) ?? [];
      return items
        .map(
          (item) => `
          <div class="ScatterPlotTooltip__box ScatterPlotTooltip__box--single ScatterPlotTooltip__box--single--ValueContent">
            <div class="ScatterPlotTooltip__dot ScatterPlotTooltip__dot--blue">${"\u2022"}</div>
            <div class="ScatterPlotTooltip__text ScatterPlotTooltip__text--title ScatterPlotTooltip__text--label">
            ${item.tooltipLabel}
            </div>
            <div class="ScatterPlotTooltip__text ScatterPlotTooltip__text--title ScatterPlotTooltip__dot--blue">
            ${renderValue(item.value)}
            </div>
            <div class="ScatterPlotTooltip__text--label">
            ${t("shared.number", { val: item.numeratorValue })}/${t(
            "shared.number",
            { val: item.denominatorValue }
          )}
            </div>
            </div>
        </div>
        `
        )
        .join("\n");
    },
    [itemsByValue, t]
  );

  const setSeries = useCallback(() => {
    let seriesObjs: EChartsOption["series"];

    if (aboveTargetColor && belowTargetColor) {
      seriesObjs = [
        {
          markLine: {
            silent: true,
            // Removed default arrow symbol for mark line
            symbol: "none",
            label: {
              show: true,
              formatter: "",
              position: "start",
              rotate: 1,
              fontWeight: "bold",
              fontSize: "16px",
            },
            lineStyle: {
              color: "black",
              width: 3,
            },
            data: markLine ? [{ xAxis: markLine }] : [],
          },
          type: "scatter",
        },
        {
          tooltip: {
            position: "inside",
            confine: true,
            borderColor: belowTargetColor,
            formatter: /* istanbul ignore next */ ({ value }) =>
              renderTooltipDifferenceContent(...(value as [number, number])),
          },
          color: belowTargetColor,
          type: "scatter",
          symbolSize: symbolSize,
          symbolOffset: symbolOffset,
          data: lowerTargetItems,
        },
        {
          tooltip: {
            position: "inside",
            confine: true,
            borderColor: aboveTargetColor,
            formatter: /* istanbul ignore next */ ({ value }) =>
              renderTooltipDifferenceContent(
                ...(value as [number, number]),
                true
              ),
          },
          color: aboveTargetColor,
          type: "scatter",
          symbolSize: symbolSize,
          symbolOffset: symbolOffset,
          data: upperTargetItems,
        },
      ];
    } else if (maxSymbolSize && minSymbolSize) {
      seriesObjs = [
        {
          markLine: {
            // ignore mouse events
            silent: true,
            // Removed default arrow symbol for mark line
            symbol: "none",
            label: {
              show: true,
              formatter: "",
              position: "start",
              rotate: 1,
              fontWeight: "bold",
              fontSize: "16px",
            },
            lineStyle: {
              color: "black",
              width: 3,
            },
            data: items.map((item) =>
              item.target !== undefined ? { xAxis: item.target } : {}
            ),
          },
          type: "scatter",
          color: basicColor,
          tooltip: {
            show: true,
            confine: true,
            formatter: /* istanbul ignore next */ ({ value }) =>
              renderTooltipValueWeigthContent(
                value as [number, number, number, string]
              ),
          },
          symbolOffset: symbolOffset,
          symbolSize: ([, , weight]: [number, number, number]) => {
            if (minWeight === maxWeight) {
              return symbolSize;
            }

            return linearMap(
              weight,
              minWeight,
              maxWeight,
              minSymbolSize,
              maxSymbolSize
            );
          },
          // First item is how data is positioned over the x-axis, second one over y-axis ( 0 + fixed offset)
          // Third one is the weight
          data: items.map(({ value, weigth }) => {
            if (typeof value == "number") {
              return [value, 0, weigth];
            }
            return [];
          }),
        },
      ];
    } else {
      seriesObjs = [
        {
          markLine: {
            // ignore mouse events
            silent: true,
            // Removed default arrow symbol for mark line
            symbol: "none",
            label: {
              show: false,
            },
            lineStyle: {
              color: "black",
              width: 3,
            },
            data: items.map((item) =>
              item.target !== undefined ? { xAxis: item.target } : {}
            ),
          },
          tooltip: {
            position: "top",
            confine: true,
            show: true,
            borderColor: basicColor,
            formatter: /* istanbul ignore next */ ({ value }) =>
              renderTooltipValueContent(value as [number, number]),
          },
          type: "scatter",
          color: basicColor,
          symbolOffset: symbolOffset,
          symbolSize: symbolSize,
          data: items.map(({ value, weigth }) => {
            if (typeof value == "number") {
              return [value, 0];
            }
            return [];
          }),
        },
      ];
    }
    return seriesObjs;
  }, [
    aboveTargetColor,
    basicColor,
    belowTargetColor,
    items,
    lowerTargetItems,
    maxSymbolSize,
    maxWeight,
    minSymbolSize,
    minWeight,
    renderTooltipDifferenceContent,
    renderTooltipValueContent,
    renderTooltipValueWeigthContent,
    symbolOffset,
    symbolSize,
    upperTargetItems,
  ]);

  const options = useMemo<EChartsOption>(
    () => ({
      grid: {
        left: grid, //+ 20 supports onmouseover item growth ( 5 would be enough ), other 15px is to show target label when attached to extremes
        right: grid, //+ 20 supports onmouseover item growth ( 5 would be enough ), other 15px is to show target label when attached to extremes
        top: 0,
      },
      tooltip: {
        show: true,
        confine: true,
        className: "ScatterPlotTooltip__Container",
      },
      series: setSeries(),
    }),
    [grid, setSeries]
  );

  return (
    <BaseScatterplot
      min={minInterval}
      max={maxInterval}
      interval={interval}
      options={options}
      style={{ height: 100 + additionalHeight }}
    />
  );
};

export default GenericScatterplot;
