import { Col, Row } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import "./GenericLegend.scss";
import {
  GenericLegendProps,
  FRACTION_TERM_ENUM,
} from "./models/GenericLegend.models";

const GenericLegend: React.FC<GenericLegendProps> = ({
  items,
  hasTarget,
  targetLabel,
}) => {
  const { t } = useTranslation();

  const firstLineLegendItems = items.filter(
    (legendItem) => legendItem.fractionTerm !== FRACTION_TERM_ENUM.DENOMINATOR
  );
  const secondLineLegendItems = items.filter(
    (legendItem) => legendItem.fractionTerm === FRACTION_TERM_ENUM.DENOMINATOR
  );

  return (
    <Row className="pb-0 GenericLegend__wrapper">
      {firstLineLegendItems.map((legendItem, index) => (
        <Col
          key={`legend-item-${legendItem.description}`}
          className="d-flex align-items-center GenericLegend__col me-2"
        >
          <div
            data-testid={`GenericLegend-${index}`}
            className={`square GenericLegend__square GenericLegend__square--${legendItem.color} me-2`}
          />
          <Text
            type={TEXT_ENUM.PARAGRAPH}
            customClass="GenericLegend__label m-0"
          >
            {legendItem.description}
          </Text>
        </Col>
      ))}

      {hasTarget && (
        <Col className="d-flex align-items-center">
          <div
            data-testid="line-dashed"
            className="GenericLegend__lineDashed"
          />
          <Text
            type={TEXT_ENUM.PARAGRAPH}
            customClass="GenericLegend__label m-0"
          >
            {targetLabel ?? t("shared.targetDifference")}
          </Text>
        </Col>
      )}
      {secondLineLegendItems.length > 0 && (
        <>
          {secondLineLegendItems.map((legendItem, index) => (
            <Col
              key={`legend-item-${legendItem.description}`}
              className="d-flex align-items-center GenericLegend__col me-2"
            >
              <div
                data-testid={`GenericLegend-${index}`}
                className={`square GenericLegend__square GenericLegend__square--${legendItem.color} me-2`}
              />
              <Text
                type={TEXT_ENUM.PARAGRAPH}
                customClass="GenericLegend__label m-0"
              >
                {legendItem.description}
              </Text>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
};
export default React.memo(GenericLegend);
