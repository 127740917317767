import { didLogout } from "@services/Auth";
import { FC, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

export const Protected: FC<{
  expiringTokenHandler: () => void;
  expiredTokenHandler: () => void;
}> = ({ expiredTokenHandler, expiringTokenHandler }) => {
  const { isLoading, isAuthenticated, signinRedirect, events } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      events.addAccessTokenExpired(expiredTokenHandler);
      events.addAccessTokenExpiring(expiringTokenHandler);
    }
  }, [events, isAuthenticated, expiredTokenHandler, expiringTokenHandler]);

  useEffect(() => {
    if (!didLogout.current && !isLoading && !isAuthenticated) {
      signinRedirect();
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  if (isLoading) {
    return <Spinner />;
  }

  return isAuthenticated ? <Outlet /> : null;
};
