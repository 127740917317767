import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@components/UI/Button/Button";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import Icon from "@components/UI/Icon/Icon";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import "./CSVButton.scss";
import { CSVButtonProps } from "./models/CSVButton.models";

const CSVButton: React.FC<CSVButtonProps> = ({
  id,
  onClick,
  isDisabled,
  indicatorName,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      id={id}
      mode={BUTTON_MODE.WHITE}
      onClick={onClick.bind(null, id)}
      customClass="CSVButton__button p-0"
      isDisabled={isDisabled}
    >
      <Icon iconClass={ICON_CLASS.DOWNLOAD} customClass="CSVButton__icon" />
      {t("shared.csv.text")}
      <span className="visually-hidden">
        {t("shared.csv.hiddenText", { indicatorName: indicatorName })}
      </span>
    </Button>
  );
};
export default React.memo(CSVButton);
