import React, { useId } from "react";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import { SearchProps } from "./models/Search.models";

const Search: React.FC<SearchProps> = ({
  title,
  value,
  onType = () => {},
  onApply = () => {},
  onClear = () => {},
  placeholder,
  showClearBtn,
  options,
  hasSearchIcon = true,
  searchBarStyle,
  tabIndex,
  showLensButton = true,
}) => {
  const { t } = useTranslation();

  const listId = useId();

  const clearFilters = () => {
    if (showClearBtn)
      return (
        <button
          type="button"
          data-testid="clear-filter-button"
          className="btn btn-outline-primary ms-md-2"
          onClick={() => {
            onClear();
          }}
        >
          {t("glossary.search.reset")}
        </button>
      );
    else return;
  };

  return (
    <div className="mb-3">
      <label htmlFor="ex-input-btn" className="form-label">
        {title}
      </label>
      <div className="d-flex flex-column flex-md-row">
        <div className="col " style={searchBarStyle}>
          <input
            type="text"
            id="ex-input-btn"
            data-testid="search-bar"
            name="ex-input-btn"
            className="form-control rounded  border-dark"
            onChange={(e) => {
              onType(e.target.value);
            }}
            list={listId}
            value={value}
            placeholder={placeholder}
            tabIndex={tabIndex}
          />
          {options && (
            <datalist id={listId} data-testid="datalist-id">
              {options.map((option) => (
                <option key={option} value={option} data-testid="option-id" />
              ))}
            </datalist>
          )}
        </div>
        {showLensButton && (
          <button
            type="button"
            data-testid="lens-button"
            className="btn btn-primary ms-md-2 my-2 my-md-0"
            onClick={() => {
              onApply(value);
            }}
          >
            {hasSearchIcon ? (
              <Icon iconClass={ICON_CLASS.SEARCH} id="bi-search-icon" />
            ) : (
              t("glossary.search.cta")
            )}
          </button>
        )}

        {clearFilters()}
      </div>
    </div>
  );
};
export default React.memo(Search);
