export const NATION_TAB_ENUM = {
  ID: "nation",
  LABEL: "indicators.goals.tabs.nation",
} as const;
export const REGION_TAB_ENUM = {
  ID: "region",
  LABEL: "indicators.goals.tabs.region",
} as const;
export const ENTERPRISE_TAB_ENUM = {
  ID: "enterprise",
  LABEL: "indicators.goals.tabs.enterprise",
} as const;
export const STRUCTURE_TAB_ENUM = {
  ID: "structure",
  LABEL: "indicators.goals.tabs.structure",
} as const;
