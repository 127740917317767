import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, {
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "./orchestrator/orchestrator.models";

export const axiosBaseQuery = (
  {
    baseUrl,
    requestInterceptors,
  }: {
    baseUrl: string;
    requestInterceptors?: ((
      value: InternalAxiosRequestConfig<any>
    ) =>
      | InternalAxiosRequestConfig<any>
      | Promise<InternalAxiosRequestConfig<any>>)[];
  } = { baseUrl: "" }
): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: RawAxiosRequestHeaders;
    responseHandler?: (response: AxiosResponse) => Promise<any>;
  },
  unknown,
  ErrorResponse | undefined
> => {
  const client = axios.create({
    baseURL: baseUrl,
    paramsSerializer: {
      indexes: null,
    },
  });

  requestInterceptors?.forEach((requestInterceptor) => {
    client.interceptors.request.use(requestInterceptor);
  });

  return async ({ url, method, data, params, headers, responseHandler }) => {
    try {
      const result = await client.request({
        url,
        method,
        data,
        params,
        headers,
      });
      return (await responseHandler?.(result)) ?? { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      let errorMessage = "Errore generico";
      if (
        typeof err.response?.data === "object" &&
        err.response.data !== null &&
        "detail" in err.response?.data &&
        typeof err.response?.data.detail === "string"
      ) {
        errorMessage = err.response.data.detail;
      }
      return {
        error: {
          status: err.response?.status,
          data: errorMessage,
        },
      };
    }
  };
};
