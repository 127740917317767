//TODO implement design system

export enum CHARTS_COLOR {
  firstColor = "#8CA1E6",
  secondColor = "#E3B496",
  successStateLabel = "#008758",
  warningStateLabel = "#A66300",
}

export enum RADAR_CHARTS_COLOR {
  DASHED_LINE = "#5C6F82",
  PRIMARY_LINE = "#004D99",
  AXIS_NAME = "#1a1a1a",
  FIRST_SPLIT_AREA_BG = "#FFFFFF",
  SECOND_SPLIT_AREA_BG = "#F5F5F5",
  BORDER_SPLIT_AREA = "#cccccc",
  TEXT = "#1A1A1A",
}

export enum RADAR_CHART_DEFINITION {
  TYPE = "radar",
  TARGET_LINE_TYPE = "dashed",
  TARGET_LABEL = "Target",
  TEXT_FONT_WEIGHT = "bold",
}

export enum SCATTERPLOT_COLORS {
  RED = "#CC334DB3",
  GREEN = "#009562B3",
  BLUE = "#5d8dbd",
}
