import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const isMobileSlice = createSlice({
  name: "isMobile",
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) =>
      (state = action.payload),
  },
});

export const isMobileReducer = isMobileSlice.reducer;
export const { setIsMobile } = isMobileSlice.actions;
