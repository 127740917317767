export const convertArrayToMap = <T>(arr: T[], field: keyof T) => {
  const map = new Map<T[keyof T], T[]>();
  arr.forEach((item) => {
    const fieldValue = item[field];
    if (map.has(fieldValue)) {
      map.get(fieldValue)?.push(item);
    } else {
      map.set(fieldValue, [item]);
    }
  });
  return map;
};
