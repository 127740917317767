import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  NATION_TAB_ENUM,
  REGION_TAB_ENUM,
} from "@containers/Indicators/models/tabs.models";
import { orchestratorApi } from "@api/orchestrator/orchestrator.services";

const initialState: string = REGION_TAB_ENUM.ID;

const locationTabSlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    storeLocationTab: (state, action: PayloadAction<string>) => action.payload,
  },
  extraReducers(builder) {
    builder.addMatcher(
      orchestratorApi.endpoints.getProfile.matchFulfilled,
      (state, action) => {
        if (action.payload.hasCentralInstitution) {
          return NATION_TAB_ENUM.ID;
        }

        return state;
      }
    );
  },
});

export const locationTabReducer = locationTabSlice.reducer;

export const { storeLocationTab } = locationTabSlice.actions;
