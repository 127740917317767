import { LableDomain } from "@models/Glossary/Glossary.model";
import { CompaniesKPI, KPIDomain } from "@models/Indicators/indicators.model";
import { DOMAIN } from "@utils/enum/domain.enum";
import { SSN_KEYS, SSN_LABEL, SSN_TYPE_KEY } from "@utils/enum/ssn.enum";
import { NESTED_TABS } from "@components/UI/Tabbar/models/Tabbar.models";
import { Domain, Tab } from "@models/Detail/detail.model";
import { NA_VALUE } from "@utils/constants";

export function useGraphData(
  domain: Domain,
  items:
    | (KPIDomain & {
        navigate?: (() => void) | undefined;
      })[]
    | CompaniesKPI[],
  activeTab: Tab,
  selectedDeliveryRegime?: LableDomain,
  isTargetShown?: boolean
) {
  const key =
    activeTab === NESTED_TABS.NUMERATOR
      ? SSN_TYPE_KEY.NUMERATOR
      : activeTab === NESTED_TABS.DENOMINATOR
      ? SSN_TYPE_KEY.DENOMINATOR
      : SSN_TYPE_KEY.VALIDATED_INDICATOR;

  return items
    .map((item) => ({
      [item.description]: item.values.map((value) => {
        const defaultValue = 0;
        if (
          domain === DOMAIN.NATION &&
          activeTab === NESTED_TABS.VALIDATED_INDICATOR &&
          isTargetShown
        ) {
          if (typeof value.value === "number") return value.value ?? 0;
          return NA_VALUE;
        } else {
          return (
            (selectedDeliveryRegime?.id === SSN_LABEL.EXTRA_SSN
              ? value[`${key}${SSN_KEYS.EXTRA_SSN}`]
              : selectedDeliveryRegime?.id === SSN_LABEL.SSN
              ? value[`${key}${SSN_KEYS.SSN}`]
              : value[`${key}`]) ?? defaultValue
          );
        }
      }),
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
}
