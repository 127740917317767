import { SideBarSection } from "@components/UI/Sidebar/Sidebar.model";
import { ROUTES, INDICATORS_NESTED_ROUTES } from "@utils/Routes/routes.enum";

type SideBarMenuItem = NonNullable<
  SideBarSection["items"][number]["expandibleItems"]
>[number];

const expandibleItems: SideBarMenuItem[] = [
  {
    title: "shared.header.menu.items.allIndicators",
    href: INDICATORS_NESTED_ROUTES.ALL_INDICATORS,
    isDisabled: false,
  },
  {
    title: "navBar.indicators.power",
    href: INDICATORS_NESTED_ROUTES.POWER,
    isDisabled: false,
  },
  {
    title: "navBar.indicators.goals",
    href: INDICATORS_NESTED_ROUTES.GOAL,
    isDisabled: false,
  },
  {
    title: "navBar.indicators.application",
    href: INDICATORS_NESTED_ROUTES.APPLICATION,
    isDisabled: true,
  },
];

function makeSidebarItems(extraItems: SideBarMenuItem[]): SideBarSection[] {
  return [
    {
      items: [
        {
          title: "shared.header.menu.items.home",
          href: ROUTES.ROOT,
          isDisabled: true,
        },
        {
          title: "shared.header.menu.items.indicators",
          href: ROUTES.INDICATORS, //TODO remove INDICATORS_NESTED_ROUTES when we have path of indicators
          isDisabled: false,
          isExpandible: true,
          expandibleItems,
        },
        {
          title: "shared.header.menu.items.dataQuality",
          href: ROUTES.DATA_QUALITY,
          isDisabled: true,
        },
        ...extraItems,
      ],
    },
    {
      items: [
        {
          title: "shared.header.menu.items.glossary",
          href: ROUTES.RESOURCES,
          isDisabled: false,
        },
      ],
    },
  ];
}

export const commonSideMenuSectionItems = makeSidebarItems([]);

export const fullSideMenuSectionItems = makeSidebarItems([
  {
    title: "shared.header.menu.items.report",
    href: ROUTES.REPORT,
    isDisabled: false,
  },
]);
