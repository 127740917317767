import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { orchestratorApi } from "@api/orchestrator/orchestrator.services";
import { isMobileReducer } from "src/redux/slices/isMobile.slice";
import { locationTabReducer } from "src/redux/slices/locationTab.slice";
import { selectionReducer } from "src/redux/slices/selections.slice";
import { alertReducer } from "src/redux/slices/alert.slice";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

export const rootReducer = combineReducers({
  [orchestratorApi.reducerPath]: orchestratorApi.reducer,
  selections: selectionReducer,
  isMobile: isMobileReducer,
  locationTab: locationTabReducer,
  alert: persistReducer(
    {
      key: "alert",
      storage: storageSession,
      whitelist: ["showTargetChangeAlert", "showEvolvingDataAlert"],
    },
    alertReducer
  ),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      orchestratorApi.middleware
    );
  },
});

export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
