import { Protected } from "@components/Routing/Protected";
import { Crumb } from "@containers/Indicators/Detail/DetailContainer";
import { KPI_CATEGORY_VALUE } from "@utils/enum/kpiCategory";
import { FeedKpis, GoalKpis } from "@utils/KPI";
import { t } from "i18next";
import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, NavLink } from "react-router-dom";
import Layout from "src/layout/Layout";
import { INDICATORS_NESTED_ROUTES, ROUTES } from "./routes.enum";
import { logout, tokenIsExpiring } from "src/redux/slices/alert.slice";
import store from "src/store";

const dispatch = store.dispatch;

const ResourcesPage = lazy(
  () => import("@containers/Resources/ResourcesContainer")
);
const ReportPage = lazy(
  () => import("@containers/ReportPage/ReportPageContainer")
);

const IndicatorsPage = lazy(
  () => import("@containers/Indicators/IndicatorsContainer")
);
const AllIndicatorsPage = lazy(
  () => import("@containers/Indicators/All/AllIndicatorsContainer")
);
const GoalsIndicatorsPage = lazy(
  () => import("@containers/Indicators/Goals/GoalsIndicatorsContainer")
);
const PowersIndicatorsPage = lazy(
  () => import("@containers/Indicators/Powers/PowersIndicatorsContainer")
);

const DetailContainer = lazy(
  () => import("@containers/Indicators/Detail/DetailContainer")
);

const DynamicInformationPage = lazy(
  () => import("@containers/DynamicInformationPage/DynamicInformationPage")
);

export default createBrowserRouter([
  {
    path: ROUTES.PRIVACY,
    element: (
      <Suspense>
        <DynamicInformationPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.LEGALNOTES,
    element: (
      <Suspense>
        <DynamicInformationPage />
      </Suspense>
    ),
  },
  {
    element: (
      <Protected
        expiredTokenHandler={() => {
          dispatch(logout());
        }}
        expiringTokenHandler={() => {
          dispatch(tokenIsExpiring());
        }}
      />
    ),
    children: [
      {
        element: <Layout />,
        handle: {
          crumb: () => <span>{t("shared.header.menu.items.home")}</span>,
        },
        children: [
          {
            index: true,
            element: <Navigate to={INDICATORS_NESTED_ROUTES.GOAL} />, // TODO remove when we have home
          },
          {
            path: ROUTES.RESOURCES,
            element: (
              <Suspense>
                <ResourcesPage />
              </Suspense>
            ),

            handle: {
              crumb: () => (
                <NavLink
                  to={ROUTES.RESOURCES}
                  className={({ isActive }) =>
                    isActive ? "text-reset text-decoration-none pe-none" : ""
                  }
                  end
                >
                  {t("shared.header.menu.items.glossary")}
                </NavLink>
              ),
            },
          },
          {
            path: ROUTES.REPORT,
            element: (
              <Suspense>
                <ReportPage />
              </Suspense>
            ),

            handle: {
              crumb: () => (
                <NavLink
                  to={ROUTES.REPORT}
                  className={({ isActive }) =>
                    isActive ? "text-reset text-decoration-none pe-none" : ""
                  }
                  end
                >
                  {t("shared.header.menu.items.report")}
                </NavLink>
              ),
            },
          },
          {
            path: ROUTES.INDICATORS,
            element: (
              <Suspense>
                <IndicatorsPage />
              </Suspense>
            ),
            handle: {
              crumb: () => (
                <NavLink
                  to={ROUTES.INDICATORS}
                  className={({ isActive }) =>
                    isActive ? "text-reset text-decoration-none pe-none" : ""
                  }
                  end
                >
                  {t("shared.header.menu.items.allIndicators")}
                </NavLink>
              ),
            },
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <AllIndicatorsPage />
                  </Suspense>
                ),
              },
              {
                path: INDICATORS_NESTED_ROUTES.GOAL,
                handle: {
                  crumb: () => (
                    <NavLink
                      to={INDICATORS_NESTED_ROUTES.GOAL}
                      className={({ isActive }) =>
                        isActive
                          ? "text-reset text-decoration-none pe-none"
                          : ""
                      }
                      end
                    >
                      {t("navBar.indicators.goals")}
                    </NavLink>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense>
                        <GoalsIndicatorsPage />
                      </Suspense>
                    ),
                  },
                  ...GoalKpis.map((kpiId) => ({
                    path: kpiId,
                    element: (
                      <Suspense>
                        <DetailContainer
                          category={KPI_CATEGORY_VALUE.GOAL}
                          kpi={kpiId}
                        />
                      </Suspense>
                    ),
                    handle: {
                      crumb: () => (
                        <Crumb category={KPI_CATEGORY_VALUE.GOAL} kpi={kpiId} />
                      ),
                    },
                  })),
                ],
              },
              {
                path: INDICATORS_NESTED_ROUTES.POWER,
                handle: {
                  crumb: () => (
                    <NavLink
                      to={INDICATORS_NESTED_ROUTES.POWER}
                      className={({ isActive }) =>
                        isActive
                          ? "text-reset text-decoration-none pe-none"
                          : ""
                      }
                      end
                    >
                      {t("navBar.indicators.power")}
                    </NavLink>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense>
                        <PowersIndicatorsPage />
                      </Suspense>
                    ),
                  },
                  ...FeedKpis.map((kpiId) => ({
                    path: kpiId,
                    element: (
                      <Suspense>
                        <DetailContainer
                          category={KPI_CATEGORY_VALUE.POWER}
                          kpi={kpiId}
                        />
                      </Suspense>
                    ),
                    handle: {
                      crumb: () => (
                        <Crumb category={KPI_CATEGORY_VALUE.GOAL} kpi={kpiId} />
                      ),
                    },
                  })),
                ],
              },
              {
                path: INDICATORS_NESTED_ROUTES.APPLICATION,
                handle: {
                  crumb: () => (
                    <NavLink
                      to={INDICATORS_NESTED_ROUTES.APPLICATION}
                      className={({ isActive }) =>
                        isActive
                          ? "text-reset text-decoration-none pe-none"
                          : ""
                      }
                      end
                    >
                      {t("navBar.indicators.application")}
                    </NavLink>
                  ),
                },
              },
              {
                path: "*",
                element: <Navigate to={ROUTES.INDICATORS} />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
