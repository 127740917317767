import { FC } from "react";
import { useTranslation } from "react-i18next";
import Headline from "@components/Headline/Headline";
import CardComponent from "@components/UI/Card/CardComponent";
import Text from "@components/UI/Text/Text";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import { SummaryCardProps } from "@containers/Indicators/models/SummaryCard.models";
import "./SummaryCard.scss";
import TextBox from "../TextBox/TextBox";
import CSVButton from "../../CSVButton/CSVButton";
import { Link } from "react-router-dom";

const SummaryCard: FC<SummaryCardProps> = ({
  id,
  headline,
  percentage,
  numerator,
  denominator,
  referringRegion,
  meanDescription,
  target,
  difference,
  textBoxType,
  chart,
  chartLegend,
  table,
  histogramSelect,
  onDownloadCsvButton,
  onGoToDetailButton,
  detailURL,
  onTargetDataLength,
  totalTargetDataLength,
  band,
  value,
}) => {
  const { t } = useTranslation();
  const indicators: string = `${headline.firstIndicator}/${headline.secondIndicator}`;

  const targetsValueLabel: JSX.Element = (
    <div className="SummaryCard__valueLabels" data-testid="target value label">
      <TextBox
        type={textBoxType}
        percentage={percentage}
        numerator={typeof numerator === "number" ? numerator : undefined}
        denominator={typeof denominator === "number" ? denominator : undefined}
        referringRegion={referringRegion}
        target={target}
        value={
          value
            ? value
            : typeof percentage === "number"
            ? percentage
            : undefined
        }
        meanDescription={meanDescription}
        difference={difference}
        onTargetDataLength={onTargetDataLength}
        totalTargetDataLength={totalTargetDataLength}
        band={band}
      />
    </div>
  );

  const tableElement: JSX.Element = (
    <div className="SummaryCard__table">
      <div className="SummaryCard__table--tableTitle">
        <Text
          type={TEXT_ENUM.PARAGRAPH}
          customClass="SummaryCard__table--title"
        >
          {t("indicators.tables.dataTable")}
          <span tabIndex={0} className="visually-hidden">
            {t("indicators.tables.hiddenTitle", { indicatorName: indicators })}
          </span>
        </Text>
        <CSVButton
          id="csv-download-button"
          onClick={onDownloadCsvButton.bind(null, indicators)}
          indicatorName={indicators}
        ></CSVButton>
      </div>
      {table}
    </div>
  );
  return (
    <div data-testid="summary-card">
      <CardComponent
        id={id}
        isWideCard
        header={
          <div className="SummaryCard__header d-xl-flex">
            <Headline {...headline} isWide />
            {onGoToDetailButton && detailURL && (
              <Link to={detailURL} onClick={onGoToDetailButton}>
                {t("shared.goToDetail")}
                <span className="visually-hidden">
                  {t("shared.goToDetailHidden", {
                    indicatorName: indicators,
                  })}
                </span>
              </Link>
            )}
          </div>
        }
        body={
          <div data-testid="summary-card-body">
            {histogramSelect}
            {targetsValueLabel}
            {chart}
            {chartLegend}
            {tableElement}
          </div>
        }
      ></CardComponent>
    </div>
  );
};

export default SummaryCard;
