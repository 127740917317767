import { NavBarItem } from "@components/UI/NavBar/models/NavBar.model";
import {
  DATA_QUALITY_NAVBAR_ITEM_ID,
  DATA_QUALITY_NAVBAR_ITEM_LABEL,
  DOCUMENTATION_NAVBAR_ITEM_ID,
  DOCUMENTATION_NAVBAR_ITEM_LABEL,
  GLOSSARY_NAVBAR_ITEM_ID,
  GLOSSARY_NAVBAR_ITEM_LABEL,
  INDICATORS_NAVBAR_ITEM_ID,
  INDICATORS_NAVBAR_ITEM_LABEL,
  REPORT_NAVBAR_ITEM_ID,
  REPORT_NAVBAR_ITEM_LABEL,
} from "../enum/navbarItem";
import { INDICATORS_NESTED_ROUTES, ROUTES } from "@utils/Routes/routes.enum";

// navbar indicators items
export const indicatorsNavBarMenu: NavBarItem[] = [
  {
    id: INDICATORS_NAVBAR_ITEM_ID.INDICATORS,
    label: INDICATORS_NAVBAR_ITEM_LABEL.INDICATORS,
    path: INDICATORS_NESTED_ROUTES.ALL_INDICATORS,
    isDisabled: false,
    isActive: false,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.POWER,
    label: INDICATORS_NAVBAR_ITEM_LABEL.POWER,
    path: INDICATORS_NESTED_ROUTES.POWER,
    isActive: false,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.GOAL,
    label: INDICATORS_NAVBAR_ITEM_LABEL.GOAL,
    path: INDICATORS_NESTED_ROUTES.GOAL,
    isActive: false,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.APPLICATION,
    label: INDICATORS_NAVBAR_ITEM_LABEL.APPLICATION,
    path: INDICATORS_NESTED_ROUTES.APPLICATION,
    isDisabled: true,
    isActive: false,
  },
];

//navbar glossary items
export const glossaryNavBarMenu: NavBarItem[] = [
  {
    id: GLOSSARY_NAVBAR_ITEM_ID.GLOSSARY,
    label: GLOSSARY_NAVBAR_ITEM_LABEL.GLOSSARY,
    path: ROUTES.RESOURCES,
    isActive: true,
  },
];

//navbar home items
// TO DO: MAYBE TO RESTORE AFTER
/* export const homeNavBarMenu: NavBarItem[] = [
  {
    id: HOME_PAGE_NAVBAR_ITEM_ID.HOME_PAGE,
    label: HOME_PAGE_NAVBAR_ITEM_LABEL.HOME_PAGE,
    path: ROUTES.ROOT,
    isActive: true,
  },
]; */

// TO DO: MAYBE TO REMOVE AFTER
export const homeNavBarMenu: NavBarItem[] = [
  {
    id: INDICATORS_NAVBAR_ITEM_ID.INDICATORS,
    label: INDICATORS_NAVBAR_ITEM_LABEL.INDICATORS,
    path: ROUTES.INDICATORS,
    isDisabled: true,
    isActive: false,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.POWER,
    label: INDICATORS_NAVBAR_ITEM_LABEL.POWER,
    path: INDICATORS_NESTED_ROUTES.POWER,
    isActive: false,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.GOAL,
    label: INDICATORS_NAVBAR_ITEM_LABEL.GOAL,
    path: INDICATORS_NESTED_ROUTES.GOAL,
    isActive: true,
  },
  {
    id: INDICATORS_NAVBAR_ITEM_ID.APPLICATION,
    label: INDICATORS_NAVBAR_ITEM_LABEL.APPLICATION,
    path: INDICATORS_NESTED_ROUTES.APPLICATION,
    isDisabled: true,
    isActive: false,
  },
];

//navbar dataquality items
export const dataQualityNavBarMenu: NavBarItem[] = [
  {
    id: DATA_QUALITY_NAVBAR_ITEM_ID.DATA_QUALITY,
    label: DATA_QUALITY_NAVBAR_ITEM_LABEL.DATA_QUALITY,
    path: ROUTES.DATA_QUALITY,
    isActive: true,
  },
];

//navbar report items
export const reportNavBarMenu: NavBarItem[] = [
  {
    id: REPORT_NAVBAR_ITEM_ID.REPORT,
    label: REPORT_NAVBAR_ITEM_LABEL.REPORT,
    path: ROUTES.REPORT,
    isActive: true,
  },
];

//navbar documentation items
export const documentationNavBarMenu: NavBarItem[] = [
  {
    id: DOCUMENTATION_NAVBAR_ITEM_ID.DOCUMENTATION,
    label: DOCUMENTATION_NAVBAR_ITEM_LABEL.DOCUMENTATION,
    path: ROUTES.DOCUMENTATION,
    isActive: true,
  },
];
