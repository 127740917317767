import { SlideItem } from "@components/UI/Slider/models/Slider.models";

export const groupLinkedDataSlides = (
  slidesLinkedData: SlideItem[] | undefined,
  groupSize: number
) => {
  const result = [];
  if (slidesLinkedData) {
    for (let i = 0; i < slidesLinkedData.length; i += groupSize) {
      result.push(slidesLinkedData.slice(i, i + groupSize));
    }
  }
  return result;
};
