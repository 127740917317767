import { useGetGlossariesQuery } from "@api/orchestrator/orchestrator.services";
import Headline from "@components/Headline/Headline";
import CardComponent from "@components/UI/Card/CardComponent";
import CardFooterIndicators from "@components/UI/Card/CardFooterIndicators/CardFooterIndicators";
import Icon from "@components/UI/Icon/Icon";
import Slider from "@components/UI/Slider/Slider";
import {
  CONTROLLERS_POSITION,
  SLIDE_POSITION,
} from "@components/UI/Slider/models/Slider.models";
import Tabbar from "@components/UI/Tabbar/Tabbar";
import Text from "@components/UI/Text/Text";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import { Domain } from "@models/Detail/detail.model";
import { Kpi } from "@utils/KPI";
import { ROUTES } from "@utils/Routes/routes.enum";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { KPI_CATEGORY_VALUE } from "@utils/enum/kpiCategory";
import { groupLinkedDataSlides } from "@utils/function/groupLinkedDataSlices";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import "./DetailContainer.scss";
import { useIndicatorsDetailViewModel } from "./DetailViewModel";
import TabDetailContainer from "./TabDetailContainer/TabDetailContainer";

export type IndicatorsDetailContainerProps = {
  category: KPI_CATEGORY_VALUE;
  kpi: Kpi;
};

const DetailContainer: React.FC<IndicatorsDetailContainerProps> = ({
  category,
  kpi,
}) => {
  const {
    linkedData,
    tabs,
    localityTab,
    changeHistogramFilterHandler,
    onClickLocalityTabHandler,
    activeSlide,
    selectedHistogramFilter,
    selectedRegion,
    setSelectedRegion,
    setLocalityTab,
    allRegions,
    kpisToRequest,
    selectedCompany,
    setSelectedCompany,
    isGoalDetail,
    glossaryForSelectedKpi,
    lastKpi,
  } = useIndicatorsDetailViewModel(category, kpi);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top when redirect
  }, []);

  const { t } = useTranslation();

  const slidesLinkedData = useMemo(
    () =>
      linkedData?.map((kpi) => {
        return {
          label: "",
          id: kpi.id,
          children: (
            <Col md={3} sm={6} key={kpi.id} className="p-auto">
              <CardComponent
                header={
                  <Headline
                    firstIndicator={kpi.numerator}
                    secondIndicator={kpi.denominator}
                    indicatorDescription={kpi.tooltip}
                    pillValues={kpi.tags}
                    headingClass="h5"
                    headingTag={TEXT_ENUM.TITLE_H3}
                  />
                }
                footer={
                  <CardFooterIndicators
                    to={`${
                      ROUTES.INDICATORS
                    }/${kpi.category.description.toLowerCase()}/${kpi.id}`}
                    showAnchor={false}
                  />
                }
              />
            </Col>
          ),
        };
      }),
    [linkedData]
  );

  const [activeGroupSlide, setActiveGroupSlide] = useState<number>(0);

  const mdSlides = groupLinkedDataSlides(slidesLinkedData, 4);
  const smSlides = groupLinkedDataSlides(slidesLinkedData, 2);
  const xsSlides = groupLinkedDataSlides(slidesLinkedData, 1);
  return (
    <div data-testid="detail-container">
      <Row className="m-0">
        <Col>
          <div className="px-3 px-md-0">
            <Headline
              firstIndicator={glossaryForSelectedKpi.numerator}
              secondIndicator={glossaryForSelectedKpi.denominator}
              indicatorDescription={glossaryForSelectedKpi.tooltip}
              pillValues={glossaryForSelectedKpi.tags}
              periodDataReferringTo={
                lastKpi?.lastDailyReferenceDate ?? new Date()
              }
              lastUpdateDate={lastKpi?.lastUpdateDisplayName ?? ""}
              isWide
              isTitlePage
              customIconSizeClass="DetailContainer__headlineBox--icon"
            />
          </div>
        </Col>
        <Col
          xs={12}
          md="auto"
          className=" d-flex justify-content-center align-items-center DetailContainer__slider"
        >
          <Link
            to={`${ROUTES.INDICATORS}/${category}/${
              kpisToRequest[
                (((activeSlide - 1) % kpisToRequest.length) +
                  kpisToRequest.length) %
                  kpisToRequest.length
              ]
            }`}
          >
            <Icon
              iconClass={ICON_CLASS.CHEVRON_LEFT}
              customClass="DetailContainer__slider--icon bi-lg"
            ></Icon>
            <span className="visually-hidden">{t("shared.slider.prev")}</span>
          </Link>
          <span className="h6 m-0 mx-3">
            <Trans
              i18nKey="shared.slider.label"
              values={{
                indicatorNum: activeSlide + 1,
                dataLength: kpisToRequest.length,
              }}
            />
          </span>
          <Link
            to={`${ROUTES.INDICATORS}/${category}/${
              kpisToRequest[
                (((activeSlide + 1) % kpisToRequest.length) +
                  kpisToRequest.length) %
                  kpisToRequest.length
              ]
            }`}
          >
            <Icon
              iconClass={ICON_CLASS.CHEVRON_RIGHT}
              customClass="DetailContainer__slider--icon bi-lg"
            ></Icon>
            <span className="visually-hidden Slider__arrowButtons">
              {t("shared.slider.next")}
            </span>
          </Link>
        </Col>
      </Row>
      <Row className="m-0">
        <Col>
          <Row>
            <Col className="px-4">
              <Tabbar
                tabs={tabs}
                activeTab={localityTab as (typeof tabs)[number]["id"]}
                onChange={onClickLocalityTabHandler}
              />
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="p-0">
              <Container fluid className="bg-white rounded-4 py-4 px-4">
                <TabDetailContainer
                  kpi={kpi}
                  category={category}
                  domain={localityTab as Domain}
                  setLocalityTab={setLocalityTab}
                  isGoalDetail={isGoalDetail}
                  selectedRegion={selectedRegion}
                  selectedCompany={selectedCompany}
                  setSelectedRegion={setSelectedRegion}
                  setSelectedCompany={setSelectedCompany}
                  selectedHistogramFilter={selectedHistogramFilter}
                  onChangeHistogramFilter={changeHistogramFilterHandler}
                  regions={allRegions}
                />
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
      {slidesLinkedData && (
        <>
          <div className="px-3 mt-4">
            <Text type={TEXT_ENUM.TITLE_H3} customClass={TEXT_ENUM.TITLE_H5}>
              {t("indicators.detail.relatedIndicators")}
            </Text>
          </div>

          <Slider
            activeSlide={activeGroupSlide}
            onChange={setActiveGroupSlide}
            showLabel
            controllersPosition={CONTROLLERS_POSITION.CONTROLLERS_CENTER}
            slides={mdSlides}
            showControllers={(mdSlides.at(0)?.length ?? 0) >= 4}
            showDots
            slidePosition={SLIDE_POSITION.SLIDE_TOP}
            customClass="d-none d-md-flex"
          />
          <Slider
            activeSlide={activeGroupSlide}
            onChange={setActiveGroupSlide}
            showLabel
            controllersPosition={CONTROLLERS_POSITION.CONTROLLERS_CENTER}
            slides={smSlides}
            showControllers={(smSlides.at(0)?.length ?? 0) >= 2}
            showDots
            slidePosition={SLIDE_POSITION.SLIDE_TOP}
            customClass="d-none d-sm-flex d-md-none"
          />
          <Slider
            activeSlide={activeGroupSlide}
            onChange={setActiveGroupSlide}
            showLabel
            controllersPosition={CONTROLLERS_POSITION.CONTROLLERS_CENTER}
            slides={xsSlides}
            showControllers={(xsSlides.at(0)?.length ?? 0) >= 1}
            showDots
            slidePosition={SLIDE_POSITION.SLIDE_TOP}
            customClass="d-sm-none"
          />
        </>
      )}
    </div>
  );
};

export const Crumb: React.FC<IndicatorsDetailContainerProps> = ({ kpi }) => {
  const { data: glossaries } = useGetGlossariesQuery();

  const glossary = glossaries?.get(kpi);
  return (
    <NavLink
      to={`/indicatori/${glossary?.category?.description.toLowerCase()}/${kpi}`}
      className={({ isActive }) =>
        isActive ? "text-reset text-decoration-none pe-none" : ""
      }
      end
    >
      {glossary?.numerator ?? ""} / {glossary?.denominator ?? ""}
    </NavLink>
  );
};

export default DetailContainer;
