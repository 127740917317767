import React from "react";
import { useTranslation } from "react-i18next";
import { TEXT_ENUM } from "../Text/models/Text.models";
import Text from "../Text/Text";
import { NotCumulativeTextProps } from "./models/NotCumulativeText.models";
import "./NotCumulativeText.scss";

const NotCumulativeText: React.FC<NotCumulativeTextProps> = ({
  value,
  meanDescription,
  referringRegion,
}) => {
  const { t } = useTranslation();
  const meanRegion = t("shared.regionAverage") as string;
  const meanNation = t("shared.nationAverage") as string;

  return (
    <div>
      <div className="NotCumulativeText">
        <span>
          <Text
            id="NotCumulativeText__number"
            type={TEXT_ENUM.TITLE_H3}
            customClass="NotCumulativeText__number"
          >
            {value}
          </Text>
        </span>
        <Text
          type={TEXT_ENUM.PARAGRAPH}
          data-testid="sidetext"
          customClass="NotCumulativeText__text"
        >
          {meanDescription}
        </Text>
      </div>
      <Text
        type={TEXT_ENUM.PARAGRAPH}
        id="sidetext"
        customClass="NotCumulativeText__text"
      >
        {referringRegion ? `${meanRegion} ${referringRegion}` : meanNation}
      </Text>
    </div>
  );
};
export default React.memo(NotCumulativeText);
