import React from "react";
import { ButtonProps, BUTTON_MODE } from "./models/Buttons.model";

const Button: React.FC<ButtonProps> = (props) => {
  const buttonClass: string = `btn btn-${
    props.mode ? props.mode : BUTTON_MODE.PRIMARY
  } ${props.customClass}`;

  return (
    <button
      id={props.id}
      data-testid={props.id}
      type="button"
      className={buttonClass}
      onClick={props.onClick}
      disabled={props.isDisabled}
      aria-label={props.ariaLabel}
      tabIndex={props.tabIndex}
      data-bs-toggle={props.dataBsToggle}
      data-bs-target={props.dataBsTarget}
    >
      {props.children}
    </button>
  );
};

export default Button;
