import { LeafTFuncKey } from "src/i18n";

export enum LEGEND_COLORS_ENUM {
  GREEN = "green",
  RED = "red",
  LIGHT_GREY = "lightGrey",
  LIGHT_BLUE = "lightBlue",
  BLUE_EXTRA_SSN = "blueExtraSSn",
  DARK_GREY = "darkGrey",
  DARK_BLUE = "darkBlue",
  BLUE_SSN = "blueSSn",
  GREY_SSN = "graySSn",
  GREY_EXTRA_SSN = "grayExtraSSn",
  ACCENT = "accent",
  CYAN_BLUE = "cyanBlue",
  BLACK = "black",
}
export enum FRACTION_TERM_ENUM {
  NUMERATOR = "Numerator",
  DENOMINATOR = "Denominator",
}
export interface LegendItem {
  color: LEGEND_COLORS_ENUM;
  description: string | LeafTFuncKey;
  isDashed?: boolean;
  fractionTerm?: FRACTION_TERM_ENUM;
}

export interface GenericLegendProps {
  items: LegendItem[];
  hasTarget?: boolean;
  targetLabel?: string;
}
