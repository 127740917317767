import { FC } from "react";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Icon from "../Icon/Icon";
import { ScrollToTopProps } from "./models/scrollToTop.model";
import "./ScrollToTop.scss";

const ScrollToTop: FC<ScrollToTopProps> = ({ onClick }) => {
  return (
    <div className="ScrollToTop">
      <div onClick={onClick} data-testid="scroll-to-top">
        <Icon
          iconClass={ICON_CLASS.ARROW_UP}
          data-testid="scroll-to-top-button"
        />
      </div>
    </div>
  );
};

export default ScrollToTop;
