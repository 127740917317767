import { FC } from "react";
import "./CardComponent.scss";
import { CardComponentProps } from "./models/cardComponent.model";

const CardComponent: FC<CardComponentProps> = ({
  id,
  header,
  body,
  footer,
  isWideCard,
  isWidthFitContent,
  hasMinHeight,
}) => {
  return (
    <div
      className={`CardComponent${
        isWidthFitContent ? " CardComponent--fitContent" : ""
      }${isWideCard ? " CardComponent--wide" : ""}${
        hasMinHeight ? " CardComponent--minHeight" : ""
      }`}
      data-testid="card-component"
      id={id}
    >
      <div
        className="CardComponent__header"
        data-testid="card-component-header"
      >
        {header}
      </div>
      {body && (
        <div className="CardComponent__body" data-testid="card-component-body">
          {body}
        </div>
      )}
      {footer && (
        <div
          className="CardComponent__footer"
          data-testid="card-component-footer"
        >
          {footer}
        </div>
      )}
    </div>
  );
};

export default CardComponent;
