import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import IndicatorsSelect from "@containers/Indicators/components/IndicatorsSelect/IndicatorsSelect";
import { DOMAIN } from "@utils/enum/domain.enum";
import { ZONE } from "@utils/enum/zone.enum";
import { t } from "i18next";
import { Col, Row } from "react-bootstrap";
import NestedTabDetailContainer from "../NestedTabDetailContainer/NestedTabDetailContainer";
import { TabDetailContainerProps } from "./TabDetailContainer.model";
import { useTabDetailViewModel } from "./TabDetailViewModel";
import EnterpriseHeader from "./components/EnterpriseHeader";
import NationHeader from "./components/NationHeader";
import RegionHeader from "./components/RegionHeader";
import { zoneKPI } from "@utils/constants/zoneKPI";

const TabDetailContainer: React.FC<TabDetailContainerProps> = ({
  category,
  kpi,
  domain,
  setLocalityTab,
  setSelectedRegion,
  isGoalDetail,
  selectedRegion,
  selectedCompany,
  selectedHistogramFilter,
  onChangeHistogramFilter,
  setSelectedCompany,
  regions,
}) => {
  const viewModel = useTabDetailViewModel(
    category,
    kpi,
    domain,
    isGoalDetail,
    selectedRegion,
    selectedCompany,
    setSelectedCompany,
    setSelectedRegion
  );
  const DetailHeader = () => {
    switch (domain) {
      case DOMAIN.NATION:
        return (
          <NationHeader
            isFetching={viewModel.getLastKpiApiResponse.isFetching}
            setSelectedRegion={setSelectedRegion}
            setLocalityTab={setLocalityTab}
            isGoalDetail={isGoalDetail}
            CSVDownload={viewModel.downloadCSV}
            numberOfRegionsOnTarget={viewModel.numberOfRegionsOnTarget}
            lastValueForSelectedKpi={viewModel.lastKpi}
          />
        );
      case DOMAIN.REGION:
        return (
          <RegionHeader
            isFetching={viewModel.getLastKpiApiResponse.isFetching}
            lastValueForSelectedKpi={viewModel.lastKpi}
            selectedHistogramFilter={selectedHistogramFilter}
            onChangeHistogramFilter={onChangeHistogramFilter}
            selectedRegion={selectedRegion}
            setLocalityTab={setLocalityTab}
            setSelectedCompany={setSelectedCompany}
            isGoalDetail={isGoalDetail}
            CSVDownload={viewModel.downloadCSV}
          />
        );
      case DOMAIN.ENTERPRISE:
        return <EnterpriseHeader />;
    }
  };

  const nestedTabProps = {
    category,
    kpi,
    regions,
    domain,
    selectedRegion,
    title: t(`indicators.detail.${domain}.subtitle`),
    noDataLabel: t("shared.charts.lineChart.noData.nation"),
    hasTarget:
      (domain === DOMAIN.NATION || domain === DOMAIN.REGION) && isGoalDetail,
    isTargetShown:
      (domain === DOMAIN.NATION || domain === DOMAIN.REGION) &&
      viewModel.isTargetShown,
    isAltertToShow: false,
  };

  const DetailBody = () => {
    if (kpi === zoneKPI && domain === DOMAIN.NATION)
      return (
        <>
          <NestedTabDetailContainer
            {...nestedTabProps}
            title={t(`indicators.detail.hasZone.subtitleAll`)}
            hasTarget={false}
            isTargetShown={false}
          />
          <NestedTabDetailContainer
            {...nestedTabProps}
            title={t(`indicators.detail.hasZone.subtitleA`)}
            hasTarget={true}
            isTargetShown={true}
            zone={ZONE.A}
          />
          <NestedTabDetailContainer
            {...nestedTabProps}
            title={t(`indicators.detail.hasZone.subtitleB`)}
            hasTarget={true}
            isTargetShown={true}
            zone={ZONE.B}
          />
        </>
      );
    else return <NestedTabDetailContainer {...nestedTabProps} />;
  };

  return (
    <div data-testid="tab-detail-container">
      <Row>
        <Col>
          <Row className="g-4">
            <Col xs="12" md>
              <h2 className={TEXT_ENUM.TITLE_H3}>{viewModel.pageTitle}</h2>
            </Col>
          </Row>
          <DetailHeader />
        </Col>
        <Col sm="auto">
          {regions && domain !== DOMAIN.NATION && (
            <IndicatorsSelect
              regions={regions}
              defaultRegion={selectedRegion}
              defaultCompany={
                domain === DOMAIN.ENTERPRISE ? selectedCompany : undefined
              }
              showCompanies={domain === DOMAIN.ENTERPRISE}
              onChange={
                domain === DOMAIN.ENTERPRISE
                  ? viewModel.onChangeEnterprise
                  : viewModel.onChangeRegion
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <DetailBody />
        </Col>
      </Row>
    </div>
  );
};
export default TabDetailContainer;
