import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "@components/UI/Icon/Icon";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import { CardFooterIndicatorsProps } from "./models/cardFooterIndicators.model";
import "./CardFooterIndicators.scss";

const CardFooterIndicators: FC<CardFooterIndicatorsProps> = ({
  arrowDownDestination,
  onClickLink,
  to,
  showAnchor = true,
  anchorDisabled = false,
  ariaLabelDestination,
}) => {
  const { t } = useTranslation();

  return (
    <div className="CardFooterIndicators" data-testid="card-footer-container">
      {showAnchor && (
        <a
          data-testid="destination-footer"
          aria-label={"Vai alla sintesi del " + ariaLabelDestination}
          className={`CardFooterIndicators__anchor ${
            anchorDisabled ? "CardFooterIndicators__anchor--disabled" : ""
          } position-relative`}
          href={arrowDownDestination}
        >
          <Icon
            iconClass={ICON_CLASS.ARROW_DOWN_SQUARE}
            customClass="CardFooterIndicators__icon foreground position-absolute"
          />
          <Icon
            iconClass={ICON_CLASS.ARROW_DOWN_SQUARE_FILL}
            customClass="CardFooterIndicators__icon background"
          />
        </a>
      )}
      <Link
        to={to}
        data-testid="card-footer-link"
        onClick={onClickLink}
        aria-label={"Vai alla sintesi del " + ariaLabelDestination}
      >
        {t("shared.goToDetail")}
      </Link>
    </div>
  );
};

export default CardFooterIndicators;
