import Button from "@components/UI/Button/Button";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import Select from "@components/UI/Select/Select";
import { LabelPosition } from "@components/UI/Select/models/Select.models";
import { FilterPeriod } from "@models/Detail/detail.model";
import {
  DeliveryRegimeValue,
  LableDomain,
} from "@models/Glossary/Glossary.model";
import { Period } from "@models/Indicators/indicators.model";
import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import { NUMBER_TYPE } from "@utils/enum/numberType.enum";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IndicatorDetailCardProps } from "../IndicatorDetailCard/IndicatorDetailCard.models";
import "./DetailFilters.scss";

export const DetailFilters: FC<{
  hasScale?: boolean;
  hasTarget?: boolean;
  isTargetToShow?: boolean;
  periods: IndicatorDetailCardProps<any>["periods"];
  granularities: IndicatorDetailCardProps<any>["granularities"];
  selectedPeriod: FilterPeriod;
  selectedGranularity: LableDomain;
  deliveryRegimes?: LableDomain<DeliveryRegimeValue>[];
  selectedDeliveryRegime?: LableDomain<DeliveryRegimeValue>;
  lineChartScale?: NUMBER_TYPE.LOG | NUMBER_TYPE.LINEAR;
  onApplyFilters: (
    selectedPeriod: FilterPeriod,
    selectedGranularity: LableDomain,
    selectedDeliveryRegime?: LableDomain<DeliveryRegimeValue>
  ) => void;
  onChangeDeliveryRegime?: (
    item: LableDomain<DeliveryRegimeValue> | undefined
  ) => void;
  onChangeLineChartScale?: () => void;
  ariaLabel?: string;
}> = ({
  granularities: availableGranularities,
  selectedGranularity: currentGranularity,
  periods,
  selectedPeriod: currentPeriod,
  deliveryRegimes,
  selectedDeliveryRegime: currentDeliveryRegime,
  onChangeDeliveryRegime,
  hasScale = false,
  lineChartScale,
  onChangeLineChartScale = () => {},
  hasTarget = false,
  isTargetToShow,
  onApplyFilters,
  ariaLabel,
}) => {
  const { t } = useTranslation();

  const [selectedPeriod, setSelectedPeriod] = useState<
    FilterPeriod | undefined
  >(currentPeriod);

  const [selectedGranularity, setSelectedGranularity] = useState<
    LableDomain | undefined
  >(currentGranularity);

  const [selectedDeliveryRegime, setSelectedDeliveryRegime] = useState(
    currentDeliveryRegime
  );

  useEffect(() => {
    setSelectedPeriod(currentPeriod);
    setSelectedGranularity(currentGranularity);
    setSelectedDeliveryRegime(currentDeliveryRegime);
  }, [currentDeliveryRegime, currentGranularity, currentPeriod]);

  const granularities =
    availableGranularities[selectedPeriod?.id ?? ""] ??
    availableGranularities[Period.LastMonth];

  const onChangeFilters = () => {
    if (selectedPeriod && selectedGranularity) {
      onApplyFilters(
        selectedPeriod,
        selectedGranularity,
        selectedDeliveryRegime
      );
    }
  };

  const filtersButtonIsDisabled =
    selectedPeriod === undefined ||
    selectedGranularity === undefined ||
    (deliveryRegimes !== undefined && selectedDeliveryRegime === undefined) ||
    (currentPeriod === selectedPeriod &&
      currentGranularity === selectedGranularity &&
      currentDeliveryRegime === selectedDeliveryRegime);

  return (
    <fieldset aria-label={ariaLabel} data-testid="detail-filters">
      <fieldset className="fieldset-bordered d-flex flex-column gap-2 Checkbox__search py-3 mb-2">
        <legend className="mb-0">{t("filters.title")}</legend>
        <Row className="gy-2">
          <Col xs="12" lg="auto" className="mb-2 mb-lg-0">
            <Select
              customClass="me-3"
              label={t("filters.period")}
              labelPosition={LabelPosition.Top}
              options={periods}
              onChange={(period) => {
                setSelectedPeriod(period);
                setSelectedGranularity(undefined);
              }}
              value={selectedPeriod}
              labelKey={FILTERS_KEY.DESCRIPTION}
              valueKey={FILTERS_KEY.ID}
              categoryKey={FILTERS_KEY.CATEGORY}
            />
          </Col>
          <Col xs="12" lg="auto" className="mb-2 mb-lg-0">
            <Select
              customClass="me-3 w-100"
              label={t("filters.granularity")}
              labelPosition={LabelPosition.Top}
              options={granularities}
              onChange={setSelectedGranularity}
              value={selectedGranularity}
              placeholder={t("filters.granularityPlaceholder")}
              labelKey={FILTERS_KEY.DESCRIPTION}
              valueKey={FILTERS_KEY.ID}
            />
          </Col>
          {deliveryRegimes && onChangeDeliveryRegime && (
            <Col xs="12" lg="auto" className="mb-2 mb-lg-0">
              <Select
                customClass="me-3"
                label={t("filters.deliveryRegime")}
                labelPosition={LabelPosition.Top}
                options={deliveryRegimes}
                onChange={setSelectedDeliveryRegime}
                value={selectedDeliveryRegime}
                placeholder={t("filters.deliveryRegimePlaceholder")}
                labelKey={FILTERS_KEY.DESCRIPTION}
                valueKey={FILTERS_KEY.ID}
                tooltip={
                  isTargetToShow
                    ? t("filters.deliveryRegimeTargetTooltip")
                    : t("filters.deliveryRegimeTooltip")
                }
                disabled={deliveryRegimes.length === 1 || isTargetToShow}
              />
            </Col>
          )}

          <Col className="me-auto d-flex align-items-end" xs="12" lg="auto">
            <Button
              customClass="w-100"
              children={t("filters.button")}
              onClick={onChangeFilters}
              isDisabled={filtersButtonIsDisabled}
            />
          </Col>
        </Row>
        {selectedPeriod && selectedPeriod.isTarget && (
          <span className="mt-2">{t("filters.targetPeriodDisclaimer")}</span>
        )}
      </fieldset>
      <Row className="gy-2 DetailFilters__buttonRow">
        {hasScale && (
          <Col xs="12" sm="7" md="auto">
            <Button
              customClass="rounded-0 rounded-start"
              mode={
                lineChartScale === NUMBER_TYPE.LINEAR
                  ? BUTTON_MODE.PRIMARY
                  : BUTTON_MODE.OUTLINE_PRIMARY
              }
              children={t("shared.charts.lineChart.linearScale")}
              onClick={onChangeLineChartScale}
            />
            <Button
              customClass="rounded-0 rounded-end"
              mode={
                lineChartScale === NUMBER_TYPE.LOG
                  ? BUTTON_MODE.PRIMARY
                  : BUTTON_MODE.OUTLINE_PRIMARY
              }
              children={t("shared.charts.lineChart.logScale")}
              onClick={onChangeLineChartScale}
            />
          </Col>
        )}
      </Row>
    </fieldset>
  );
};
