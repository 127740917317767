import { ReactNode } from "react";

export enum TEXT_ENUM {
  TITLE_H1 = "h1",
  TITLE_H2 = "h2",
  TITLE_H3 = "h3",
  TITLE_H4 = "h4",
  TITLE_H5 = "h5",
  TITLE_H6 = "h6",
  PARAGRAPH = "p",
  SPAN = "span",
}
export interface TextProps {
  children: ReactNode;
  type: TEXT_ENUM;
  customClass?: string;
  id?: string;
  onClick?: () => void;
  arialabel?: string;
}
