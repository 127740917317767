import { GetKPIsApiResponse } from "@api/orchestrator/orchestrator.models";
import {
  KPIValue,
  KPIValueBeforeConversion,
} from "@models/Indicators/indicators.model";
import { toTitleCase } from "./textFormatting";
import { NA_VALUE, OUT_OF_SCALE_VALUE } from "@utils/constants";

export function parseKpi(
  response: GetKPIsApiResponse<KPIValueBeforeConversion>
): GetKPIsApiResponse {
  return {
    kpis: response.kpis.map((kpi) => ({
      ...kpi,
      values: kpi.values
        ?.map(parseKPIValue)
        .sort(
          (v1, v2) => v1.referenceDate.getTime() - v2.referenceDate.getTime()
        ),
      lastUpdateDisplayName: kpi.lastUpdateDisplayName.toLowerCase(),
      regions: kpi.regions.map((region) => ({
        ...region,
        description: toTitleCase(region.description ?? region.id),
        values: region.values
          .map(parseKPIValue)
          .sort(
            (v1, v2) => v1.referenceDate.getTime() - v2.referenceDate.getTime()
          ),
        companies: region.companies?.map((company) => ({
          ...company,
          description: toTitleCase(company.description ?? company.id),
          values: company.values
            .map(parseKPIValue)
            .sort(
              (v1, v2) =>
                v1.referenceDate.getTime() - v2.referenceDate.getTime()
            ),
          structures: company.structures
            ?.map((structure) => ({
              ...structure,
              referenceDate: new Date(structure.referenceDate),
            }))
            .sort(
              (v1, v2) =>
                v1.referenceDate.getTime() - v2.referenceDate.getTime()
            ),
        })),
      })),
      referenceDate: new Date(kpi.referenceDate),
    })),
  };
}

function parseKPIValue(kpi: KPIValueBeforeConversion): KPIValue {
  if (kpi.denominator === 0) {
    return {
      ...kpi,
      value: NA_VALUE,
      valueSsn: NA_VALUE,
      valueExtraSsn: NA_VALUE,
      referenceDate: new Date(kpi.referenceDate),
    };
  } else if (kpi.value > 1) {
    return {
      ...kpi,
      value: OUT_OF_SCALE_VALUE,
      valueSsn: OUT_OF_SCALE_VALUE,
      valueExtraSsn: OUT_OF_SCALE_VALUE,
      referenceDate: new Date(kpi.referenceDate),
    };
  }
  return {
    ...kpi,
    referenceDate: new Date(kpi.referenceDate),
  };
}
