import { FC } from "react";
import ReactTooltip from "../ReactTooltip/ReactTooltip";
import { IconProps } from "./models/Icon.model";

const Icon: FC<IconProps> = ({ iconClass, customClass, tooltip, id }) => {
  const IconClass: string = `${iconClass} ${customClass}`;

  return (
    <ReactTooltip text={tooltip}>
      <i className={IconClass} data-testid={id} />
    </ReactTooltip>
  );
};

export default Icon;
