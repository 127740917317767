import { Option } from "@models/Option/Option.models";

export const getOptionsArray = <T extends object>(
  items: T[],
  labelKey: keyof T,
  valueKey: keyof T
): (T & Option)[] => {
  return items.map((item) => {
    return { ...item, label: item[labelKey], value: item[valueKey] } as T &
      Option;
  });
};

export enum REGION_OPTION {
  LABEL = "description",
  VALUE = "id",
}

export enum ASL_OPTION {
  LABEL = "description",
  VALUE = "code",
}
