import { LEGEND_COLORS_ENUM } from "@components/Charts/Legend/GenericLegend/models/GenericLegend.models";
import LineLegend from "@components/Charts/Legend/LineLegend/LineLegend";
import LineChart from "@components/Charts/Line/LineChart";
import NoDataRectangle from "@components/UI/NoDataRectangle/NoDataRectangle";
import Spinner from "@components/UI/Spinner/Spinner";
import Text from "@components/UI/Text/Text";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import CSVButton from "@containers/Indicators/components/CSVButton/CSVButton";
import Checkbox from "@containers/Indicators/components/Checkbox/Checkbox";
import { NATIONAL_VALUE_LABEL } from "@utils/constants";
import { DOMAIN } from "@utils/enum/domain.enum";
import { NUMBER_TYPE } from "@utils/enum/numberType.enum";
import { useId } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { DetailFilters } from "../Filters/DetailFilters";
import { IndicatorDetailCardProps } from "./IndicatorDetailCard.models";

export default function IndicatorDetailCard<T>({
  regions,
  hasTarget = false,
  hasScale = false,
  checkbox,
  periods,
  granularities,
  selectedGranularity,
  selectedPeriod,
  selectedRegion,
  selectedCompany,
  csvButton,
  tableComponent,
  lineChartTarget,
  lineChartValuesByEntity,
  lineChartXAxis,
  noDataLabel,
  onApplyFilters,
  lineChartScale,
  onChangeLineChartScale,
  isFetching,
  domain,
  isValidatedContainer = false,
  referenceDate,
  summaryText,
  isTargetToShow,
  deliveryRegimes,
  selectedDeliveryRegime,
  setSelectedDeliveryRegime,
  isGoalDetail,
  alert,
}: IndicatorDetailCardProps<T>) {
  const { t } = useTranslation();
  const csvButtonId = useId();
  const getLegendItems = () => {
    switch (domain) {
      case DOMAIN.NATION:
        const lineChartLine = [
          {
            color: LEGEND_COLORS_ENUM.BLACK,
            description: t("shared.charts.lineChart.target"),
            isDashed: true,
          },
          {
            color: LEGEND_COLORS_ENUM.DARK_GREY,
            description: t("indicators.goals.tabs.region"),
          },
        ];
        if (hasTarget) {
          return Object.keys(lineChartValuesByEntity).find(
            (key) => key === NATIONAL_VALUE_LABEL
          )
            ? [
                {
                  color: LEGEND_COLORS_ENUM.CYAN_BLUE,
                  description: t("shared.charts.lineChart.nationalValue"),
                  isDashed: true,
                },
                ...lineChartLine,
              ]
            : lineChartLine;
        }
        return Object.keys(lineChartValuesByEntity).find(
          (key) => key === NATIONAL_VALUE_LABEL
        )
          ? [
              {
                color: LEGEND_COLORS_ENUM.CYAN_BLUE,
                description: t("shared.charts.lineChart.nationalValue"),
                isDashed: true,
              },
              {
                color: LEGEND_COLORS_ENUM.DARK_GREY,
                description: t("indicators.goals.tabs.region"),
              },
            ]
          : lineChartLine;
      case DOMAIN.REGION:
        if (hasTarget) {
          return [
            {
              color: LEGEND_COLORS_ENUM.CYAN_BLUE,
              description: t("shared.charts.lineChart.selectedValue", {
                selectedValue: selectedRegion ? selectedRegion.description : "",
              }),
              isDashed: true,
            },
            {
              color: LEGEND_COLORS_ENUM.BLACK,
              description: t("shared.charts.lineChart.target"),
              isDashed: true,
            },
            {
              color: LEGEND_COLORS_ENUM.DARK_GREY,
              description: t("indicators.goals.tabs.enterprise"),
            },
          ];
        } else {
          return [
            {
              color: LEGEND_COLORS_ENUM.CYAN_BLUE,
              description: t("shared.charts.lineChart.selectedValue", {
                selectedValue: selectedRegion ? selectedRegion.description : "",
              }),
              isDashed: true,
            },
            {
              color: LEGEND_COLORS_ENUM.DARK_GREY,
              description: t("indicators.goals.tabs.enterprise"),
            },
          ];
        }
      case DOMAIN.ENTERPRISE:
        return [
          {
            color: LEGEND_COLORS_ENUM.CYAN_BLUE,
            description: selectedCompany ? selectedCompany.description : "",
          },
        ];
    }
    //return {} as LegendItem[];
  };

  return (
    <Container
      fluid
      className="shadow rounded-4 px-4"
      data-testid="indicator-detail-card"
    >
      <Row>
        <Col xl="6">
          <Text type={TEXT_ENUM.PARAGRAPH} customClass="py-3">
            <Trans values={{ referenceDate }} components={{ bold: <b /> }}>
              {summaryText}
            </Trans>
          </Text>
        </Col>
      </Row>
      {alert && (
        <Row>
          <Col md="11" lg="8" xl="6">
            {alert}
          </Col>
        </Row>
      )}
      <Row className="g-4">
        {checkbox && (
          <Col xs="auto" md="7" lg="auto">
            <Checkbox {...checkbox} />
          </Col>
        )}
        <Col xs="12" md="7" lg>
          <Row>
            <Col className="align-self-start">
              <DetailFilters
                granularities={granularities}
                onApplyFilters={onApplyFilters}
                periods={periods}
                selectedGranularity={selectedGranularity}
                selectedPeriod={selectedPeriod}
                hasScale={hasScale}
                lineChartScale={lineChartScale}
                onChangeLineChartScale={onChangeLineChartScale}
                hasTarget={hasTarget}
                isTargetToShow={isTargetToShow}
                deliveryRegimes={deliveryRegimes}
                selectedDeliveryRegime={selectedDeliveryRegime}
                onChangeDeliveryRegime={setSelectedDeliveryRegime}
                ariaLabel={t(
                  "indicators.detail.nation.detailFilters.ariaLabel"
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {isFetching ? (
                <Spinner />
              ) : (
                <div className="indicator-detail-card__chartContainer">
                  <LineChart
                    regions={regions}
                    values={lineChartValuesByEntity}
                    target={lineChartTarget}
                    xAxis={lineChartXAxis}
                    scale={lineChartScale}
                    type={hasScale ? NUMBER_TYPE.NUMBER : NUMBER_TYPE.PERCENT}
                    hasPercentageY={
                      // TODO: refactor these booleans
                      isValidatedContainer ||
                      (isValidatedContainer && !isGoalDetail)
                    }
                    hasTarget={hasTarget && isTargetToShow}
                    noDataRectangle={
                      Object.keys(lineChartValuesByEntity).length === 0 ? (
                        <NoDataRectangle label={noDataLabel} />
                      ) : undefined
                    }
                    isNational={domain === DOMAIN.NATION}
                  />
                  <div className="indicator-detail-card__legendContainer">
                    <LineLegend items={getLegendItems()} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs="12" sm="auto" className="me-auto">
          <Text
            type={TEXT_ENUM.PARAGRAPH}
            customClass="fw-bold m-0"
            aria-label={t("indicators.detail.nation.tableAriaLabel")}
          >
            {t("shared.tableDetail")}
          </Text>
        </Col>
        <Col xs="12" sm="auto">
          <CSVButton id={csvButtonId} onClick={csvButton} />
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>{isFetching ? <Spinner /> : tableComponent}</Col>
      </Row>
    </Container>
  );
}
