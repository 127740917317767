import { Col, Row } from "react-bootstrap";
import React from "react";
import { TEXT_ENUM } from "@components/UI/Text/models/Text.models";
import Text from "@components/UI/Text/Text";
import "./LineLegend.scss";
import { GenericLegendProps } from "../GenericLegend/models/GenericLegend.models";

const LineLegend: React.FC<GenericLegendProps> = ({ items }) => {
  return (
    <Row className="LineLegend__wrapper">
      {items.map((legendItem, index) => {
        const lineClass = legendItem.isDashed
          ? `LineLegend__lineDashed LineLegend__lineDashed--${legendItem.color}`
          : `LineLegend__lineSolid`;
        return (
          <Col
            key={`legend-item-${legendItem.description}`}
            className="d-flex align-items-center me-2"
          >
            <span
              data-testid={`LineLegend-${index}`}
              className={`${lineClass} me-2`}
            />
            <Text
              type={TEXT_ENUM.PARAGRAPH}
              customClass="LineLegend__label m-0"
            >
              {legendItem.description}
            </Text>
          </Col>
        );
      })}
    </Row>
  );
};
export default React.memo(LineLegend);
