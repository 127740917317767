import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { t } from "i18next";
import { BUTTON_MODE } from "@components/UI/Button/models/Buttons.model";
import Icon from "@components/UI/Icon/Icon";
import { SortableTable } from "@components/sortableTable/SortableTable";
import { ROWS_DEFAULT_LENGTH } from "@utils/constants";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Button from "@components/UI/Button/Button";
import "./GenericTable.scss";
import { GenericTableProps } from "./GenericTable.model";

export default function GenericTable<T extends unknown>({
  containerName,
  columnsWithNumbers,
  data,
  columns,
  initialState,
}: GenericTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [canShowMore, setCanShowMore] = useState(true);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const showMoreButton = useMemo(
    () => data.length > ROWS_DEFAULT_LENGTH,
    [data.length]
  );

  return (
    <div data-testid="generic-table" className={containerName}>
      <SortableTable
        table={table}
        columnsWithNumbers={columnsWithNumbers}
        rowLength={canShowMore ? ROWS_DEFAULT_LENGTH : data.length}
      />
      {showMoreButton && (
        <Button
          id="generic-table-button"
          customClass={`table__button ${
            containerName ? containerName + "__button" : ""
          }`}
          mode={BUTTON_MODE.LINK}
          onClick={() => setCanShowMore(!canShowMore)}
        >
          <Icon
            iconClass={canShowMore ? ICON_CLASS.PLUS : ICON_CLASS.DASH}
            customClass={`table__button--icon ${
              containerName ? containerName + "__button--icon" : ""
            }`}
          />
          {/* TODO: move trans */}
          {canShowMore ? (
            <>
              {t("indicators.nationTable.showMore")}
              <span className="visually-hidden">
                {t("indicators.nationTable.showMoreHidden")}
              </span>
            </>
          ) : (
            <>
              {t("indicators.nationTable.showLess")}
              <span className="visually-hidden">
                t("indicators.nationTable.showLessHidden")
              </span>
            </>
          )}
        </Button>
      )}
    </div>
  );
}
