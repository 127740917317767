import { SubType } from "@utils/SubType";
import { ReactNode } from "react";

export enum LabelPosition {
  Left = "left",
  Top = "top",
}

export type SelectProps<T extends unknown> = {
  options: T[];
  placeholder?: string;
  label: string | ReactNode;
  labelPosition?: LabelPosition;
  disabled?: boolean;
  valueKey: keyof SubType<T, string>;
  labelKey: keyof SubType<T, string>;
  categoryKey?: keyof SubType<T, string | undefined>;
  onChange: (item: T | undefined) => void;
  customClass?: string;
  focusable?: boolean;
  tooltip?: string;
} & (
  | {
      value: T | undefined;
      multiple?: false;
    }
  | {
      value: T[];
      multiple: true;
    }
);

export type SelectedValue = string | string[] | number | number[] | undefined;
